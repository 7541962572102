import Color from 'color'

export const invertColor = hex => {
  return Color(hex).luminosity() > 0.55 ? '#121212' : '#FFFFFF'
}

export const lightenColor = (hex, amount) => {
  return Color(hex)
    .lighten(amount)
    .hex()
}

export const complementColor = hex => {
  return Color(hex)
    .rotate(-30)
    .hex()
}
