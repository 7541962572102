import {combineReducers} from 'redux'
import types from 'customer/types/Transactions.types'
import createReducer from 'customer/reducers/createReducer'
import createRequestReducer from 'customer/reducers/createRequestReducer'

const INITIAL_DATA_STATE = []
const data = createReducer(INITIAL_DATA_STATE, {
  [types.FULFILLED_GET_FUTURE_TRANSACTIONS]: (_, action) => action.data.transactions
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.FULFILLED_GET_FUTURE_TRANSACTIONS]: () => INITIAL_ERROR_STATE,
  [types.REJECTED_GET_FUTURE_TRANSACTIONS]: (_, action) => action.error
})

const getStatus = createRequestReducer(types.GET_FUTURE_TRANSACTIONS)

export default combineReducers({data, error, getStatus})
