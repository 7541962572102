import selectableCollection from 'admin/components/hoc/selectableCollection'
import Confirmation from 'admin/components/Confirmation'
import Dropdown from 'admin/components/ui/Dropdown'
import useBoolState from 'admin/hooks/useBoolState'
import useDestroyed from 'admin/hooks/useDestroyed'
import useDocument from 'admin/hooks/useDocument'
import useEffect from 'admin/hooks/useEffect'
import {pluralise} from 'admin/lib/format'
import invert from 'invert-color'
import React, {Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'

const Table = ({data, SelectAll, SelectOne}) => (
  <table className='table is-striped is-fullwidth'>
    <colgroup>
      <col span='1' width='2%' />
      <col span='1' width='98%' />
    </colgroup>
    <thead>
      <tr>
        <th>
          <SelectAll />
        </th>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      {data.map((group, i) => {
        let style = {}
        let color = group.colour || 'f5f5f5'
        style.backgroundColor = `#${color}`
        style.color = invert(`#${color}`, true)

        return (
          <tr key={i}>
            <td>
              <SelectOne identifier={group.id} />
            </td>
            <td>
              <span className='tag' style={style}>
                {group.name}
              </span>
            </td>
          </tr>
        )
      })}
    </tbody>
  </table>
)

export default () => {
  const {organisationID} = useParams()
  const {destroyed, restore} = useDestroyed(organisationID, 'Group')
  const [showRestoreConfirmation, _setShowRestoreConfirmation, toggleShowRestoreConfirmation] = useBoolState()
  const [selected, setSelected] = useState([])
  const SelectableTable = selectableCollection(Table, {onSelect: setSelected, selectedIdentifiers: selected})

  useEffect(() => setSelected([]), [destroyed], false)
  useDocument({title: 'Deleted Groups'})

  const handleRestore = () => {
    restore({recordIds: selected}, () => {
      setSelected([])
      toggleShowRestoreConfirmation()
    })
  }

  return (
    <Fragment>
      <Confirmation
        confirm='Are you sure you want to restore?'
        buttonText='Restore'
        onConfirm={handleRestore}
        onCancel={toggleShowRestoreConfirmation}
        onClose={toggleShowRestoreConfirmation}
        isOpen={showRestoreConfirmation}
      />
      <section className='section'>
        <div className='container'>
          <div className='columns is-vcentered'>
            <div className='column'>
              <h3 className='title'>Manage deleted groups</h3>
              <h4 className='subtitle'>Showing all deleted groups</h4>
            </div>
          </div>

          <div className='level'>
            <div className='level-left'>
              {selected.length ? (
                <div className='level-left has-background-info box mb-0 is-shadowless'>
                  <div className='level-item has-text-white'>
                    {selected.length > 1 && selected.length === destroyed.length ? 'All ' : ''}
                    {pluralise('group', selected.length, true)} on this page {pluralise('is', selected.length)} selected
                  </div>
                  <Dropdown id='groups-actions-menu' className='level-item'>
                    <button className='button is-outlined is-small'>
                      <span>Action</span>
                      <span className='icon is-small'>
                        <i className='fa fa-caret-down' aria-hidden='true'></i>
                      </span>
                    </button>
                    <a onClick={toggleShowRestoreConfirmation} className='dropdown-item'>
                      Restore
                    </a>
                  </Dropdown>
                </div>
              ) : (
                <div className='level-left'></div>
              )}
            </div>
            <div className='level-right'></div>
          </div>

          <SelectableTable data={destroyed} />
        </div>
      </section>
    </Fragment>
  )
}
