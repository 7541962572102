import React, {Fragment} from 'react'
import {formatNumber} from 'customer/lib/format'

export default ({date, description, amount, balance, reference, expand, onClick}) => {
  return (
    <div onClick={onClick} className={`row ${expand ? 'is-highlighted' : ''}`}>
      <div className='columns is-mobile is-multiline is-gapless'>
        <div className='column is-8'>
          <div>{description}</div>
          {expand ? null : <div className='has-text-grey mt-1 is-size-7'>{date}</div>}
        </div>
        <div className={`column is-4 ${expand ? 'has-text-right' : ''}`}>
          <div className='has-text-success'>+{formatNumber(amount)}</div>
          {expand ? null : <div className='has-text-grey mt-1 is-size-7'>{formatNumber(balance)}</div>}
        </div>
        <div className={`column is-full ${expand ? '' : 'is-hidden'}`}>
          <div className='columns is-mobile is-multiline is-gapless row-detail'>
            <div className='column is-half has-text-weight-medium'>Date</div>
            <div className='column is-half has-text-right'>{date}</div>

            {reference ? (
              <Fragment>
                <div className='column is-half has-text-weight-medium'>Reference</div>
                <div className='column is-half has-text-right'>{reference}</div>
              </Fragment>
            ) : null}

            <div className='column is-half has-text-weight-medium'>Balance after</div>
            <div className='column is-half has-text-right'>{formatNumber(balance)}</div>
          </div>
        </div>
      </div>
      <span className='icon has-text-grey-light'>
        <i className={`fa ${expand ? 'fa-angle-down' : 'fa-angle-right'}`} aria-hidden='true'></i>
      </span>
    </div>
  )
}
