import {gql} from 'admin/lib/apollo'

export const BASE_TEXT_MESSAGE_FIELDS = gql`
  fragment BaseTextMessageFields on TextMessage {
    id
    contentWithPlaceholders
    sender
    recipients
    createdAt
  }
`

export const SEND_TEXT_MESSAGE = gql`
  ${BASE_TEXT_MESSAGE_FIELDS}
  mutation SendTextMessage($organisationId: ID!, $accountIds: [String!]!, $contentWithPlaceholders: String!) {
    textMessage: sendTextMessage(
      organisationId: $organisationId
      accountIds: $accountIds
      contentWithPlaceholders: $contentWithPlaceholders
    ) {
      ...BaseTextMessageFields
    }
  }
`
