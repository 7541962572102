import {CREATE_INVOICE, GET_INVOICE, SEND_INVOICE_EMAIL, UPDATE_INVOICE} from 'admin/gql/Invoice.gql'
import clearCache from 'admin/hooks/clearCache'
import mutateWith from 'admin/hooks/mutateWith'
import useDestroyable from 'admin/hooks/useDestroyable'
import {parseError, useMutation, useQuery} from 'admin/lib/apollo'

export default (organisationId, invoiceId) => {
  const {data: {invoice} = {}} = useQuery(GET_INVOICE, {
    variables: {organisationId, invoiceId},
    skip: !invoiceId,
    errorPolicy: 'all'
  })

  const [create, {error: createError}] = useMutation(CREATE_INVOICE, {
    variables: {organisationId, invoiceId},
    errorPolicy: 'all',
    update: (cache, {data: {invoice}, errors}) =>
      !errors && clearCache({invoices: {organisationId}, statement: {accountId: invoice.account.id}})(cache)
  })

  const [update, {error: updateError}] = useMutation(UPDATE_INVOICE, {
    variables: {organisationId, invoiceId},
    errorPolicy: 'all',
    update: (cache, {errors}) => !errors && clearCache({history: {itemType: 'Invoice', itemId: invoiceId}})(cache)
  })

  const [send, {error: sendError}] = useMutation(SEND_INVOICE_EMAIL, {
    variables: {organisationId, invoiceId},
    errorPolicy: 'all',
    update: (cache, {errors}) => !errors && clearCache({history: {itemType: 'Invoice', itemId: invoiceId}})(cache)
  })

  const {destroy, errors: destroyError} = useDestroyable(organisationId, 'Invoice', invoiceId)

  return {
    invoice,
    errors: parseError(createError || updateError || sendError) || destroyError,
    create: mutateWith(create, 'Invoice created'),
    update: mutateWith(update, 'Invoice updated'),
    destroy,
    send: mutateWith(send, 'Invoice sent')
  }
}
