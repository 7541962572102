import React from 'react'
import useUser from 'admin/hooks/useUser'
import useEffect from 'admin/hooks/useEffect'
import useStateObject from 'admin/hooks/useStateObject'
import useFlash from 'admin/hooks/useFlash'
import useDocument from 'admin/hooks/useDocument'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'
import Tags from 'admin/components/ui/Tags'
import AccountField from 'admin/components/ui/AccountField'

export default ({history, match}) => {
  const {organisationID} = match.params
  const {user, error, createUser, inviteUser, createUserStatus, inviteUserStatus} = useUser(organisationID)
  const [form, setForm] = useStateObject({email: '', send_invitation: true, accounts: []})
  const [_flash, setFlash] = useFlash()

  useDocument({title: 'New user'})
  useEffect(
    () => {
      if (createUserStatus.fetched) {
        if (!error.data) {
          setFlash('User created')
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
        if (user.id) history.replace(`/orgs/${organisationID}/users/${user.id}`)
      }
    },
    [createUserStatus],
    false
  )
  useEffect(
    () => {
      if (inviteUserStatus.fetched) {
        if (!error.data) {
          setFlash('User invited')
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
        if (user.id) history.replace(`/orgs/${organisationID}/users/${user.id}`)
      }
    },
    [inviteUserStatus],
    false
  )

  const handleSubmit = e => {
    form.send_invitation ? inviteUser(form) : createUser(form)
    e.preventDefault()
  }

  const handleRemoveAccount = account => {
    setForm({accounts: form.accounts.filter(a => String(a.id) !== String(account.id))})
  }

  const handleAddAccount = account => {
    setForm({accounts: form.accounts.concat(account)})
  }

  const {data: errors = {}} = error

  return (
    <Modal onClose={history.goBack}>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title is-marginless'>New user</h3>
            </div>
            <div className='column buttons is-right'>
              <button
                className='button is-primary'
                onClick={handleSubmit}
                disabled={createUserStatus.fetching || inviteUserStatus.fetching}>
                Create
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <form onSubmit={handleSubmit}>
            <div className='block'>
              <InputField type='text' name='first_name' label='Name' value={form.name} onChange={setForm} />
            </div>
            <div className='block'>
              <InputField type='text' name='email' value={form.email} onChange={setForm} errors={errors.email} />
              <InputField
                type='checkbox'
                name='send_invitation'
                checked={form.send_invitation}
                label='Send invitation'
                onChange={setForm}
                errors={errors.send_invitation}
                help='Email this user with a link to setup a password'
              />
            </div>
            <div className='block'>
              <label htmlFor='account_name' className='label is-small'>
                Accounts
              </label>
              <Tags selected={form.accounts} onDeselect={handleRemoveAccount} />
              <AccountField
                organisationID={organisationID}
                onSelect={handleAddAccount}
                showLabel={false}
                exclude={form.accounts.reduce((exclusions, account) => {
                  exclusions.push(account.id)
                  return exclusions
                }, [])}
              />
            </div>
          </form>
        </div>
      </section>
    </Modal>
  )
}
