import types from 'admin/types/RepeatingInvoices.types'
import send from 'admin/lib/action'
import {toParams} from 'admin/lib/format'
import {parseRepeatingInvoicesResponse} from 'admin/lib/parse'

export const updateRepeatingInvoices = (organisationID, data) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/bulk/repeating_invoices`,
    baseType: types.UPDATE_REPEATING_INVOICES,
    data: {repeating_invoices: data, task: 'update'}
  })

export const getRepeatingInvoices = (organisationID, filters = {}) =>
  send({
    method: 'get',
    path: `/orgs/${organisationID}/repeating_invoices.json?${toParams({...filters, states: ['not_deleted']})}`,
    baseType: types.GET_REPEATING_INVOICES,
    data: filters,
    successParser: parseRepeatingInvoicesResponse
  })
