import {combineReducers} from 'redux'
import types from '../types/RepeatingInvoices.types'
import createReducer from 'admin/reducers/createReducer'
import createRequestReducer from 'admin/reducers/createRequestReducer'

const INITIAL_DATA_STATE = []
const data = createReducer(INITIAL_DATA_STATE, {
  [types.GET_REPEATING_INVOICES_FULFILLED]: (_, action) => action.data.transactions
})

const INITIAL_COUNTS_STATE = {}
const counts = createReducer(INITIAL_COUNTS_STATE, {
  [types.GET_REPEATING_INVOICES_FULFILLED]: (state, {data}) => ({...state, ...data.filters})
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.GET_REPEATING_INVOICES_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.UPDATE_REPEATING_INVOICES_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.UPDATE_REPEATING_INVOICES_REJECTED]: (_, action) => action.error
})

const updateStatus = createRequestReducer(types.UPDATE_REPEATING_INVOICES)
const getStatus = createRequestReducer(types.GET_REPEATING_INVOICES)

export default combineReducers({data, error, getStatus, counts, updateStatus})
