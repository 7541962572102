import axios from 'axios'
import auth from 'customer/lib/auth'

// compose Rails specific request header as { X-CSRF-Token: csrfToken, X-Requested-With: XMLHttpRequest }
const config = () => {
  return {
    headers: {
      authorization: auth.getToken(),
      ...ReactOnRails.authenticityHeaders()
    }
  }
}

export default ({method, path, baseType, data, successParser, errorParser}) => dispatch => {
  dispatch({type: baseType, data})
  let args = method === 'get' ? [path, config()] : [path, data, config()]
  return axios[method](...args)
    .then(response => {
      if (response.headers.authorization) auth.setToken(response.headers.authorization)
      const data = successParser ? successParser(response.data) : response.data
      dispatch({type: `FULFILLED_${baseType}`, data})
      return {data}
    })
    .catch(({response}) => {
      const data = errorParser ? errorParser(response.data) : response.data
      dispatch({type: `REJECTED_${baseType}`, error: {data, status: response.status}})
      return {error: data, status: response.status}
    })
}
