import React from 'react'
import useAccount from 'customer/hooks/useAccount'
import useFlash from 'admin/hooks/useFlash'
import useEffect from 'customer/hooks/useEffect'
import Tabs from 'customer/components/ui/Tabs'
import InputField from 'customer/components/ui/InputField'

export default ({match}) => {
  const {accountID} = match.params
  const {account, updateAccount, updateAccountStatus, saveAccount, error} = useAccount(accountID)
  const [_flash, setFlash] = useFlash()

  useEffect(
    () => {
      if (updateAccountStatus.fetched) {
        if (!error.data) {
          setFlash('Successfully updated')
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [updateAccountStatus],
    false
  )

  const handleSubmit = e => {
    e.preventDefault()
    updateAccount()
  }

  return (
    <div id='customer_account'>
      <Tabs
        className='is-fullwidth-mobile is-small-mobile'
        boxed={false}
        active='Edit contact details'
        collection={[
          {label: 'View statement', to: `/accounts/${accountID}/transactions`},
          {label: 'Edit contact details', to: `/accounts/${accountID}`}
        ]}
      />
      <section className='section'>
        <form className='container' onSubmit={handleSubmit}>
          <div className='field is-grouped'>
            <div className='control is-expanded'>
              <InputField
                type='text'
                name='contact_first_name'
                label='Contact first name'
                autoComplete='off'
                value={account.contact_first_name || ''}
                onChange={saveAccount}
              />
            </div>
            <div className='control is-expanded'>
              <InputField
                type='text'
                name='contact_last_name'
                label='Contact last name'
                autoComplete='off'
                value={account.contact_last_name || ''}
                onChange={saveAccount}
              />
            </div>
          </div>
          <div className='field is-grouped is-spaced'>
            <div className='control is-expanded'>
              <InputField
                type='textarea'
                rows={3}
                name='postal_address'
                label='Contact postal address'
                autoComplete='off'
                value={account.postal_address || ''}
                onChange={saveAccount}
              />
            </div>
          </div>
          <div className='field is-grouped is-spaced'>
            <div className='control is-expanded'>
              <InputField
                type='tel'
                name='contact_phone'
                label='Contact phone number'
                autoComplete='off'
                value={account.contact_phone || ''}
                onChange={saveAccount}
              />
            </div>
          </div>
          <div className='field is-grouped is-spaced'>
            <div className='control is-expanded'>
              <InputField
                type='email'
                name='contact_email'
                label='Contact email address'
                autoComplete='off'
                value={account.contact_email || ''}
                onChange={saveAccount}
              />
            </div>
          </div>
          <div className='buttons'>
            <input type='submit' className='button is-primary' value='Save' />
          </div>
        </form>
      </section>
    </div>
  )
}
