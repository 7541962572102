import React from 'react'
import GroupSelect from 'admin/components/ui/GroupSelect'
import InputField from 'admin/components/ui/InputField'

export default ({q, includeGroupIds = [], excludeGroupIds = [], onSubmit}) => {
  const handleSearch = changes => onSubmit({...changes, page: 1})
  const handleIncludeGroupSelect = group => onSubmit({includeGroupIds: includeGroupIds.concat([group.id]), page: 1})
  const handleExcludeGroupSelect = group => onSubmit({excludeGroupIds: excludeGroupIds.concat([group.id]), page: 1})

  const handleIncludeGroupUnselect = group =>
    onSubmit({includeGroupIds: includeGroupIds.filter(id => String(id) !== String(group.id)), page: 1})

  const handleExcludeGroupUnselect = group =>
    onSubmit({excludeGroupIds: excludeGroupIds.filter(id => String(id) !== String(group.id)), page: 1})

  return (
    <form className='box is-radiusless is-shadowless has-background-filters'>
      <div className='field is-grouped'>
        <div className='control is-expanded'>
          <InputField type='search' name='q' label='Search' autoComplete='off' value={q} onChange={handleSearch} />
        </div>
      </div>

      <div className='field'>
        <div className='control'>
          <GroupSelect
            selectedIds={includeGroupIds}
            onUnselect={handleIncludeGroupUnselect}
            onSelect={handleIncludeGroupSelect}
            buttonPosition='top'
            buttonText={
              <span>
                <b>Include</b> groups
              </span>
            }
          />
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <GroupSelect
            selectedIds={excludeGroupIds}
            onUnselect={handleExcludeGroupUnselect}
            onSelect={handleExcludeGroupSelect}
            tagPrefix='NOT'
            buttonPosition='top'
            buttonText={
              <span>
                <b>Exclude</b> groups
              </span>
            }
          />
        </div>
      </div>
    </form>
  )
}
