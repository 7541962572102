import moment from 'admin/lib/moment'

export const hello = () => {
  const currentHour = moment().format('HH')
  if (currentHour >= 5 && currentHour < 12) {
    return 'Ata mārie'
  } else {
    return 'Kia ora'
  }
}
