import {parseError, useMutation, useQuery} from 'admin/lib/apollo'
import {GET_TRANSACTION, CREATE_TRANSACTION, UPDATE_TRANSACTION} from 'admin/gql/Transaction.gql'
import mutateWith from './mutateWith'
import clearCache from './clearCache'
import useDestroyable from 'admin/hooks/useDestroyable'

export default (organisationId, accountId, transactionId) => {
  const {data: {transaction = {}} = {}, error: getError} = useQuery(GET_TRANSACTION, {
    variables: {organisationId, transactionId},
    skip: !transactionId,
    errorPolicy: 'all'
  })
  
  const [create, {error: createError}] = useMutation(CREATE_TRANSACTION, {
    variables: {organisationId, accountId},
    errorPolicy: 'all',
    update: (cache, {errors}) => !errors && clearCache({statement: {accountId}})(cache)
  })

  const [update, {error: updateError}] = useMutation(UPDATE_TRANSACTION, {
    variables: {organisationId, accountId, transactionId},
    errorPolicy: 'all',
    update: (cache, {errors}) =>
      !errors &&
      clearCache({
        history: {itemType: 'Payment', itemId: transactionId},
        statement: {accountId}
      })(cache)
  })

  const {destroy, errors: destroyError} = useDestroyable(organisationId, 'Payment', transactionId)

  return {
    transaction,
    errors: parseError(getError || createError || updateError) || destroyError,
    create: mutateWith(create, 'Transaction created'),
    update: mutateWith(update, 'Transaction updated'),
    destroy
  }
}
