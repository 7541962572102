import variationsFor from './variationsFor'

export const requestTypes = [
  'GET_USER',
  'UPDATE_USER',
  'CREATE_USER',
  'INVITE_USER',
  'SETUP_USER',
  'SEND_USER_INVITATION',
  'REVOKE_USER_ACCESS',
  'RESTORE_USER_ACCESS',
  'DESTROY_USER',
  'SEND_USER_VERIFICATION',
  'ADD_USER_ACCOUNT',
  'REMOVE_USER_ACCOUNT'
]

export default requestTypes.reduce(
  (reducedTypes, requestType) => ({
    ...reducedTypes,
    ...variationsFor(requestType)
  }),
  {}
)
