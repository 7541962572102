import React from 'react'
import moment from 'customer/lib/moment'
import Pikaday from 'pikaday'
import InputField from 'customer/components/ui/InputField'

const DISPLAY_FORMAT = 'D MMM YYYY'
const DATA_FORMAT = 'YYYY-MM-DD'

class DateField extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.pikaday = null
    this.state = {
      value: props.value,
      fallbackValue: ''
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    let {value} = this.props
    if (prevProps.value !== value) {
      let newValue = value !== '' ? moment(value).format(DISPLAY_FORMAT) : ''
      this.setState({value: newValue, fallbackValue: ''})
    }
  }

  componentDidMount = () => {
    this.setupPikaday()
  }

  componentWillUnmount = () => {
    this.destroyPikaday()
  }

  setupPikaday = () => {
    if (this.inputRef.current.type != 'date' && !this.props.readOnly) {
      this.initPikaday()
    }
  }

  initPikaday = () => {
    this.pikaday = new Pikaday({
      field: this.inputRef.current,
      format: DATA_FORMAT,
      toString: date => moment(date).format(DISPLAY_FORMAT),
      onSelect: this.handlePikadayChange
    })
  }

  destroyPikaday = () => {
    if (this.pikaday) {
      this.pikaday.destroy()
    }
  }

  handlePikadayChange = date => {
    this.setState(({fallbackValue}) => ({
      value: moment(fallbackValue === '' ? date : fallbackValue).format(DISPLAY_FORMAT),
      fallbackValue: ''
    }))
  }

  handleInputBlur = () => {
    let {onChange, name} = this.props
    let {value, fallbackValue} = this.state
    if (value === '') {
      onChange({[name]: value})
    } else {
      let momentValue = moment(value)
      if (momentValue.isValid()) {
        onChange({[name]: momentValue.format(DATA_FORMAT)})
      } else {
        onChange({[name]: moment(fallbackValue).format(DATA_FORMAT)})
      }
    }
  }

  handleInputChange = changes => {
    let newValue = changes[this.props.name]

    this.setState(({value, fallbackValue}) => {
      if (newValue !== '' && fallbackValue === '') {
        return {value: newValue, fallbackValue: value}
      } else {
        return {value: newValue}
      }
    })

    this.pikaday.setMoment(moment(newValue), true)
  }

  render() {
    return (
      <InputField
        {...this.props}
        type='text'
        autoComplete='off'
        ref={this.inputRef}
        value={this.state.value}
        onChange={this.handleInputChange}
        onBlur={this.handleInputBlur}
      />
    )
  }
}

export default DateField
