import {combineReducers} from 'redux'
import invoices from './Invoices.reducer.js'
import pastTransactions from './PastTransactions.reducer.js'
import repeatingInvoices from './RepeatingInvoices.reducer.js'
import admin from './Admin.reducer.js'
import user from './User.reducer.js'
import users from './Users.reducer.js'

export default combineReducers({
  invoices,
  pastTransactions,
  admin,
  user,
  users,
  repeatingInvoices,
})
