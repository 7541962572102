import React, {Fragment} from 'react'
import {Route, Switch} from 'react-router-dom'
import {isEmpty} from 'lodash'
import {formatNumber, formatDate} from 'customer/lib/format'
import {invertColor, lightenColor, complementColor} from 'customer/lib/color'
import useAccount from 'customer/hooks/useAccount'
import useEffect from 'customer/hooks/useEffect'
import useOrganisation from 'customer/hooks/useOrganisation'
import ContactDetails from 'customer/components/ContactDetails'
import Transactions from 'customer/components/Transactions'
import Upcoming from 'customer/components/Upcoming'
import useDocument from 'customer/hooks/useDocument'
import FourZeroFour from 'customer/components/FourZeroFour'

export default props => {
  const currentPath = props.match.path
  const {accountID} = props.match.params
  const {organisation} = useOrganisation()
  const {account, error, getAccount, getAccountStatus} = useAccount(accountID)
  useDocument({title: account.display_name})
  useEffect(() => {
    if (!getAccountStatus.fetching) getAccount()
  }, [accountID])

  if (error.status == 404) {
    return <FourZeroFour />
  } else if (isEmpty(account)) {
    return null
  }

  let style = {}
  if (organisation.color) {
    let lightenedColor = lightenColor(organisation.color, 0.1)
    style = {
      color: invertColor(organisation.color),
      backgroundColor: lightenedColor,
      backgroundImage: `linear-gradient(175deg, ${lightenedColor} 0%, ${lightenedColor} 35%, ${complementColor(
        organisation.color
      )} 100%)`
    }
  }

  return (
    <Fragment>
      <section className='section account' style={style}>
        <div className='container content'>
          <div className='is-size-4 block has-text-centered'>{account.display_name}</div>
          <div className='is-size-5 block has-text-centered has-text-weight-bold'>
            {account.balance === 0
              ? 'Nothing owing'
              : account.balance > 0
              ? `$${formatNumber(Math.abs(account.balance))} credit`
              : `$${formatNumber(Math.abs(account.balance))} owing`}
          </div>
          {(account.customer_number || account.physical_address) && (
            <dl className='inline-flex block'>
              {account.customer_number && (
                <Fragment>
                  <dt className='has-text-right'>Account number</dt>
                  <dd>{account.customer_number}</dd>
                </Fragment>
              )}
              {account.physical_address && (
                <Fragment>
                  <dt className='has-text-right'>Physical address</dt>
                  <dd>{account.physical_address}</dd>
                </Fragment>
              )}
            </dl>
          )}
          {account.as_at && (
            <div className='has-text-centered is-size-7'>Last reconciled {formatDate(account.as_at)}</div>
          )}
        </div>
      </section>

      <Switch>
        <Route path={`${currentPath}/transactions`} component={Transactions} />
        <Route path={`${currentPath}/upcoming`} component={Upcoming} />
        <Route path={`${currentPath}`} exact={true} component={ContactDetails} />
      </Switch>
    </Fragment>
  )
}
