import React, {Fragment} from 'react'
import {isEmpty} from 'lodash'
import {Link, useParams} from 'react-router-dom'
import invert from 'invert-color'
import {formatNumber, frequencyInWords, formatDate} from 'admin/lib/format'
import useOrganisation from 'admin/hooks/useOrganisation'
import useSchedule from 'admin/hooks/useSchedule'
import useServices from 'admin/hooks/useServices'
import Tags from 'admin/components/ui/Tags'
import ShowField from 'admin/components/ui/ShowField'
import Dropdown from 'admin/components/ui/Dropdown'

const ARCHIVE_CONFIRMATION = 'Repeating invoices will end today'
const SUSPEND_CONFIRMATION = 'Repeating invoices will end today and an immediate suspension fee will be charged'

const Icon = ({name}) => (
  <span className='icon'>
    <i className={`fa fa-sm fa-${name}`}></i>
  </span>
)

const PlanTag = ({plan}) => {
  let style = {}
  let color = plan.color || '#F5F5F5' 
  style.backgroundColor = `${color}` 
  style.color = invert(`${color}`, true)

  return (
    <span className='tag is-small' style={style}>{plan.name}</span>
  )
}

export default ({account, breadcrumbs, onEdit, onArchive, onSuspend, onUnsuspend, onShowActivity, onRecalculate}) => {
  const {organisationID, accountID} = useParams()
  const {schedule} = useSchedule(organisationID, accountID)
  const {services} = useServices(organisationID, accountID)
  const {organisation} = useOrganisation(organisationID)

  if (isEmpty(account)) return null

  const handleArchive = () => {
    if (confirm(ARCHIVE_CONFIRMATION)) onArchive()
  }

  const handleSuspend = () => {
    if (confirm(SUSPEND_CONFIRMATION)) onSuspend()
  }

  return (
    <Fragment>
      <div className='columns is-vcentered is-mobile'>
        <div className='column'>{breadcrumbs}</div>

        <div className='column buttons is-right'>
          <a onClick={onShowActivity} className='button is-light'>
            <span className='icon is-small'>
              <i className='fa fa-history' aria-hidden='true'></i>
            </span>
            <span>View History & Notes</span>
          </a>
          <Dropdown id='account-options-menu' className='is-right'>
            <button className='button is-light'>
              <span>Actions</span>
              <span className='icon'>
                <i className='fa fa-caret-down' aria-hidden='true'></i>
              </span>
            </button>
            {account.state === 'active' ? (
              <a onClick={handleArchive} className='dropdown-item'>
                Archive
              </a>
            ) : null}
            <a onClick={account.isSuspended ? onUnsuspend : handleSuspend} className='dropdown-item'>
              {account.isSuspended ? 'Unuspend' : 'Suspend'}
            </a>
            {account.xeroLink && (
              <a className='dropdown-item' href={account.xeroLink} target='_blank'>
                <span>Show in Xero</span>
                <span className='icon'>
                  <i className='fa fa-external-link'></i>
                </span>
              </a>
            )}
          </Dropdown>
          <button onClick={onEdit} className='button is-light'>
            Edit
          </button>
        </div>
      </div>

      <div className='columns is-variable is-7-desktop'>
        <div className='column'>
          <ShowField
            className='mb-1'
            label={<Icon name='user' />}
            alwaysHorizontal={true}
            flexGrow={false}
            values={{contactFirstName: account.contactFirstName, contactLastName: account.contactLastName}}
          />
          <ShowField
            value={account.physicalAddress}
            className='mb-1'
            label={<Icon name='home' />}
            alwaysHorizontal={true}
            flexGrow={false}
            type='address'
          />
          <ShowField
            value={account.postalAddress}
            className='mb-1'
            label={<Icon name='envelope' />}
            alwaysHorizontal={true}
            flexGrow={false}
            type='address'
            showHref={false}
          />
          <ShowField
            value={account.contactPhone}
            className='mb-1'
            label={<Icon name='phone' />}
            alwaysHorizontal={true}
            flexGrow={false}
            type='tel'
          />
          <ShowField
            value={account.contactEmail}
            className='mb-1'
            label={<Icon name='at' />}
            alwaysHorizontal={true}
            flexGrow={false}
            type='email'
          />
          <ShowField
            value={account.billingEmail}
            className='mb-1'
            label={<Icon name='at' />}
            alwaysHorizontal={true}
            flexGrow={false}
            type='email'
          />
        </div>

        <div className='column'>
          <ShowField label='Groups'>
            {account.groups.length ? (
              <Tags selected={account.groups} />
            ) : (
              <span className='has-text-grey-light'>None</span>
            )}
          </ShowField>
          {organisation.sms_enabled ? (
            <ShowField label='Notifications'>
              {account.warnWhenOverdue ? (
                <span className='is-italic is-size-7'>
                  <Icon name='bell-o' />
                  {'  '}Send SMS warnings when overdue
                </span>
              ) : (
                <span className='has-text-grey-light'>None</span>
              )}
            </ShowField>
          ) : null}
          <ShowField label='Account number'>
            {account.customerNumber || <span className='has-text-grey-light'>None</span>}
          </ShowField>
          <ShowField label='Balance'>
            {Number(account.balance) >= 0 ? (
              <Fragment>
                <span className='is-size-5'>{formatNumber(account.balance, {accounting: true})}</span>
                {!!Number(account.balance > 0) && <span> credit</span>}
              </Fragment>
            ) : (
              <Fragment>
                <span className='is-size-5 has-text-danger'>{formatNumber(Math.abs(account.balance))}</span>{' '}
                <span className='has-text-danger'>owing</span>
                {account.overdueSince ? (
                  <span className='help has-text-danger'>{`Overdue since ${account.overdueSince}`}</span>
                ) : null}
              </Fragment>
            )}
            <a onClick={onRecalculate} className='icon mx-2 has-text-info'>
              <i className={`fa fa-sm fa-refresh`}></i>
            </a>
          </ShowField>
          {schedule.length ? (
            <ShowField label='Upcoming invoices'>
              <div className='buttons'>
                {schedule.map((transaction, i) => (
                  <Link
                    key={i}
                    className='button is-white is-small'
                    to={`/orgs/${organisationID}/${
                      {
                        Invoice: 'invoices',
                        RepeatingInvoice: 'repeating_invoices',
                        Transaction: `accounts/${account.id}/transactions`
                      }[transaction.__typename]
                    }/${transaction.id}`}>
                    <div style={{marginRight: 'auto'}}>
                      {formatDate(
                        transaction.__typename === 'RepeatingInvoice' ? transaction.nextInvoiceDate : transaction.date
                      )}
                      &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                      {formatNumber(transaction.amount)}{' '}
                      {transaction.__typename === 'RepeatingInvoice'
                        ? `every ${frequencyInWords(transaction.frequencyCount, transaction.frequency)}`
                        : 'one off'}
                    </div>
                    {transaction.state === 'draft' ? (
                      <Fragment>
                        &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                        <span className='tag is-small'>Draft</span>
                      </Fragment>
                    ) : (
                      transaction.isSendable && (
                        <Fragment>
                          &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                          <span className='tag greenish is-small'>Send</span>
                        </Fragment>
                      )
                    )}
                  </Link>
                ))}
              </div>
            </ShowField>
          ) : null}
          <ShowField label='Services'>
            <div className='buttons'>
              {services.map((service, i) => (
                <Link
                  key={i}
                  className='button is-white is-small'
                  to={`/orgs/${organisationID}/accounts/${accountID}/services/${service.id}`}>
                  {!!service.plan ? (
                    <Fragment>
                      <PlanTag plan={service.plan} />
                      &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                    </Fragment>
                  ) : null}
                  <div style={{marginRight: 'auto'}}>{service.description}</div>
                </Link>
              ))}
              <Link
                to={`/orgs/${organisationID}/accounts/${accountID}/services/new`}
                className='button is-light is-small'>
                Add service
              </Link>
            </div>
          </ShowField>
        </div>
      </div>
    </Fragment>
  )
}
