// This file should reflect what is in Tax.rb

const DEFAULT_TAX_RATE = 0.15

const EXCLUSIVE = 'EXCLUSIVE'
const INCLUSIVE = 'INCLUSIVE'
const NONE = 'NONE'

export const TAX_TYPES = {
  exclusive: EXCLUSIVE,
  inclusive: INCLUSIVE,
  none: NONE
}

export const taxForInvoice = invoice => {
  let lineItems = invoice.line_items || invoice.lineItems,
    taxType = invoice.tax_type || invoice.taxType

  if (!invoice || !lineItems || taxType == NONE) return 0

  if (taxType == INCLUSIVE) {
    return lineItems.reduce((sum, item) => {
      let unitPrice = item.unit_price || item.unitPrice || 0,
        taxRate = item.tax_rate || item.taxRate,
        quantity = item.quantity || 0
      return sum + taxExcluded(unitPrice * quantity, taxRate)
    }, 0)
  } else {
    return lineItems.reduce((sum, item) => {
      let unitPrice = item.unit_price || item.unitPrice || 0,
        taxRate = item.tax_rate || item.taxRate,
        quantity = item.quantity || 0
      return sum + taxIncluded(unitPrice * quantity, taxRate)
    }, 0)
  }
}

export const subtotalForInvoice = invoice => {
  let lineItems = invoice.line_items || invoice.lineItems

  if (!invoice || !lineItems) return 0

  return lineItems.reduce((sum, item) => {
    let unitPrice = item.unit_price || item.unitPrice || 0,
      quantity = item.quantity || 0
    return sum + unitPrice * quantity
  }, 0)
}

export const totalForInvoice = invoice => {
  if (!invoice) return 0
  let taxType = invoice.tax_type || invoice.taxType
  if (taxType == EXCLUSIVE) {
    return subtotalForInvoice(invoice) + taxForInvoice(invoice)
  } else {
    return subtotalForInvoice(invoice)
  }
}

const taxExcluded = (value, taxRate) => {
  if ((taxRate || DEFAULT_TAX_RATE) == 0.15) {
    return (value * 3) / 23
  } else {
    return 0
  }
}

const taxIncluded = (value, taxRate) => {
  return value * (taxRate || DEFAULT_TAX_RATE)
}
