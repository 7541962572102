import React from 'react'
import {useParams, useHistory} from 'react-router-dom'
import useAccount from 'admin/hooks/useAccount'
import useStateObject from 'admin/hooks/useStateObject'
import useDocument from 'admin/hooks/useDocument'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'

export default () => {
  const {organisationID} = useParams()
  const history = useHistory()
  const {account, errors, create} = useAccount(organisationID)
  const [form, setForm] = useStateObject(account)

  useDocument({title: 'New account'})

  const handleCreate = e => {
    e.preventDefault()
    create(form, ({account}) => {
      history.replace(`/orgs/${organisationID}/accounts/${account.id}`)
    })
  }

  const handleClose = () => {
    history.replace(`/orgs/${organisationID}/accounts`)
  }

  return (
    <Modal onClose={handleClose}>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title is-marginless'>New account</h3>
            </div>
            <div className='column buttons is-right'>
              <button className='button is-primary' onClick={handleCreate}>
                Create
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <form onSubmit={handleCreate}>
            <InputField type='text' name='name' value={form.name || ''} onChange={setForm} errors={errors.name} />
          </form>
        </div>
      </section>
    </Modal>
  )
}
