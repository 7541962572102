import {combineReducers} from 'redux'
import types, {requestTypes} from 'admin/types/User.types'
import createReducer from 'admin/reducers/createReducer'
import createRequestReducer from 'admin/reducers/createRequestReducer'
import {asVariable} from 'admin/lib/format'

const INITIAL_DATA_STATE = []
const data = createReducer(INITIAL_DATA_STATE, {
  [types.GET_USER_FULFILLED]: (_, action) => action.data,
  [types.UPDATE_USER_FULFILLED]: (_, action) => action.data,
  [types.CREATE_USER_FULFILLED]: (_, action) => action.data,
  [types.INVITE_USER_FULFILLED]: (_, action) => action.data,
  [types.SETUP_USER_FULFILLED]: (_, action) => action.data,
  [types.SEND_USER_INVITATION_FULFILLED]: (_, action) => action.data,
  [types.REVOKE_USER_ACCESS_FULFILLED]: (_, action) => action.data,
  [types.RESTORE_USER_ACCESS_FULFILLED]: (_, action) => action.data,
  [types.DESTROY_USER_FULFILLED]: (_, action) => action.data,
  [types.SEND_USER_VERIFICATION_FULFILLED]: (_, action) => action.data,
  [types.ADD_USER_ACCOUNT_FULFILLED]: (_, action) => action.data,
  [types.REMOVE_USER_ACCOUNT_FULFILLED]: (_, action) => action.data
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.GET_USER_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.UPDATE_USER_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.CREATE_USER_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.INVITE_USER_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.SETUP_USER_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.SEND_USER_INVITATION_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.REVOKE_USER_ACCESS_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.RESTORE_USER_ACCESS_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.DESTROY_USER_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.SEND_USER_VERIFICATION_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.ADD_USER_ACCOUNT_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.REMOVE_USER_ACCOUNT_FULFILLED]: () => INITIAL_ERROR_STATE,

  [types.GET_USER_REJECTED]: (_, action) => action.error,
  [types.UPDATE_USER_REJECTED]: (_, action) => action.error,
  [types.CREATE_USER_REJECTED]: (_, action) => action.error,
  [types.INVITE_USER_REJECTED]: (_, action) => action.error,
  [types.SETUP_USER_REJECTED]: (_, action) => action.error,
  [types.SEND_USER_INVITATION_REJECTED]: (_, action) => action.error,
  [types.REVOKE_USER_ACCESS_REJECTED]: (_, action) => action.error,
  [types.RESTORE_USER_ACCESS_REJECTED]: (_, action) => action.error,
  [types.DESTROY_USER_REJECTED]: (_, action) => action.error,
  [types.SEND_USER_VERIFICATION_REJECTED]: (_, action) => action.error,
  [types.ADD_USER_ACCOUNT_REJECTED]: (_, action) => action.error,
  [types.REMOVE_USER_ACCOUNT_REJECTED]: (_, action) => action.error
})

const requestReducers = requestTypes.reduce((reducers, requestType) => {
  reducers[`${asVariable(requestType)}Status`] = createRequestReducer(types[requestType])
  return reducers
}, {})

export default combineReducers({data, error, ...requestReducers})
