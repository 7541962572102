import {gql} from '@apollo/client'

export const GET_SERVICE = gql`
  query GetService($organisationId: ID!, $serviceId: ID!) {
    service(organisationId: $organisationId, id: $serviceId) {
      id
      description
      macAddress
      plan {
        id
        name
      }
    }
  }
`

export const CREATE_SERVICE = gql`
  mutation CreateService($accountId: ID!, $description: String, $macAddress: String, $planId: ID) {
    service(accountId: $accountId, description: $description, macAddress: $macAddress, planId: $planId) {
      id
      description
      macAddress
      plan {
        id
        name
      }
    }
  }
`

export const UPDATE_SERVICE = gql`
  mutation UpdateService($id: ID!, $description: String, $macAddress: String, $accountId: ID!, $planId: ID) {
    service(id: $id, description: $description, macAddress: $macAddress, accountId: $accountId, planId: $planId) {
      id
      accountId
      description
      macAddress
      plan {
        id
        name
      }
    }
  }
`
