import {gql, useMutation} from 'admin/lib/apollo'
import {pluralise} from 'admin/lib/format'
import {camelCase, isObject} from 'lodash'

const MUTATION = gql`
  mutation RestoreRecords($organisationId: ID!, $recordType: String!, $recordIds: [String!]!) {
    restore(organisationId: $organisationId, recordType: $recordType, recordIds: $recordIds) {
      ... on Group {
        id
        name
        colour
      }
      ... on Invoice {
        id
        account {
          id
          name
          contactFirstName
          contactLastName
          displayName @client
        }
        date
        invoiceNumber
        reference
        amount
      }
      ... on Transaction {
        id
        date
        amount
        description
      }
      ... on RepeatingInvoice {
        id
        account {
          id
        }
      }
    }
  }
`

export default variables =>
  useMutation(MUTATION, {
    variables,
    errorPolicy: 'all',
    update: (cache, {data: {restore = []}, errors}) => {
      if (!errors) {
        // cache.modify({
        //   fields: {
        //     [camelCase(pluralise(recordType))]: (_, {DELETE}) => DELETE,
        //     destroyed: (cached = [], {readField}) => {
        //       console.log('hi')
        //       return cached
        //       let restoredIds = restore.map(({id}) => id)
        //       if (isObject(cached) && cached.__typename.match(/Collection$/)) {
        //         let collection = cached.collection.filter(
        //           recordRef => !restoredIds.includes(readField('id', recordRef))
        //         )
        //         let diffCount = cached.collection.length - collection.length
        //         return {
        //           ...cached,
        //           collection,
        //           metadata: {
        //             ...cached.metadata,
        //             totalCount: cached.metadata.totalCount - diffCount
        //           }
        //         }
        //       } else {
        //         return cached.filter(recordRef => !restoredIds.includes(readField('id', recordRef)))
        //       }
        //     }
        //   }
        // })
      }
    }
  })
