import {useSelector, useDispatch} from 'react-redux'
import {getAccounts} from 'customer/actions/Accounts.actions'

const mapState = state => ({
  error: state.accounts.error,
  accounts: state.accounts.data,
  getAccountsStatus: state.accounts.getStatus
})

export default () => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)
  return {
    ...store,
    getAccounts: () => getAccounts()(dispatch)
  }
}
