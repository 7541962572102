import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {formatNumber, formatDate} from 'admin/lib/format'
import sortable from 'admin/components/hoc/sortable'

const InvoiceRow = ({invoice, onClick}) => {
  return (
    <tr onClick={onClick}>
      <td>
        {invoice.source === 'xero' && <span className='xero__dot'></span>}
        {formatDate(invoice.date)}
      </td>
      <td>
        {invoice.invoiceNumber}
        &nbsp;&nbsp;
        {invoice.isSent && <span className='tag is-success'>Sent</span>}
      </td>
      <td>{invoice.description}</td>
      <td>{invoice.reference}</td>
      <td>{formatDate(invoice.dueDate)}</td>
      <td className='has-text-right'>
        <span>{formatNumber(Math.abs(invoice.amount))}</span>
      </td>
      <td className='has-text-right'></td>
      <td className='has-text-right'>
        <span>{formatNumber(invoice.balance, {accounting: true})}</span>
      </td>
    </tr>
  )
}

const TransactionRow = ({transaction, onClick}) => {
  return (
    <tr onClick={onClick}>
      <td>
        {transaction.source === 'xero' && <span className='xero__dot'></span>}
        {formatDate(transaction.date)}
      </td>
      <td></td>
      <td>{transaction.description}</td>
      <td></td>
      <td></td>
      <td className='has-text-right'>{transaction.amount < 0 ? formatNumber(Math.abs(transaction.amount)) : null}</td>
      <td className='has-text-right'>
        <span className='has-text-success'>
          {transaction.amount >= 0 ? formatNumber(Math.abs(transaction.amount)) : null}
        </span>
      </td>
      <td className='has-text-right'>
        <span>{formatNumber(transaction.balance, {accounting: true})}</span>
      </td>
    </tr>
  )
}

export default ({statement, onSort, sortBy, sortDirection}) => {
  const history = useHistory()
  const {organisationID, accountID} = useParams()
  const SortableTH = sortable('th', {onSort, sortBy, sortDirection, camelcase: true})

  return (
    <table className='table is-striped is-hoverable is-fullwidth is-linked past'>
      <colgroup>
        <col width='12%' />
        <col width='12%' />
        <col width='20%' />
        <col width='12%' />
        <col width='12%' />
        <col width='10.67%' />
        <col width='10.67%' />
        <col width='10.67%' />
      </colgroup>
      <thead>
        <tr>
          <SortableTH name='date' />
          <SortableTH name='invoice_number' />
          <SortableTH name='description' />
          <SortableTH name='reference' />
          <SortableTH name='due_date' />
          <th className='has-text-right'>Debit</th>
          <th className='has-text-right'>Credit</th>
          <SortableTH className='has-text-right' name='balance' />
        </tr>
      </thead>
      <tbody>
        {statement.map((transaction, i) =>
          transaction.__typename === 'Invoice' ? (
            <InvoiceRow
              key={i}
              invoice={transaction}
              onClick={() => history.push(`/orgs/${organisationID}/invoices/${transaction.id}`)}
            />
          ) : (
            <TransactionRow
              key={i}
              transaction={transaction}
              onClick={() =>
                history.push(`/orgs/${organisationID}/accounts/${accountID}/transactions/${transaction.id}`)
              }
            />
          )
        )}
      </tbody>
    </table>
  )
}
