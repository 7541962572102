import React from 'react'
import History from 'admin/components/History'
import Flash from 'admin/components/Flash'
import Modal from 'admin/components/ui/Modal'

export default props => {
  const {invoiceID} = props.match.params

  return (
    <Modal onClose={props.history.goBack}>
      <Flash />
      <History itemType='Invoice' itemId={invoiceID} />
    </Modal>
  )
}
