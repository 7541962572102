import React, {Fragment} from 'react'
import {Link, useParams, useHistory} from 'react-router-dom'
import {pluralise, formatNumber, capitalize} from 'admin/lib/format'
import useEffect from 'admin/hooks/useEffect'
import useRepeatingInvoices from 'admin/hooks/useRepeatingInvoices'
import useDocument from 'admin/hooks/useDocument'
import sortable from 'admin/components/hoc/sortable'
import Pagination from 'admin/components/ui/Pagination'
import Dropdown from 'admin/components/ui/Dropdown'
import Tabs from 'admin/components/ui/Tabs'
import Flash from 'admin/components/Flash'
import Filters from './Filters'
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params'

export default () => {
  const {organisationID} = useParams()
  const history = useHistory()
  const {repeatingInvoices, counts, getRepeatingInvoices} = useRepeatingInvoices(organisationID)
  const [filters, setFilters] = useQueryParams({
    q: withDefault(StringParam, ''),
    from_date: withDefault(StringParam, ''),
    to_date: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sort_by: withDefault(StringParam, 'date'),
    sort_direction: withDefault(StringParam, 'desc')
  })

  useEffect(() => getRepeatingInvoices(filters), [filters])

  useDocument({title: 'Repeating invoices'})

  const SortableTH = sortable('th', {
    onSort: setFilters,
    sortBy: filters.sort_by,
    sortDirection: filters.sort_direction
  })

  const handleEdit = (e, invoice) => {
    if (e.target.tagName.toLowerCase() !== 'a') {
      e.preventDefault()
      history.push(`/orgs/${organisationID}/repeating_invoices/${invoice.id}`)
    }
  }

  return (
    <Fragment>
      <Flash />
      <Tabs
        active='Repeating'
        collection={[
          {label: 'Draft', to: `/orgs/${organisationID}/invoices/draft`},
          {label: 'Approved', to: `/orgs/${organisationID}/invoices/approved`},
          {label: 'Repeating', to: `/orgs/${organisationID}/repeating_invoices`},
          {label: 'Deleted', to: `/orgs/${organisationID}/invoices/deleted`}
        ]}
      />
      <section className='section' id='admin_invoices'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title'>Manage repeating invoices</h3>
              <h4 className='subtitle'>{`Showing ${counts.count || 0} of ${pluralise(
                `repeating invoice`,
                counts.results_count || 0,
                true
              )}`}</h4>
            </div>
            <div className='column buttons is-right'>
              <Dropdown id='invoices-options' className='is-right'>
                <button className='button is-light'>
                  <span>New</span>
                  <span className='icon'>
                    <i className='fa fa-caret-down'></i>
                  </span>
                </button>
                <Link to={`/orgs/${organisationID}/invoices/new`}>New invoice</Link>
                <Link to={`/orgs/${organisationID}/repeating_invoices/new`}>New repeating invoice</Link>
              </Dropdown>
            </div>
          </div>
          <Filters q={filters.q} fromDate={filters.from_date} toDate={filters.to_date} onSubmit={setFilters} />
          {counts.count && counts.count != 0 ? (
            <Fragment>
              <div className='level'>
                <div className='level-left'></div>
                <div className='level-right'>
                  <Pagination
                    page={filters.page}
                    limit={filters.limit}
                    total={counts.results_count}
                    onChange={setFilters}
                    className='level-item'
                  />
                </div>
              </div>
              <table className='table is-striped is-hoverable is-fullwidth is-linked'>
                <thead>
                  <tr>
                    <th>Account</th>
                    <SortableTH name='reference' />
                    <SortableTH column='date' name='Next invoice date' />
                    <SortableTH column='end_date' name='End date' />
                    <SortableTH className='has-text-right' name='amount' />
                    <SortableTH className='has-text-right' column='status' name='Status' />
                  </tr>
                </thead>
                <tbody>
                  {repeatingInvoices.map((invoice, i) => (
                    <tr key={i} onClick={e => handleEdit(e, invoice)}>
                      <td>
                        <Link to={`/orgs/${organisationID}/accounts/${invoice.account_id}`}>
                          {invoice.account_name}
                        </Link>
                      </td>
                      <td>{invoice.reference}</td>
                      <td>{invoice.date}</td>
                      <td>{invoice.end_date}</td>
                      <td className='has-text-right'>{formatNumber(invoice.amount)}</td>
                      <td className='has-text-right'>
                        {invoice.status && (
                          <span className={`tag ${{sent: 'is-success', send: 'greenish'}[invoice.status] || ''}`}>
                            {capitalize(invoice.status)}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {counts.count > 10 && (
                <div className='level'>
                  <div className='level-left'></div>
                  <div className='level-right'>
                    <Pagination
                      page={filters.page}
                      limit={filters.limit}
                      total={counts.results_count}
                      onChange={setFilters}
                      className='level-item'
                    />
                  </div>
                </div>
              )}
            </Fragment>
          ) : (
            <p>No repeating invoices</p>
          )}
        </div>
      </section>
    </Fragment>
  )
}
