import React from 'react'
import {Redirect, Link} from 'react-router-dom'
import useUser from 'customer/hooks/useUser'
import useStateObject from 'admin/hooks/useStateObject'
import useAccounts from 'customer/hooks/useAccounts'
import useEffect from 'customer/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'
import InputField from 'customer/components/ui/InputField'
import auth from 'customer/lib/auth'
import useDocument from 'customer/hooks/useDocument'

export default props => {
  const {loginUser, error, loginStatus, saveUser} = useUser()
  const {accounts, getAccounts, getAccountsStatus} = useAccounts()
  const [credentials, setCredentials] = useStateObject({email: '', password: ''})
  const [_flash, setFlash] = useFlash()
  useDocument({title: 'Login'})

  useEffect(saveUser, []) // to clear errors on mount

  useEffect(() => {
    if (loginStatus.fetched || auth.isValid()) {
      if (!error.data && !getAccountsStatus.fetching && !getAccountsStatus.fetched) {
        setFlash('Signed in successfully')
        getAccounts()
      } else {
        setFlash('There are error(s) on the form', {type: 'alert'})
      }
    }
  }, [loginStatus])

  useEffect(
    () => {
      if ((loginStatus.fetched || auth.isValid()) && !error.data && getAccountsStatus.fetched) {
        props.history.replace(accounts.length ? `/accounts/${accounts[0].id}/transactions` : '/accounts')
      }
    },
    [getAccountsStatus],
    false
  )

  const handleSubmit = e => {
    e.preventDefault()
    loginUser(credentials)
  }

  const failed = error.status == 401

  return (
    <form action='' onSubmit={handleSubmit} className='box' style={{padding: '3em'}}>
      <div className='block'>
        <h1 className='title has-text-dark'>Login</h1>
      </div>
      <div className='block'>
        <div className='field'>
          <InputField
            type='email'
            name='email'
            label='Email'
            onChange={setCredentials}
            value={credentials.email}
            errors={failed ? ['Incorrect email or password'] : null}
          />
        </div>
        <div className='field'>
          <InputField
            type='password'
            name='password'
            label='Password'
            onChange={setCredentials}
            value={credentials.password}
            errors={failed ? ['Incorrect email or password'] : null}
          />
        </div>
      </div>
      <div className='block field'>
        <button className='button is-success'>Login</button>
      </div>

      <div className='block field'>
        <Link to='/login/forgotpassword' className='button is-light'>
          Forgot your password?
        </Link>
      </div>
    </form>
  )
}
