import {useSelector, useDispatch} from 'react-redux'
import {getOrganisation} from 'customer/actions/Organisation.actions'
import useEffect from 'customer/hooks/useEffect'

const mapState = state => ({
  organisation: state.organisation.data,
  error: state.organisation.error,
  getOrganisationStatus: state.organisation.getStatus
})

export default () => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)

  useEffect(() => {
    if (!store.getOrganisationStatus.fetching && !store.getOrganisationStatus.fetched) getOrganisation()(dispatch)
  }, [store.getOrganisationStatus])

  return store
}
