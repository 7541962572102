import React, {Fragment} from 'react'
import {pluralise} from 'admin/lib/format'

export default (Component, {onSelect, identifier = 'id', selectedIdentifiers}) => props => {
  const allIdentifiers = props.data.map(item => item[identifier])

  const handleSelectOne = identifier => {
    if (selectedIdentifiers.includes(identifier)) {
      onSelect(selectedIdentifiers.filter(id => id !== identifier))
    } else {
      onSelect(selectedIdentifiers.concat(identifier))
    }
  }

  const handleSelectAll = e => {
    onSelect(e.currentTarget.checked ? allIdentifiers : [])
  }

  const SelectAll = props => (
    <input
      type='checkbox'
      checked={allIdentifiers.length > 0 && selectedIdentifiers.length === allIdentifiers.length}
      onChange={handleSelectAll}
    />
  )

  const SelectOne = ({identifier}) => (
    <input
      type='checkbox'
      checked={selectedIdentifiers.includes(identifier)}
      onChange={() => handleSelectOne(identifier)}
    />
  )

  return <Component {...props} SelectAll={SelectAll} SelectOne={SelectOne} />
}
