import {useSelector, useDispatch} from 'react-redux'
import {getPastTransactions} from 'customer/actions/Transactions.actions'
import types from 'customer/types/Transactions.types'

const mapState = state => ({
  transactions: state.pastTransactions.data,
  getTransactionsStatus: state.pastTransactions.getStatus,
  filters: state.pastTransactions.filters,
  stats: state.pastTransactions.stats
})

export default accountID => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)

  return {
    ...store,
    getTransactions: () => getPastTransactions(accountID, store.filters)(dispatch),
    saveFilters: data => dispatch({type: types.SAVE_PAST_TRANSACTIONS_FILTERS, data: {...store.filters, ...data}})
  }
}
