import types from 'customer/types/Account.types'
import send from 'customer/lib/action'

export const getAccount = id =>
  send({
    method: 'get',
    path: `/accounts/${id}.json`,
    baseType: types.GET_ACCOUNT
  })

export const updateAccount = (id, data) =>
  send({
    method: 'put',
    path: `/accounts/${id}.json`,
    baseType: types.UPDATE_ACCOUNT,
    data
  })
