import {useQuery, gql} from 'admin/lib/apollo'

export const GET_SCHEDULE = gql`
  query GetSchedule($organisationId: ID!, $accountId: ID!) {
    schedule(organisationId: $organisationId, accountId: $accountId) {
      ... on Invoice {
        id
        date
        amount
        description @client
        isSendable
        isSent
        state
      }
      ... on Transaction {
        id
        date
        amount
        description
      }
      ... on RepeatingInvoice {
        id
        nextInvoiceDate
        frequency
        frequencyCount
        amount
        description @client
        isSendable
        state
      }
    }
  }
`

export default (organisationId, accountId) => {
  const {data: {schedule = []} = {}} = useQuery(GET_SCHEDULE, {variables: {organisationId, accountId}})
  return {schedule}
}
