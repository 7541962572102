import React, {Fragment} from 'react'
import {useParams} from 'react-router-dom'
import useStateObject from 'admin/hooks/useStateObject'
import useTextMessage from 'admin/hooks/useTextMessage'
import InputField from 'admin/components/ui/InputField'
import {pluralise} from 'admin/lib/format'

export default ({accountIds, onSend, sender = ''}) => {
  const {organisationID} = useParams()
  const {errors, create} = useTextMessage(organisationID)
  const [form, setForm] = useStateObject({contentWithPlaceholders: ''})

  const fullMessage = `${sender.toUpperCase()}: ${form.contentWithPlaceholders}\nDoNotReply. SMS&DataRatesApply`

  const handleSubmit = e => {
    e.preventDefault()
    create({accountIds, contentWithPlaceholders: fullMessage}, onSend)
  }

  return (
    <Fragment>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title'>New text message</h3>
            </div>
            <div className='column buttons is-right'>
              <button className='button is-primary' onClick={handleSubmit}>
                Send to {pluralise('selected account', accountIds.length, true)}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container content is-size-7'>
          Please note:
          <ul>
            <li>Only accounts with valid mobile numbers will be included</li>
            <li>Accounts with multiple mobile numbers will receive a message to each mobile number</li>
          </ul>
        </div>
        <div className='columns'>
          <div className='column is-half'>
            <label className='label is-small'>Preview</label>
            <div
              className='box has-linebreaks has-text-weight-medium has-background-filters is-shadowless'
              style={{marginBottom: '0', overflowWrap: 'break-word'}}>
              {fullMessage}
            </div>
            <span className='help has-text-right'>
              {fullMessage.length} characters. {pluralise('message part', Math.ceil(fullMessage.length / 160), true)}.
            </span>
          </div>
          <div className='column is-half'>
            <form>
              <div className='field'>
                <div className='control'>
                  <InputField
                    type='textarea'
                    name='contentWithPlaceholders'
                    label='Message'
                    rows={5}
                    errors={errors.contentWithPlaceholders}
                    value={form.contentWithPlaceholders}
                    onChange={setForm}
                    help={
                      <span>
                        Refer to the{' '}
                        <a href='https://www.dia.govt.nz/spam' target='_blank'>
                          Department of Internal Affairs website
                        </a>{' '}
                        for electronic message regulations.
                      </span>
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
