import React from 'react'
import {Link, useLocation, matchPath} from 'react-router-dom'

export default ({collection, active, className = '', boxed = true}) => {
  const {pathname, search} = useLocation()
  const carriedSearch = search.replace(/&?(page|limit)=\d+/gi, '')

  if (!active) {
    let tab = collection.find(({to}) => matchPath(pathname, {path: to, exact: false}))
    active = tab ? tab.label : null
  }

  return (
    <div className={`tabs ${boxed ? 'is-boxed' : ''} ${className}`}>
      <ul className='container'>
        {collection.map(({label, to}, i) => (
          <li key={i} className={active === label ? 'is-active has-text-weight-semibold' : ''}>
            <Link to={`${to}${carriedSearch}`} className='is-decorationless'>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
