import React from 'react'
import InputField from 'admin/components/ui/InputField'
import DateField from 'admin/components/ui/DateField'

export default ({fromDate, toDate, q, onSubmit}) => {
  const handleSearch = changes => {
    onSubmit({...changes, page: 1})
  }

  return (
    <form className='box is-radiusless is-shadowless has-background-filters'>
      <div className='field is-grouped'>
        <div className='control is-expanded'>
          <InputField type='search' name='q' label='Search' autoComplete='off' value={q} onChange={handleSearch} />
        </div>
        <div className='control'>
          <DateField name='from_date' value={fromDate} onChange={handleSearch} />
        </div>
        <div className='control'>
          <DateField name='to_date' value={toDate} onChange={handleSearch} />
        </div>
      </div>
    </form>
  )
}
