import {useEffect, useRef} from 'react'
import diff from 'deep-diff'

export default (callback, dependencies, onMount = true) => {
  const didMountRef = useRef(false)
  useEffect(
    () => {
      if (onMount) callback()
      else if (didMountRef.current) callback()
      else didMountRef.current = true
    },
    (value => {
      const ref = useRef()
      if (diff(value, ref.current)) ref.current = value
      return ref.current
    })(dependencies)
  )
}
