import Form from 'admin/components/Invoice/Form'
import Breadcrumbs from 'admin/components/ui/Breadcrumbs'
import Flash from 'admin/components/Flash'
import useDocument from 'admin/hooks/useDocument'
import useEffect from 'admin/hooks/useEffect'
import useInvoice from 'admin/hooks/useInvoice'
import useStateObject from 'admin/hooks/useStateObject'
import React, {Fragment} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {NumberParam, StringParam, useQueryParams} from 'use-query-params'

export default () => {
  const history = useHistory()
  const {organisationID} = useParams()
  const [{accountId, accountName}] = useQueryParams({accountId: NumberParam, accountName: StringParam})
  const {
    invoice = {
      accountId,
      account: {
        id: accountId,
        displayName: accountName
      },
      email: {}
    },
    errors,
    create
  } = useInvoice(organisationID)
  const [form, setForm] = useStateObject(invoice)

  useDocument({title: 'New invoice'})

  useEffect(() => setForm(invoice), [invoice], false)

  const handleCreate = e => {
    e.preventDefault()
    create(form, ({invoice}) => history.replace(`/orgs/${organisationID}/invoices/${invoice.id}`))
  }

  const breadcrumbs = []
  if (invoice.account?.id) {
    breadcrumbs.push({
      label: 'Manage accounts',
      path: `/orgs/${organisationID}/accounts`
    })
    breadcrumbs.push({
      label: invoice.account.displayName,
      path: `/orgs/${organisationID}/accounts/${invoice.account.id}`
    })
  } else {
    breadcrumbs.push({
      label: 'Invoices',
      path: `/orgs/${organisationID}/invoices`
    })
  }

  return (
    <Fragment>
      <Flash />
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered'>
            <div className='is-5 column'>
              <Breadcrumbs breadcrumbs={breadcrumbs}>
                <h3 className='title is-marginless'>New invoice</h3>
              </Breadcrumbs>
            </div>
            <div className='is-7 column buttons is-right'>
              <button className='button is-success' onClick={handleCreate}>
                Create
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <Form errors={errors} organisationID={organisationID} invoice={form} onChange={setForm} />
        </div>
      </section>
    </Fragment>
  )
}
