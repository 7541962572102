import React, {Fragment, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {toParams} from 'customer/lib/format'
import usePastTransactions from 'customer/hooks/usePastTransactions'
import useEffect from 'customer/hooks/useEffect'
import Tabs from 'customer/components/ui/Tabs'
import PaymentRow from './PaymentRow'
import InvoiceRow from './InvoiceRow'
import Filters from './Filters'

export default ({match}) => {
  const {accountID} = match.params
  const {transactions, getTransactions, getTransactionsStatus, filters, saveFilters, stats} = usePastTransactions(
    accountID
  )
  const [expandedRowIndex, setExpandedRowIndex] = useState(null)

  let {q, from_date, to_date, page} = filters

  useEffect(() => {
    if (!getTransactionsStatus.fetching) getTransactions()
    setExpandedRowIndex(null)
  }, [accountID, filters])

  return (
    <Fragment>
      <Tabs
        className='is-fullwidth-mobile block'
        boxed={false}
        active='View statement'
        collection={[
          {label: 'View statement', to: `/accounts/${accountID}/transactions`},
          {label: 'Edit contact details', to: `/accounts/${accountID}`}
        ]}
      />

      <div className='container'>
        <Filters q={q} fromDate={from_date} toDate={to_date} onSubmit={saveFilters} />

        <div className='buttons is-centered'>
          <a href={`transactions.pdf?${toParams({q, from_date, to_date})}`} className='button is-light'>
            Download statement
          </a>
        </div>

        <InfiniteScroll
          dataLength={transactions.length}
          next={() => saveFilters({page: page + 1})}
          hasMore={transactions.length < stats.unpaginated_count}
          loader={<p className='has-text-centered'>Loading...</p>}
          endMessage={
            <p className='has-text-centered'>
              No more transactions.
              <br />
              <span className='is-size-7 has-text-grey'>
                Please note: Transactions can only be viewed as far back as 1 May 2019.
              </span>
            </p>
          }>
          {transactions.map((transaction, i) =>
            transaction.type === 'payment' ? (
              <PaymentRow
                key={i}
                {...transaction}
                expand={expandedRowIndex === i}
                onClick={() => setExpandedRowIndex(expandedRowIndex === i ? null : i)}
              />
            ) : (
              <InvoiceRow
                key={i}
                {...transaction}
                expand={expandedRowIndex === i}
                onClick={() => setExpandedRowIndex(expandedRowIndex === i ? null : i)}
              />
            )
          )}
        </InfiniteScroll>
      </div>
    </Fragment>
  )
}
