import React, {useState} from 'react'
import useEffect from 'admin/hooks/useEffect'
import InputField from 'admin/components/ui/InputField'

export default ({q, organisationID, onSubmit}) => {
  const handleSearch = changes => {
    onSubmit({...changes, page: 1})
  }

  return (
    <form className='box is-radiusless is-shadowless has-background-filters'>
      <div className='field is-grouped'>
        <div className='control is-expanded'>
          <InputField type='search' name='q' label='Search' autoComplete='off' value={q} onChange={handleSearch} />
        </div>
      </div>
    </form>
  )
}
