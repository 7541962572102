import React from 'react'
import DateField from 'admin/components/ui/DateField'
import moment from 'admin/lib/moment'

export default ({fromDate, toDate, onSubmit}) => {
  const handleSearch = changes => onSubmit({...changes, page: 1})

  return (
    <form className='box is-radiusless is-shadowless has-background-filters'>
      <div className='field is-grouped'>
        <div className='control is-expanded'>
          <DateField name='fromDate' label='From date' value={fromDate} onChange={handleSearch} />
        </div>
        <div className='control is-expanded'>
          <DateField name='toDate' label='To date' value={toDate} onChange={handleSearch} maxDate={moment().toDate()} />
        </div>
      </div>
    </form>
  )
}
