import createReducer from 'customer/reducers/createReducer'

const INITIAL_REQUEST_STATE = {}

export default baseType =>
  createReducer(INITIAL_REQUEST_STATE, {
    [baseType]: state => ({...state, fetching: true, fetched: false}),
    [`REJECTED_${baseType}`]: state => ({...state, fetching: false, fetched: true}),
    [`FULFILLED_${baseType}`]: state => ({...state, fetching: false, fetched: true}),
    [`CLEAR_${baseType}`]: () => INITIAL_REQUEST_STATE
  })
