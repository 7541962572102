import client, {parseError, useMutation} from 'admin/lib/apollo'
import {BASE_GROUP_FIELDS, CREATE_GROUP, UPDATE_GROUP} from 'admin/gql/Group.gql'
import useDestroyable from 'admin/hooks/useDestroyable'
import clearCache from 'admin/hooks/clearCache'
import mutateWith from 'admin/hooks/mutateWith'

export default (organisationId, type, groupId) => {
  const variables = {organisationId, groupId}

  const group =
    client.readFragment({
      id: `Group:${groupId}`,
      fragment: BASE_GROUP_FIELDS
    }) || {}

  const [create, {error: createError}] = useMutation(CREATE_GROUP, {
    variables,
    errorPolicy: 'all',
    update: (cache, {errors}) => !errors && clearCache({groups: {organisationId, type}}, {broadcast: true})(cache)
  })

  const [update, {error: updateError}] = useMutation(UPDATE_GROUP, {
    variables,
    errorPolicy: 'all'
  })

  const {destroy, errors: destroyError} = useDestroyable(organisationId, 'Group', groupId)

  return {
    group,
    errors: parseError(createError || updateError) || destroyError,
    create: mutateWith(create, 'Group created'),
    update: mutateWith(update, 'Group updated'),
    destroy
  }
}