import {useSelector, useDispatch} from 'react-redux'
import {getAccount, updateAccount} from 'customer/actions/Account.actions'
import types from 'customer/types/Account.types'

const mapState = state => ({
  account: state.account.data,
  error: state.account.error,
  getAccountStatus: state.account.getStatus,
  updateAccountStatus: state.account.updateStatus
})

export default accountID => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)
  return {
    ...store,
    getAccount: () => getAccount(accountID)(dispatch),
    saveAccount: data => dispatch({type: types.SAVE_ACCOUNT, data: {...store.account, ...data}}),
    updateAccount: () => updateAccount(accountID, store.account)(dispatch)
  }
}
