import React, {Fragment} from 'react'
import {titlecase} from 'admin/lib/format'

const preventDefault = e => {
  if (e.preventDefault) e.preventDefault()
  e.returnValue = false
}

const blockScroll = () => {
  // disable mousewheel on a input number field when in focus
  // (to prevent Chromium browsers change the value when scrolling)
  window.addEventListener('DOMMouseScroll', preventDefault, false) // older Firefox
  document.addEventListener('wheel', preventDefault, {passive: false}) // Chrome
}

const releaseScroll = () => {
  window.removeEventListener('DOMMouseScroll', preventDefault, false)
  document.removeEventListener('wheel', preventDefault, {passive: false})
}

export default React.forwardRef(
  (
    {
      children,
      className,
      style,
      required,
      label,
      options,
      errors,
      onChange,
      showLabel = true,
      help,
      addOn,
      onClick,
      isHorizontal,
      ...props
    },
    ref
  ) => {
    let handleChange = e => {
      let value = e.target.value
      if (props.type === 'number' && value !== '') value = Number(Math.abs(value))
      onChange({[e.target.name]: value})
      e.preventDefault()
    }

    if (props.type == 'checkbox') {
      handleChange = () => onChange({[props.name]: !props.checked})
      return (
        <Fragment>
          <div className='field'>
            <div className='control'>
              <label className='label is-small' htmlFor={props.name} required={required}>
                <input
                  {...props}
                  ref={ref}
                  id={props.name}
                  onChange={handleChange}
                  className={`checkbox ${errors ? 'is-danger' : ''}`}
                />
                &nbsp;{label || titlecase(props.name)}
              </label>
              {children}
            </div>
            {help && <span className='help'>{help}</span>}
            {errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
          </div>
        </Fragment>
      )
    } else if (props.type == 'textarea') {
      return (
        <Fragment>
          <div className='field' style={style}>
            <label htmlFor={props.name} required={required} className='label is-small'>
              {label || titlecase(props.name)}
            </label>
            <div className='control'>
              <textarea
                ref={ref}
                id={props.name}
                {...props}
                onChange={handleChange}
                className={`textarea ${errors ? 'is-danger' : ''} ${className || ''}`}
              />
            </div>
            {help && <span className='help'>{help}</span>}
            {errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
          </div>
          {children}
        </Fragment>
      )
    } else {
      const theLabel = (
        <label htmlFor={props.id || props.name} className='label is-small' required={required}>
          {label || titlecase(props.name)}
        </label>
      )

      const theField = (
        <div className={`field ${children || addOn ? 'has-addons' : ''}`}>
          {!isHorizontal && !children && !addOn && showLabel && theLabel}

          <div className='control'>
            <input
              id={props.name}
              {...props}
              ref={ref}
              className={`input ${errors ? 'is-danger' : ''} ${className || ''}`}
              onChange={handleChange}
              onMouseEnter={props.type == 'number' ? blockScroll : null}
              onMouseLeave={props.type == 'number' ? releaseScroll : null}
            />
          </div>

          {help && <span className='help'>{help}</span>}
          {children ||
            (addOn && (
              <div className='control'>
                {children || (
                  <a onClick={onClick} className='button is-info'>
                    {addOn}
                  </a>
                )}
              </div>
            ))}
          {!children && errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
        </div>
      )

      return (
        <Fragment>
          {isHorizontal ? (
            <div className='field is-horizontal'>
              <div className='field-label is-small has-text-left is-flex-grow-2'>{theLabel}</div>
              <div className='field-body'>{theField}</div>
            </div>
          ) : (
            <Fragment>
              {(children || addOn) && showLabel && theLabel}
              {theField}
            </Fragment>
          )}

          {children && errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
        </Fragment>
      )
    }
  }
)
