import React from 'react'
import {connect} from 'react-redux'
import Breadcrumbs from 'admin/components/ui/Breadcrumbs'

const Header = ({breadcrumbs, children, subheading, light}) => {
  const Content = () => (
    <div className='columns is-vcentered is-mobile'>
      <div className='column'>
        <Breadcrumbs breadcrumbs={breadcrumbs.slice(0, -1)}>
          {breadcrumbs.length && <h3 className='title'>{breadcrumbs.slice(-1)[0].label}</h3>}
        </Breadcrumbs>
        {subheading && <h4 className='subtitle'>{subheading}</h4>}
      </div>
      <div className='column'>{children}</div>
    </div>
  )

  return light ? (
    <Content />
  ) : (
    <section className='section has-background-light'>
      <div className='container'>
        <Content />
      </div>
    </section>
  )
}

const mapStateToProps = ({breadcrumbs}) => ({breadcrumbs})

export default connect(mapStateToProps, null)(Header)
