import variationsFor from './variationsFor'

export const requestTypes = [
  'LOGIN',
  'LOGOUT',
  'GET_USER',
  'UPDATE_USER',
  'CONFIRM_EMAIL',
  'FORGOT_PASSWORD',
  'CHANGE_PASSWORD',
  'SET_PASSWORD'
]

export default requestTypes.reduce(
  (reducedTypes, requestType) => ({
    ...reducedTypes,
    ...variationsFor(requestType),
    SAVE_USER: 'SAVE_USER'
  }),
  {}
)
