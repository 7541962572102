import types from 'admin/types/User.types'
import send from 'admin/lib/action'

export const getUser = (organisationID, id) =>
  send({
    method: 'get',
    path: `/orgs/${organisationID}/users/${id}.json`,
    baseType: types.GET_USER
  })

export const updateUser = (organisationID, id, data) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/users/${id}`,
    baseType: types.UPDATE_USER,
    data
  })

export const createUser = (organisationID, data) =>
  send({
    method: 'post',
    path: `/orgs/${organisationID}/users`,
    baseType: types.CREATE_USER,
    data
  })

export const inviteUser = (organisationID, data) =>
  send({
    method: 'post',
    path: `/orgs/${organisationID}/users/invitation`,
    baseType: types.INVITE_USER,
    data
  })

export const setupUser = (organisationID, data) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/users/invitation`,
    baseType: types.SETUP_USER,
    data
  })

export const sendUserInvitation = (organisationID, id) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/users/${id}/invite`,
    baseType: types.SEND_USER_INVITATION
  })

export const sendUserVerification = (organisationID, id) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/users/${id}/verify`,
    baseType: types.SEND_USER_VERIFICATION
  })

export const revokeUserAccess = (organisationID, id) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/users/${id}/revoke`,
    baseType: types.REVOKE_USER_ACCESS
  })

export const restoreUserAccess = (organisationID, id) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/users/${id}/restore`,
    baseType: types.RESTORE_USER_ACCESS
  })

export const destroyUser = (organisationID, id) =>
  send({
    method: 'delete',
    path: `/orgs/${organisationID}/users/${id}`,
    baseType: types.DESTROY_USER
  })
