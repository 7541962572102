import {GET_ACCOUNT, CREATE_ACCOUNT, UPDATE_ACCOUNT} from 'admin/gql/Account.gql'
import {GET_HISTORY} from 'admin/gql/History.gql'
import {BULK_ACCOUNTS} from 'admin/gql/Accounts.gql'
import {parseError, useQuery, useMutation} from 'admin/lib/apollo'
import mutateWith from 'admin/hooks/mutateWith'

export default (organisationId, accountId) => {
  const variables = {organisationId, accountId}

  const {
    data: {account = {}} = {},
    error: getError,
    loading: getLoading
  } = useQuery(GET_ACCOUNT, {variables, skip: !accountId})

  const [create, {error: createError, loading: createLoading}] = useMutation(CREATE_ACCOUNT, {
    variables,
    errorPolicy: 'all',
    refetchQueries: ['GetAccounts']
  })

  const [update, {error: updateError, loading: updateLoading}] = useMutation(UPDATE_ACCOUNT, {
    variables,
    errorPolicy: 'all',
    refetchQueries: [{query: GET_HISTORY, variables: {itemType: 'Account', itemId: accountId}}]
  })

  const [recalculate] = useMutation(BULK_ACCOUNTS, {
    variables: {organisationId, task: 'recalculate'},
    errorPolicy: 'all'
  })

  return {
    account,
    errors: parseError(getError || createError || updateError),
    loading: getLoading || createLoading || updateLoading,
    create: mutateWith(create, 'Account created'),
    update: mutateWith(update, 'Account updated'),
    recalculate: mutateWith(recalculate, 'Account recalculated')
  }
}
