import {GET_ORGANISATION, UPDATE_ORGANISATION} from 'admin/gql/Organisation.gql'
import {parseError, useQuery, useMutation} from 'admin/lib/apollo'
import mutateWith from 'admin/hooks/mutateWith'

export default organisationId => {
  const {data: {organisation = {}} = {}, error: getError} = useQuery(GET_ORGANISATION, {
    variables: {organisationId},
    skip: !organisationId
  })

  const [update, {error: updateError}] = useMutation(UPDATE_ORGANISATION, {
    variables: {organisationId},
    errorPolicy: 'all'
  })

  return {
    organisation,
    errors: parseError(getError || updateError),
    update: mutateWith(update, 'Organisation updated')
  }
}
