import {gql} from '@apollo/client'

export const GET_SERVICES = gql`
  query GetServices($organisationId: ID!, $accountId: ID!) {
    services(organisationId: $organisationId, accountId: $accountId) {
      id
      description
      macAddress
      plan {
        id
        name
        color
      }
    }
  }
`
