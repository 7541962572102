import React, {Fragment} from 'react'
import {capitalize} from 'customer/lib/format'

const ErrorWrapper = ({errors, children}) => {
  if (React.Children.count(children) == 1) {
    return (
      <React.Fragment>
        {errors ? (
          <div className='field_with_errors'>
            {children}
            <div className='error'>{errors.join(', ')}</div>
          </div>
        ) : (
          children
        )}
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {errors ? <div className='field_with_errors'>{children[0]}</div> : children[0]}
        {errors ? (
          <div className='field_with_errors'>
            {children[1]}
            <div className='error'>{errors.join(', ')}</div>
          </div>
        ) : (
          children[1]
        )}
      </React.Fragment>
    )
  }
}

export default React.forwardRef(
  (
    {
      children,
      className,
      style,
      required,
      label,
      options,
      errors,
      onChange,
      showLabel = true,
      help,
      addOn,
      onClick,
      ...props
    },
    ref
  ) => {
    let handleChange = e => {
      onChange({[e.target.name]: e.target.value})
      e.preventDefault()
    }

    if (props.type == 'checkbox') {
      handleChange = () => onChange({[props.name]: !props.checked})
      return (
        <Fragment>
          <div className='field'>
            <div className='control'>
              <label className='label is-small' htmlFor={props.name} required={required}>
                <input
                  {...props}
                  ref={ref}
                  id={props.name}
                  onChange={handleChange}
                  className={`checkbox ${errors ? 'is-danger' : ''}`}
                />
                &nbsp;{label || capitalize(props.name)}
              </label>
              {children}
            </div>
            {help && <span className='help'>{help}</span>}
            {errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
          </div>
        </Fragment>
      )
    } else if (props.type == 'textarea') {
      return (
        <Fragment>
          <div className='field' style={style}>
            <label htmlFor={props.name} required={required} className='label is-small'>
              {label || capitalize(props.name)}
            </label>
            <div className='control'>
              <textarea
                ref={ref}
                id={props.name}
                {...props}
                onChange={handleChange}
                className={`textarea  ${className || ''}`}
              />
            </div>
            {help && <span className='help'>{help}</span>}
            {errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
          </div>
          {children}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {(children || addOn) && showLabel && (
            <label htmlFor={props.name} className='label is-small' required={required}>
              {label || capitalize(props.name)}
            </label>
          )}

          <div className={`field ${children || addOn ? 'has-addons' : ''}`}>
            {!children && !addOn && showLabel && (
              <label htmlFor={props.name} className='label is-small' required={required}>
                {label || capitalize(props.name)}
              </label>
            )}

            <div className='control'>
              <input
                id={props.name}
                {...props}
                ref={ref}
                className={`input ${errors ? 'is-danger' : ''} ${className || ''}`}
                onChange={handleChange}
              />
            </div>

            {help && <span className='help'>{help}</span>}
            {children ||
              (addOn && (
                <div className='control'>
                  {children || (
                    <a onClick={onClick} className='button is-info'>
                      {addOn}
                    </a>
                  )}
                </div>
              ))}
            {!children && errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
          </div>
          {children && errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
        </Fragment>
      )
    }
  }
)
