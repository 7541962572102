import React, {Fragment} from 'react'
import {Link, Route, matchPath} from 'react-router-dom'
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params'
import {pluralise} from 'admin/lib/format'
import useUsers from 'admin/hooks/useUsers'
import useEffect from 'admin/hooks/useEffect'
import useDocument from 'admin/hooks/useDocument'
import Flash from 'admin/components/Flash'
import NewUser from 'admin/components/NewUser'
import Pagination from 'admin/components/ui/Pagination'
import Table from './Table'
import Filters from './Filters'

export default ({match, history, location}) => {
  const {organisationID} = match.params
  const {error, users, counts, getUsers, getUsersStatus} = useUsers(organisationID)
  const [filters, setFilters] = useQueryParams({
    q: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50)
  })

  useEffect(() => {
    if (!getUsersStatus.fetched || matchPath(location.pathname, {path: match.path, exact: true})) getUsers(filters)
  }, [filters])

  useDocument({title: 'Users'})

  const handleRowClick = userID => {
    history.push(`/orgs/${organisationID}/users/${userID}`)
  }

  return (
    <Fragment>
      <Flash />

      <Route exact path={`${match.path}/new`} component={NewUser} />

      <section className='section'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title'>Manage users</h3>
              <h4 className='subtitle'>{`Showing ${counts.paginated_count || 0} of ${pluralise(
                `user`,
                counts.unpaginated_count || 0,
                true
              )}`}</h4>
            </div>
            <div className='column buttons is-right'>
              <Link to={`/orgs/${organisationID}/users/new`} className='button is-light'>
                New user
              </Link>
            </div>
          </div>
          <Filters {...filters} organisationID={organisationID} onSubmit={setFilters} />
          {counts.unpaginated_count != 0 ? (
            <Fragment>
              <div className='level'>
                <div className='level-left'></div>
                <div className='level-right'>
                  <Pagination
                    page={filters.page}
                    limit={filters.limit}
                    total={counts.unpaginated_count}
                    onChange={setFilters}
                    className='level-item'
                  />
                </div>
              </div>
              <Table data={users} onClick={handleRowClick} />
              <div className='level'>
                <div className='level-left'></div>
                <div className='level-right'>
                  <Pagination
                    page={filters.page}
                    limit={filters.limit}
                    total={counts.unpaginated_count}
                    onChange={setFilters}
                    className='level-item'
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            <p>No users</p>
          )}
        </div>
      </section>
    </Fragment>
  )
}
