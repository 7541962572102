import useFlash from 'admin/hooks/useFlash'

export default (mutation, flashMessage) => {
  const [_flash, setFlash] = useFlash()

  return (variables, onComplete) =>
    mutation({variables}).then(({errors, data}) => {
      if (!errors) {
        if (onComplete) onComplete(data)
        if (flashMessage) setFlash(flashMessage)
      } else {
        setFlash('There are error(s) on the form', {type: 'alert'})
      }
    })
}
