import {useQuery, gql} from '@apollo/client'
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params'
import useEffect from 'admin/hooks/useEffect'

// lng1, lat1 (southWest), lng2, lat2 (northEast)
const NZ_BOUNDS = '187.82226562500003, -34.016241889667015, 159.71923828125003, -47.27922900257082'

const GET_SERVICE_TOWERS = gql`
  query GetServiceTowers($organisationId: ID!, $bounds: String, $coordsOnly: Boolean) {
    serviceTowers(organisationId: $organisationId, bounds: $bounds, coordsOnly: $coordsOnly) {
      id
      name
      coords
      coverage
    }
  }
`

export default organisationId => {
  const [filters, setFilters] = useQueryParams({
    bounds: withDefault(StringParam, NZ_BOUNDS),
    zoom: NumberParam
  })

  const variables = {organisationId, bounds: filters.bounds, coordsOnly: !filters.zoom || filters.zoom < 13}

  const {data} = useQuery(GET_SERVICE_TOWERS, {variables})
  const response = data ? data.serviceTowers : []

  return {filters, setFilters, serviceTowers: response}
}
