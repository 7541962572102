import moment from 'moment-timezone'

moment.tz.setDefault('Pacific/Auckland')

const TODAY = 0
const A_YEAR_AGO = -366

export const dateIsTodayOrInThePast = date => moment(date).diff(moment().startOf('day'), 'days') <= TODAY
export const dateIsInThePast = date => date && moment(date).diff(moment().startOf('day'), 'days') < TODAY
export const dateIsTodayOrInTheFuture = date => date && moment(date).diff(moment().startOf('day'), 'days') >= TODAY
export const dateIsInTheFuture = date => date && moment(date).diff(moment().startOf('day'), 'days') > TODAY
export const dateIsMoreThanAYearAgo = date => date && moment(date).diff(moment().startOf('day'), 'days') < A_YEAR_AGO
export const dateIsToday = date => date && moment(date).diff(moment().startOf('day'), 'days') == TODAY

export default moment
