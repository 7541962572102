import moment from 'customer/lib/moment'
import {toParams} from 'customer/lib/format'
import send from 'customer/lib/action'
import types from 'customer/types/Transactions.types'

export const getPastTransactions = (accountID, data) => {
  if (data.to_date) data.to_date = moment(data.to_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
  return send({
    method: 'get',
    path: `/accounts/${accountID}/transactions.json?${toParams(data)}`,
    baseType: types.GET_PAST_TRANSACTIONS,
    data
  })
}

export const getFutureTransactions = accountID => {
  let data = {
    from_date: moment()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    types: ['invoices', 'repeating_invoices'],
    states: ['reconciled', 'not_deleted', 'not_draft', 'not_ignored']
  }
  return send({
    method: 'get',
    path: `/accounts/${accountID}/transactions.json?${toParams(data)}`,
    baseType: types.GET_FUTURE_TRANSACTIONS,
    data
  })
}
