import React from 'react'
import {useParams, useHistory} from 'react-router-dom'
import useGroup from 'admin/hooks/useGroup'
import useDocument from 'admin/hooks/useDocument'
import useStateObject from 'admin/hooks/useStateObject'
import useEffect from 'admin/hooks/useEffect'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'

const DESTROY_CONFIRMATION = 'Are you sure you want to delete?'

export default () => {
  const history = useHistory()
  const {organisationID, type, groupID} = useParams()
  const {errors, group, update, destroy} = useGroup(organisationID, type, groupID)
  const [form, setForm] = useStateObject(group)

  useDocument({title: `Edit ${group.name}`})

  useEffect(() => setForm(group), [group])

  useEffect(() => {
    jscolor.installByClassName('jscolor')
  })

  const handleColorChange = e => {
    e.preventDefault()
    setForm({colour: e.target.value})
  }

  const handleDestroy = e => {
    e.preventDefault()
    if (confirm(DESTROY_CONFIRMATION)) destroy({}, history.goBack)
  }

  const handleSave = e => {
    e.preventDefault()
    update(form, history.goBack)
  }

  return (
    <Modal onClose={history.goBack} isOpen={true} minWidth='50%'>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title is-marginless'>Edit {group.name}</h3>
            </div>
            <div className='column buttons is-right'>
              <button className='button is-danger is-outlined' onClick={handleDestroy}>
                Delete
              </button>
              <button className='button is-primary' onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <form>
            <div className='field is-grouped'>
              <div className='control is-expanded'>
                <InputField
                  type='text'
                  name='name'
                  autoComplete='off'
                  value={form.name || ''}
                  errors={errors.name}
                  onChange={setForm}
                />
              </div>
              <div className='control'>
                <label htmlFor='colour' className='label is-small'>
                  Colour
                </label>
                <input
                  type='text'
                  name='colour'
                  autoComplete='off'
                  value={form.colour || ''}
                  className='input jscolor'
                  onChange={handleColorChange}
                  onBlur={handleColorChange}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  )
}
