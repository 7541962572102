import {isEqual, isMatch, isArray, some} from 'lodash'

// Invalidates fields causing Apollo to re-fetch from the server
// EXAMPLES:
// {services: [{accountId: 1}, {accountId: 2}]} - invalidates services queries with accountId: 1 or accountId:2
// {accounts: {organisationId: 1}} - invalidates accounts queries with organisationId: 1
// {accounts: null} - invalidates all accounts queries
// {accounts: null, services: null} - invalidates all accounts and services queries
export default (fieldNamesAndArguments, {broadcast} = {broadcast: false}) => {
  return cache => {
    let fields = Object.entries(fieldNamesAndArguments).reduce((memo, [fieldName, fieldArgs]) => {
      memo[fieldName] = (storeFieldValue, {storeFieldName, DELETE}) => {
        if (fieldArgs) {
          // storeFieldName = full internal key including serialized key arguments e.g. "services({'accountId':1})"
          let storeFieldArgs = JSON.parse(storeFieldName.match(new RegExp(`^${fieldName}\\(([^)]+)\\)`))[1])

          if (!isArray(fieldArgs)) fieldArgs = [fieldArgs]

          if (storeFieldArgs.page && some(fieldArgs, args => isMatch(storeFieldArgs, args))) {
            // for paginated querys, delete on partial match
            return DELETE
          } else if (some(fieldArgs, args => isEqual(storeFieldArgs, args))) {
            // for unpaginated queries, delete on full match
            return DELETE
          } else {
            // do nothing when no fieldArgs match storeFieldArgs
            return storeFieldValue
          }
        } else {
          // delete when no fieldArgs passed in
          return DELETE
        }
      }
      return memo
    }, {})
    return cache.modify({fields, broadcast})
  }
}
