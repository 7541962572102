import decode from 'jwt-decode'
import moment from 'customer/lib/moment'

export const setToken = token => localStorage.setItem('token', token)
export const getToken = () => localStorage.getItem('token')
export const clearToken = () => localStorage.removeItem('token')
export const isValid = () => {
  let token = localStorage.getItem('token')
  if (token) {
    let payload = decode(token.split(" ")[1])
    return moment.unix(payload.exp).isAfter(moment())
  } else {
    return false
  }
}

export default {
  setToken,
  getToken,
  clearToken,
  isValid
}