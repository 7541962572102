import React from 'react'

export default ({
  type,
  label,
  value,
  values,
  children,
  showHref = true,
  className = '',
  flexGrow = true,
  alwaysHorizontal = false
}) => {
  const hrefAttr =
    showHref &&
    {
      address: () => `https://www.google.co.nz/maps?t=k&q=loc:${value.replace(/\n/, ' ')}`,
      email: () => `mailto:${value}`,
      tel: () => `tel:${value}`
    }[type]

  if (!children && (!value || value.length === 0) && !values) return null

  return (
    <div className={`field ${alwaysHorizontal ? 'is-horizontal-mobile' : 'is-horizontal'} ${className}`}>
      <div className={`field-label is-small has-text-left is-flex-grow-${flexGrow ? '2' : '0'}`}>
        <label className='label is-small'>{label}</label>
      </div>
      <div className='field-body'>
        <div style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap', width: '100%'}}>
          {values ? (
            Object.values(values)
              .filter(v => !!v)
              .join(' ')
          ) : children ? (
            children
          ) : value && hrefAttr ? (
            <a href={hrefAttr()} target='_blank'>
              {value}
            </a>
          ) : (
            value
          )}
        </div>
      </div>
    </div>
  )
}
