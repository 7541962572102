import client, {parseError, useMutation} from 'admin/lib/apollo'
import {BASE_TEXT_MESSAGE_FIELDS, SEND_TEXT_MESSAGE} from 'admin/gql/TextMessage.gql'
import useFlash from 'admin/hooks/useFlash'

export default (organisationId, textMessageId) => {
  const [flash, setFlash] = useFlash()

  const variables = {organisationId, textMessageId}

  const cachedTextMessage = client.readFragment({
    id: `TextMessage:${textMessageId}`,
    fragment: BASE_TEXT_MESSAGE_FIELDS
  })

  const [create, {data: {textMessages = {}} = {}, error: createError, loading: createLoading}] = useMutation(
    SEND_TEXT_MESSAGE,
    {
      variables,
      errorPolicy: 'all',
      refetchQueries: ['GetTextMessages']
    }
  )

  const action = (mutation, message) => (variables, onComplete) =>
    mutation({variables}).then(({data: {textMessage}, errors}) => {
      if (!errors) {
        if (onComplete) onComplete(textMessage)
        if (message) setFlash(message)
      }
    })

  return {
    textMessage: cachedTextMessage || textMessages,
    errors: parseError(createError),
    loading: createLoading,
    create: action(create, 'Text messages queued')
  }
}
