export const parseInvoiceData = invoice => ({
  ...invoice,
  date: invoice.date || '',
  days_until_due: invoice.days_until_due || '',
  line_items: invoice.line_items || [{}],
  invoice_number: invoice.invoice_number || '',
  reference: invoice.reference || ''
})

export const parseRepeatingInvoiceData = repeatingInvoice => ({
  ...repeatingInvoice,
  date: repeatingInvoice.next_invoice_date || '',
  end_date: repeatingInvoice.end_date || '',
  days_until_due: repeatingInvoice.days_until_due || '',
  line_items: repeatingInvoice.line_items || [{}],
  frequency: repeatingInvoice.frequency || 'weekly',
  frequency_count: repeatingInvoice.frequency_count || 1,
  reference: repeatingInvoice.reference || ''
})

// TODO consider doing this in the component scope
const INVOICE_TYPE_MAP = {
  draft: {states: ['draft', 'not_deleted']},
  approved: {states: ['not_draft', 'not_deleted']},
  deleted: {states: ['deleted']}
}

const findType = ({types, states}) => {
  if (!types || !states) return null
  const entry = Object.entries(INVOICE_TYPE_MAP).find(([_, filters]) =>
    filters.states.every((item, i) => item === states[i])
  )
  return entry && entry[0]
}

export const parseInvoicesResponse = response => {
  const type = findType(response.filters)
  let filters = {...response.filters, type}
  return {...response, filters}
}

export const parseInvoicesRequest = request => {
  const typeFilters = INVOICE_TYPE_MAP[request.type] || {}
  return {...request, ...typeFilters}
}

export const parseRepeatingInvoicesResponse = response => {
  const type = findType(response.filters)
  let filters = {...response.filters, type}
  return {...response, filters}
}

export const parseAccountRequest = request => {
  if (!request) return request
  const {account_groups, ...account} = request
  let account_groups_attributes
  if (account_groups) {
    account_groups_attributes = account_groups.filter(account_group => !account_group.system_defined)
  }
  return {...account, account_groups_attributes}
}

export const parseTransactionRequest = props => {
  if (props.type === 'payment' && props.amount < 0) {
    props.amount = Math.abs(props.amount)
  } else if (props.type === 'refund' && props.amount > 0) {
    props.amount = -props.amount
  }
  return props
}
