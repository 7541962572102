import React, {Fragment} from 'react'
import invert from 'invert-color'
import {Switch, Link, Route, useHistory, useRouteMatch} from 'react-router-dom'
import {capitalize} from 'admin/lib/format'
import useDocument from 'admin/hooks/useDocument'
import useGroups from 'admin/hooks/useGroups'
import Group from 'admin/components/Group'
import NewGroup from 'admin/components/NewGroup'

export default () => {
  const history = useHistory()
  const match = useRouteMatch()
  const {organisationID, type} = match.params
  const {groups} = useGroups(organisationID, type)

  useDocument({title: `${capitalize(type)} Groups`})

  const handleClick = (e, groupId) => {
    if (e.target.tagName.toLowerCase() !== 'a') {
      e.preventDefault()
      history.push(`/orgs/${organisationID}/groups/${type}/${groupId}`)
    }
  }

  return (
    <Fragment>
      <Switch>
        <Route exact path={`${match.path}/:groupID(\\d+)`} component={Group} />
        <Route path={`${match.path}/new`} component={NewGroup} />
      </Switch>

      <section className='section'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title'>Manage {type} groups</h3>
              <h4 className='subtitle'>Showing all {type} groups</h4>
            </div>
            <div className='column buttons is-right'>
              {type === 'custom' ? (
                <Link to={`/orgs/${organisationID}/groups/${type}/new`} className='button is-light is-decorationless'>
                  New group
                </Link>
              ) : null}
            </div>
          </div>
          <table className={`table is-striped is-fullwidth ${type === 'custom' ? 'is-hoverable is-linked' : ''}`}>
            <thead>
              <tr>
                <th>Name</th>
                <th># of accounts</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group, i) => {
                let style = {}
                let color = group.colour || 'f5f5f5'
                style.backgroundColor = `#${color}`
                style.color = invert(`#${color}`, true)

                return (
                  <tr key={i} onClick={type === 'custom' ? e => handleClick(e, group.id) : null}>
                    <td>
                      <span className='tag' style={style}>
                        {group.name}
                      </span>
                    </td>
                    <td>
                      {Object.keys(group.numOfAccountsByState).map((state, i) => {
                        if (group.numOfAccountsByState[state] === 0) return null
                        return (
                          <span key={i}>
                            <Link to={`/orgs/${organisationID}/accounts/${state}?includeGroupIds=${group.id}`}>
                              {group.numOfAccountsByState[state]} {state}
                            </Link>
                            &nbsp;&nbsp;
                          </span>
                        )
                      })}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </section>
    </Fragment>
  )
}
