import React from 'react'
import useUser from 'customer/hooks/useUser'
import InputField from 'customer/components/ui/InputField'
import useFlash from 'admin/hooks/useFlash'
import useEffect from 'customer/hooks/useEffect'
import useDocument from 'customer/hooks/useDocument'

export default props => {
  const {user, updateUser, updateUserStatus, saveUser, error} = useUser()
  const [_flash, setFlash] = useFlash()
  useDocument({title: 'Your profile'})

  useEffect(
    () => {
      if (updateUserStatus.fetched) {
        if (!error.data) {
          setFlash('Successfully updated')
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [updateUserStatus],
    false
  )

  const handleSubmit = e => {
    e.preventDefault()
    updateUser()
  }

  const errors = error.data || {}

  return (
    <section className='section'>
      <form className='container' onSubmit={handleSubmit}>
        <h3 className='title'>Your Profile</h3>
        <div className='field is-spaced'>
          <div className='control'>
            <InputField
              type='email'
              name='email'
              label='Email'
              value={user.email || ''}
              onChange={saveUser}
              errors={errors.email}
            />
          </div>
        </div>
        <div className='field is-grouped is-spaced'>
          <div className='control is-expanded'>
            <InputField
              type='password'
              name='password'
              label='New Password'
              value={user.password || ''}
              autoComplete='off'
              onChange={saveUser}
              errors={errors.password}
            />
          </div>
          <div className='control is-expanded'>
            <InputField
              type='password'
              name='password_confirmation'
              label='New Password Confirmation'
              value={user.password_confirmation || ''}
              autoComplete='off'
              onChange={saveUser}
              errors={errors.password_confirmation}
            />
          </div>
        </div>
        <div className='field is-spaced'>
          <div className='control'>
            <InputField
              type='password'
              name='current_password'
              label='Current Password'
              value={user.current_password || ''}
              autoComplete='off'
              onChange={saveUser}
              errors={errors.current_password}
            />
          </div>
        </div>

        <div className='buttons'>
          <input type='submit' className='button is-primary' value='Save' />
        </div>
      </form>
    </section>
  )
}
