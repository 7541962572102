import {useQuery} from '@apollo/client'
import {GET_HISTORY} from 'admin/gql/History.gql'

export default (itemType, itemId) => {
  const {
    data: {history = []} = {},
    refetch,
    loading
  } = useQuery(GET_HISTORY, {variables: {itemType, itemId}, skip: !itemId})

  return {history, loading, refetch}
}
