import React, {Fragment} from 'react'
import {Route, Switch, Redirect, useRouteMatch} from 'react-router-dom'
import Flash from 'admin/components/Flash'
import Groups from 'admin/components/Groups'
import DeletedGroups from 'admin/components/GroupsDeleted'
import Tabs from 'admin/components/ui/Tabs'

export default () => {
  const match = useRouteMatch()
  const {organisationID} = match.params
  return (
    <Fragment>
      <Flash />

      <Tabs
        collection={[
          {label: 'Custom', to: `/orgs/${organisationID}/groups/custom`},
          {label: 'System', to: `/orgs/${organisationID}/groups/system`},
          {label: 'Deleted', to: `/orgs/${organisationID}/groups/deleted`}
        ]}
      />

      <Switch>
        <Route path={`${match.path}/deleted`} component={DeletedGroups} />
        <Route path={`${match.path}/:type`} component={Groups} />

        <Redirect exact path={match.path} to={`/orgs/${organisationID}/groups/custom`} />
      </Switch>
    </Fragment>
  )
}
