import React from 'react'
import {Link} from 'react-router-dom'

export default ({collection, active, onClick, className = '', boxed = true}) => {
  return (
    <div className={`tabs ${boxed ? 'is-boxed' : ''} ${className}`}>
      <ul className='container'>
        {collection.map(({label, to}, i) => (
          <li key={i} className={active === label ? 'is-active has-text-weight-semibold' : ''}>
            <Link to={to} className='is-decorationless'>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
