import React, {Fragment, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {isEmpty} from 'lodash'
import {invertColor, lightenColor} from 'customer/lib/color'
import useAccounts from 'customer/hooks/useAccounts'
import useUser from 'customer/hooks/useUser'
import useEffect from 'customer/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'
import useOrganisation from 'customer/hooks/useOrganisation'
import Flash from 'admin/components/Flash'
import auth from 'customer/lib/auth'

export default Component => {
  return props => {
    const {accountID} = props.match.params
    const {accounts, getAccountsStatus, getAccounts} = useAccounts()
    const {getUser, getUserStatus, logoutUser, logoutStatus, error} = useUser()
    const {organisation} = useOrganisation()
    const [active, setActive] = useState(false)
    const handleBurger = () => setActive(!active)
    const [_flash, setFlash] = useFlash()

    useEffect(() => {
      if (!getUserStatus.fetching && !getUserStatus.fetched) getUser()
      if (!getAccountsStatus.fetching && !getAccountsStatus.fetched) getAccounts()
    }, [])
    useEffect(() => setActive(false), [accountID])
    useEffect(
      () => {
        if (logoutStatus.fetched) {
          if (!error.data) {
            setFlash('Logged out successfully')
            auth.clearToken()
            props.history.replace('/login')
          } else {
            setFlash('There are error(s) on the form', {type: 'alert'})
          }
        }
      },
      [logoutStatus],
      false
    )

    let textStyle, backgroundStyle, lightBackgroundStyle
    if (organisation.color) {
      textStyle = {color: invertColor(organisation.color)}
      backgroundStyle = {background: organisation.color}
      lightBackgroundStyle = {background: lightenColor(organisation.color, 0.1)}
    }

    const handleAccountSwitch = (location, account) => {
      if (location.pathname.includes(`/accounts/${accountID}`)) {
        return location.pathname.replace(`/accounts/${accountID}`, `/accounts/${account.id}`)
      } else {
        return `/accounts/${account.id}`
      }
    }

    if (isEmpty(organisation)) return null

    const DropItem = ({style, children}) => (
      <span
        style={{...textStyle, ...style}}
        className={`navbar-link is-arrowless content ${
          props.location.pathname.includes('/accounts') ? 'is-active' : ''
        }`}>
        {children}
        <i className='fa fa-caret-down' aria-hidden='true'></i>
      </span>
    )

    return (
      <Fragment>
        <nav className='customer-navbar navbar' style={backgroundStyle} role='navigation' aria-label='main navigation'>
          <div className='container'>
            <div className='navbar-brand'>
              <span className='navbar-item' style={textStyle}>
                {organisation.logo ? <img src={organisation.logo} alt='logo' /> : organisation.name}
              </span>
              <a
                onClick={handleBurger}
                role='button'
                className={`navbar-burger burger ${active ? 'is-active' : ''}`}
                style={textStyle}
                aria-label='menu'
                aria-expanded='false'>
                <span aria-hidden='true'></span>
                <span aria-hidden='true'></span>
                <span aria-hidden='true'></span>
              </a>
            </div>
            <div className={`navbar-menu ${active ? 'is-active' : ''}`} style={backgroundStyle}>
              <div className='navbar-end'>
                {accounts.length > 1 ? (
                  <div className='navbar-item has-dropdown is-hoverable'>
                    {accountID ? (
                      <DropItem style={{margin: 0, display: 'block', lineHeight: '1em'}}>
                        <sup>Account</sup>
                        <br />
                        {accounts.find(account => account.id == accountID).display_name}
                        &nbsp;&nbsp;
                      </DropItem>
                    ) : (
                      <DropItem style={{margin: 0}}>Accounts &nbsp;&nbsp;</DropItem>
                    )}
                    <div className='navbar-dropdown is-right' style={lightBackgroundStyle}>
                      {accounts
                        .filter(account => account.id != accountID)
                        .map((account, i) => (
                          <NavLink
                            key={i}
                            to={location => handleAccountSwitch(location, account)}
                            activeClassName='is-active'
                            className='navbar-item'
                            style={textStyle}>
                            {account.display_name}
                          </NavLink>
                        ))}
                    </div>
                  </div>
                ) : accounts.length === 1 ? (
                  <NavLink
                    to={location => handleAccountSwitch(location, accounts[0])}
                    activeClassName='is-active'
                    className='navbar-item content'
                    style={{...textStyle, margin: 0, display: 'block', lineHeight: '1em'}}>
                    <sup>Account</sup>
                    <br />
                    {accounts[0].display_name}
                  </NavLink>
                ) : null}

                {getUserStatus.fetched && (
                  <Fragment>
                    <NavLink
                      to='/profile'
                      activeClassName='is-active'
                      alt='Your profile'
                      className='navbar-item'
                      style={textStyle}>
                      Profile
                    </NavLink>
                    <a onClick={logoutUser} alt='Log out' className='navbar-item' style={textStyle}>
                      Logout
                    </a>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </nav>
        <main className='customer-content'>
          <Flash floating={true} />
          <Component {...props} />
        </main>
      </Fragment>
    )
  }
}
