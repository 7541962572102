import React, {useRef, useState} from 'react'
import {gql, useLazyQuery} from 'admin/lib/apollo'
import {snakeCase} from 'admin/lib/format'
import useEffect from 'admin/hooks/useEffect'
import InputField from 'admin/components/ui/InputField'
import AccountList from './AccountList'

const QUERY = gql`
  query GetAccountsForAccountField($organisationId: ID!, $name: String, $limit: Int) {
    accounts(organisationId: $organisationId, name: $name, limit: $limit, state: "active") {
      collection {
        id
        name
        displayName @client
        contactFirstName
        contactLastName
        contactEmail
        physicalAddress
        postalAddress
        customerNumber
      }
    }
  }
`

export default ({
  organisationID: organisationId,
  value,
  label = 'Account',
  showLabel = true,
  className,
  onChange,
  onSelect,
  exclude = [],
  ...props
}) => {
  const ref = useRef()
  const [showList, setShowList] = useState(false)
  const [accountName, setAccountName] = useState(value)

  useEffect(() => setAccountName(value), [value], false)

  useEffect(() => {
    document.addEventListener('click', handleExit)
    return () => document.removeEventListener('click', handleExit)
  }, [])

  const [getAccounts, {data}] = useLazyQuery(QUERY)
  const response = data ? data.accounts : {}

  const handleExit = ({target: clickedElement}) => {
    do {
      if (clickedElement == ref.current) return
      clickedElement = clickedElement.parentNode
    } while (clickedElement)
    setShowList(false)
  }

  const wrapOnChange = account => {
    onChange && onChange(account)
  }

  const handleSearchChange = ({account_name}) => {
    setAccountName(account_name)
    if (account_name) {
      getAccounts({variables: {organisationId, name: account_name, limit: 5}})
      setShowList(true)
    } else {
      setShowList(false)
      wrapOnChange(null)
    }
  }

  const handleAccountSelection = account => {
    const snakeCaseAccount = Object.keys(account).reduce((result, key) => {
      result[snakeCase(key)] = account[key]
      return result
    }, {})
    if (onSelect) {
      onSelect(snakeCaseAccount)
      setShowList(false)
    } else {
      setShowList(false)
      wrapOnChange(snakeCaseAccount)
    }
  }

  const filteredData = response.collection ? response.collection.filter(({id}) => !exclude.includes(id)) : []

  return (
    <div style={{width: '100%'}} className={`dropdown ${filteredData.length && showList ? 'is-active' : ''}`}>
      <div style={{width: '100%'}} className='dropdown-trigger'>
        <InputField
          {...props}
          type='text'
          name='account_name'
          label={label}
          showLabel={showLabel}
          autoComplete='off'
          value={accountName || ''}
          aria-controls='accounts-list'
          aria-haspopup='true'
          onChange={handleSearchChange}
          className={props.readOnly ? `${className} is-static` : className}
        />
      </div>
      <div className='dropdown-menu' id='accounts-list' role='menu'>
        <AccountList
          ref={ref}
          accounts={filteredData}
          onClick={handleAccountSelection}
          organisationID={organisationId}
        />
      </div>
    </div>
  )
}
