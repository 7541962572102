import {gql} from 'admin/lib/apollo'

export const GET_GROUPS = gql`
  query GetGroups($organisationId: ID!, $type: String) {
    groups(organisationId: $organisationId, type: $type) {
      id
      name
      colour
      numOfAccountsByState
    }
  }
`
