import React, {Fragment} from 'react'
import useStateObject from 'admin/hooks/useStateObject'
import useAdmin from 'admin/hooks/useAdmin'
import useEffect from 'admin/hooks/useEffect'
import useBoolState from 'admin/hooks/useBoolState'
import InputField from 'admin/components/ui/InputField'
import Confirmation from 'admin/components/Confirmation'
import Modal from 'admin/components/ui/Modal'
import {isEmpty, omitBy} from 'lodash'
import {diff} from 'deep-diff'

export default ({email = {}, errors = {}, onSend, onSave, buttonText, onExit, isOpen, children, placeholders = {}}) => {
  const {admin} = useAdmin()
  const [form, setForm, resetForm] = useStateObject()
  const adminIsCopied = form.cc && form.cc.includes(admin.email)
  const [showPlaceholderValues, setShowPlaceholderValues, toggleShowPlaceholderValues] = useBoolState(false)
  const [showDiscardConfirmation, setShowDiscardConfirmation, toggleShowDiscardConfirmation] = useBoolState()

  email = omitBy(email, isEmpty)
  useEffect(() => resetForm(email), [email])

  const fillPlaceholders = (content = '') => {
    return Object.keys(placeholders).reduce((result, placeholder) => {
      return result.replace(`[${placeholder}]`, placeholders[placeholder])
    }, content)
  }

  const handleTogglePlaceholders = e => {
    e.preventDefault()
    toggleShowPlaceholderValues()
  }

  const handleCopy = e => {
    e.preventDefault()
    let value = [form.cc, admin.email].filter(t => t).join(', ')
    setForm({cc: value})
  }

  const handleUncopy = e => {
    e.preventDefault()
    let value = form.cc.replace(new RegExp(',? *' + admin.email), '')
    setForm({cc: value})
  }

  const handleExit = e => {
    e.preventDefault()
    onExit && onExit(form)
    resetForm(email)
    setShowDiscardConfirmation(false)
  }

  const handleSend = e => {
    e.preventDefault()
    onSend(form)
  }

  const handleSave = e => {
    e.preventDefault()
    onSave(form)
  }

  const handleDiscard = diff(form, email) ? toggleShowDiscardConfirmation : handleExit

  return (
    <Fragment>
      <Modal onClose={handleDiscard} isOpen={isOpen}>
        <section className='section has-background-light'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-8'>
                <h4 className='title is-4'>Edit email message</h4>
                {children}
              </div>
              <div className='column is-4 buttons is-right'>
                {onSend ? (
                  <Fragment>
                    <button className='button is-text is-decorationless' onClick={handleDiscard}>
                      Cancel
                    </button>
                    <button className='button is-primary' onClick={handleSend}>
                      {buttonText || 'Send'}
                    </button>
                  </Fragment>
                ) : onSave ? (
                  <Fragment>
                    <button className='button is-text is-decorationless' onClick={handleDiscard}>
                      Cancel
                    </button>
                    <button className='button is-primary' onClick={handleSave}>
                      {buttonText || 'Save'}
                    </button>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        <section className='section'>
          <form>
            <InputField
              type='text'
              disabled={true}
              name='to'
              errors={errors.to}
              value={form.to || ''}
              onChange={setForm}
            />
            <a href='' onClick={adminIsCopied ? handleUncopy : handleCopy} className='is-pulled-right is-size-7'>
              {adminIsCopied ? "Don't copy" : 'Copy'} me in
            </a>
            <InputField
              type='text'
              name='cc'
              errors={errors.cc}
              value={form.cc || ''}
              onChange={setForm}
              help='Separate multiple email addresses with a comma (,).'
            />
            <InputField type='text' name='bcc' errors={errors.bcc} value={form.bcc || ''} onChange={setForm} />
            {!isEmpty(placeholders) && (
              <a href='' onClick={handleTogglePlaceholders} className='is-pulled-right is-size-7'>
                Toggle placeholders
              </a>
            )}
            <InputField
              type='text'
              disabled={showPlaceholderValues}
              name='subject'
              errors={errors.subject}
              value={showPlaceholderValues ? fillPlaceholders(form.subject) : form.subject}
              onChange={showPlaceholderValues ? null : setForm}
            />
            <InputField
              type='textarea'
              disabled={showPlaceholderValues}
              name='message'
              rows={8}
              errors={errors.message}
              value={showPlaceholderValues ? fillPlaceholders(form.message) : form.message}
              onChange={showPlaceholderValues ? null : setForm}
              help='Placeholders are filled in when new invoices are created.'
            />
          </form>
        </section>
      </Modal>
      <Confirmation
        confirm='Discard your changes?'
        onConfirm={handleExit}
        onCancel={toggleShowDiscardConfirmation}
        isOpen={showDiscardConfirmation}
        confirmText='Discard'
        cancelText='Continue editing'
        buttonClass='is-danger'
        minWidth={null}
      />
    </Fragment>
  )
}
