import React, {Fragment} from 'react'
import {Route, Switch, Redirect, useRouteMatch} from 'react-router-dom'
import Accounts from 'admin/components/Accounts'
import Flash from 'admin/components/Flash'
import Tabs from 'admin/components/ui/Tabs'

export default () => {
  const match = useRouteMatch()
  const {organisationID} = match.params
  return (
    <Fragment>
      <Flash />

      <Tabs
        collection={[
          {label: 'Draft', to: `/orgs/${organisationID}/accounts/draft`},
          {label: 'Active', to: `/orgs/${organisationID}/accounts/active`},
          {label: 'Archived', to: `/orgs/${organisationID}/accounts/archived`}
        ]}
      />

      <Switch>
        <Route path={`${match.path}/:state`} component={Accounts} />
        <Redirect exact path={match.path} to={`/orgs/${organisationID}/accounts/active`} />
      </Switch>
    </Fragment>
  )
}
