import {toParams} from 'admin/lib/format'
import send from 'admin/lib/action'
import types from 'admin/types/Users.types'

export const getUsers = (organisationID, data) =>
  send({
    method: 'get',
    path: `/orgs/${organisationID}/users.json?${toParams(data)}`,
    baseType: types.GET_USERS,
    data
  })
