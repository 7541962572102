import {combineReducers} from 'redux'
import types from 'admin/types/Transactions.types'
import createReducer from 'admin/reducers/createReducer'
import createRequestReducer from 'admin/reducers/createRequestReducer'

const INITIAL_DATA_STATE = []
const data = createReducer(INITIAL_DATA_STATE, {
  [types.GET_PAST_TRANSACTIONS_FULFILLED]: (_, action) => action.data.transactions
})

const INITIAL_COUNTS_STATE = {count: 0, total_count: 0, results_count: 0}
const counts = createReducer(INITIAL_COUNTS_STATE, {
  [types.GET_PAST_TRANSACTIONS_FULFILLED]: (state, {data}) => ({...state, ...data.filters})
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.GET_PAST_TRANSACTIONS_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.GET_PAST_TRANSACTIONS_REJECTED]: (_, action) => action.error
})

const getPastTransactionsStatus = createRequestReducer(types.GET_PAST_TRANSACTIONS)
const sendPastTransactionsStatus = createRequestReducer(types.SEND_PAST_TRANSACTIONS)

export default combineReducers({data, error, getPastTransactionsStatus, sendPastTransactionsStatus, counts})
