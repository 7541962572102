import {useDispatch, useSelector} from 'react-redux'
import {getInvoices} from 'admin/actions/Invoices.actions'
import mutateWith from 'admin/hooks/mutateWith'
import useRestoreMutation from 'admin/hooks/useRestoreMutation'
import {parseError} from 'admin/lib/apollo'

const mapState = state => ({
  invoices: state.invoices.data,
  error: state.invoices.error,
  counts: state.invoices.counts,
  getInvoicesStatus: state.invoices.getStatus
})

export default organisationId => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)

  const [restore, {error: restoreError}] = useRestoreMutation({
    organisationId,
    recordType: 'Invoice'
  })

  return {
    ...store,
    getInvoices: filters => getInvoices(organisationId, filters)(dispatch),
    errors: parseError(restoreError),
    restore: mutateWith(restore, 'Invoices restored')
  }
}
