import {gql} from '@apollo/client'

export const GET_HISTORY = gql`
  query GetHistory($itemType: String!, $itemId: ID!) {
    history(itemType: $itemType, itemId: $itemId) {
      id
      timestamp
      user
      event
      details
    }
  }
`
