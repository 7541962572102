import {useSelector, useDispatch} from 'react-redux'
import {
  loginUser,
  logoutUser,
  getUser,
  updateUser,
  confirmEmail,
  forgotPassword,
  changePassword,
  setPassword
} from 'customer/actions/User.actions'
import types from 'customer/types/User.types'

const mapState = state => ({
  user: state.user.data,
  error: state.user.error,
  loginStatus: state.user.loginStatus,
  logoutStatus: state.user.logoutStatus,
  getUserStatus: state.user.getUserStatus,
  updateUserStatus: state.user.updateUserStatus,
  confirmEmailStatus: state.user.confirmEmailStatus,
  forgotPasswordStatus: state.user.forgotPasswordStatus,
  changePasswordStatus: state.user.changePasswordStatus,
  setPasswordStatus: state.user.setPasswordStatus
})

export default () => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)
  return {
    ...store,
    saveUser: (data = {}) => dispatch({type: types.SAVE_USER, data: {...store.user, ...data}}),
    getUser: () => getUser()(dispatch),
    loginUser: data => loginUser({customer: data})(dispatch),
    logoutUser: () => logoutUser()(dispatch),
    updateUser: () => updateUser({customer: store.user})(dispatch),
    confirmEmail: token => confirmEmail({confirmation_token: token})(dispatch),
    forgotPassword: data => forgotPassword({customer: data})(dispatch),
    changePassword: data => changePassword({customer: data})(dispatch),
    setPassword: data => setPassword({customer: data})(dispatch),
    clearForgotPassword: () => dispatch({type: types.CLEAR_FORGOT_PASSWORD})
  }
}
