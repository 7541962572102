import {useQuery, useMutation} from 'admin/lib/apollo'
import {useQueryParams, StringParam, NumberParam, ArrayParam, withDefault} from 'use-query-params'
import {GET_ACCOUNTS, BULK_ACCOUNTS} from 'admin/gql/Accounts.gql'
import useEffect from 'admin/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'

export default (organisationId, {state} = {state: 'active'}) => {
  const [flash, setFlash] = useFlash()
  const [filters, setFilters] = useQueryParams({
    q: withDefault(StringParam, ''),
    includeGroupIds: withDefault(ArrayParam, []),
    excludeGroupIds: withDefault(ArrayParam, []),
    bounds: StringParam,
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sortBy: withDefault(StringParam, 'name'),
    sortDirection: withDefault(StringParam, 'asc')
  })

  const variables = {organisationId, state, ...filters}

  const {data: {accounts = {}} = {}, fetchMore} = useQuery(GET_ACCOUNTS, {variables})

  const [bulkAction] = useMutation(BULK_ACCOUNTS, {
    variables: {organisationId},
    errorPolicy: 'all',
    refetchQueries: [{query: GET_ACCOUNTS, variables}]
  })

  useEffect(() => fetchMore({variables: filters}), [filters], false)

  return {
    filters,
    setFilters,
    accounts: accounts.collection || [],
    totalCount: accounts.metadata ? accounts.metadata.totalCount : 0,
    destroy: variables =>
      bulkAction({variables: {...variables, task: 'destroy'}}).then(({errors}) => {
        if (!errors) setFlash('Accounts deleted')
      }),
    activate: variables =>
      bulkAction({variables: {...variables, task: 'activate'}}).then(({errors}) => {
        if (!errors) setFlash('Accounts activated')
      }),
    archive: variables =>
      bulkAction({variables: {...variables, task: 'archive'}}).then(({errors}) => {
        if (!errors) setFlash('Accounts archived')
      }),
    merge: variables =>
      bulkAction({variables: {...variables, task: 'merge'}}).then(({errors}) => {
        if (!errors) setFlash('Accounts merged')
      }),
    update: variables =>
      bulkAction({variables: {...variables, task: 'update'}}).then(({errors}) => {
        if (!errors) setFlash('Accounts updated')
      })
  }
}
