import {parseError, useQuery, useMutation, gql} from 'admin/lib/apollo'
import {pluralise} from 'admin/lib/format'
import mutateWith from 'admin/hooks/mutateWith'
import useEffect from 'admin/hooks/useEffect'
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params'
import {isObject, camelCase} from 'lodash'

const QUERY = gql`
  query GetDestroyedRecords($organisationId: ID!, $recordType: String!) {
    destroyed(organisationId: $organisationId, recordType: $recordType) {
      collection {
        ... on Group {
          id
          name
          colour
        }
        ... on Invoice {
          id
          account {
            id
            name
            contactFirstName
            contactLastName
            displayName @client
          }
          date
          invoiceNumber
          reference
          amount
          deletedAt
        }
        ... on Transaction {
          id
          date
          amount
          description
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

const MUTATION = gql`
  mutation RestoreRecords($organisationId: ID!, $recordType: String!, $recordIds: [String!]!) {
    restore(organisationId: $organisationId, recordType: $recordType, recordIds: $recordIds) {
      ... on Group {
        id
        name
        colour
      }
      ... on Invoice {
        id
        account {
          id
          name
          contactFirstName
          contactLastName
          displayName @client
        }
        date
        invoiceNumber
        reference
        amount
        deletedAt
      }
      ... on Transaction {
        id
        date
        amount
        description
      }
      ... on RepeatingInvoice {
        id
        deletedAt
        account {
          id
        }
      }
    }
  }
`

export default (organisationId, recordType, runQuery = true) => {
  const [filters, setFilters] = useQueryParams({
    q: withDefault(StringParam, ''),
    fromDate: StringParam,
    toDate: StringParam,
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sortBy: withDefault(StringParam, 'date'),
    sortDirection: withDefault(StringParam, 'desc')
  })

  const {data: {destroyed = []} = {}, fetchMore} = useQuery(QUERY, {
    variables: {organisationId, recordType},
    skip: !runQuery
  })

  useEffect(() => fetchMore({variables: filters}), [filters], false)

  const [restore, {error: restoreError}] = useMutation(MUTATION, {
    variables: {organisationId, recordType},
    errorPolicy: 'all',
    update: (cache, {data: {restore = []}, errors}) => {
      if (!errors) {
        cache.modify({
          fields: {
            [camelCase(pluralise(recordType))]: (_, {DELETE}) => DELETE,
            destroyed: (cached = [], {readField}) => {
              let restoredIds = restore.map(({id}) => id)

              if (isObject(cached) && cached.__typename.match(/Collection$/)) {
                let collection = cached.collection.filter(
                  recordRef => !restoredIds.includes(readField('id', recordRef))
                )
                let diffCount = cached.collection.length - collection.length
                return {
                  ...cached,
                  collection,
                  metadata: {
                    ...cached.metadata,
                    totalCount: cached.metadata.totalCount - diffCount
                  }
                }
              } else {
                return cached.filter(recordRef => !restoredIds.includes(readField('id', recordRef)))
              }
            }
          }
        })
      }
    }
  })

  return {
    filters,
    setFilters,
    destroyed: destroyed.collection || [],
    totalCount: destroyed.metadata ? destroyed.metadata.totalCount : 0,
    errors: parseError(restoreError),
    restore: mutateWith(restore, 'Successfully restored')
  }
}
