import React, {Fragment} from 'react'
import useStateObject from 'admin/hooks/useStateObject'
import useAdmin from 'admin/hooks/useAdmin'
import useEffect from 'admin/hooks/useEffect'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'

export default ({email, errors = {}, onSend, onDone, isOpen, children}) => {
  const {admin} = useAdmin()
  const [form, setForm, resetForm] = useStateObject()
  const adminIsCopied = form.cc && form.cc.includes(admin.email)

  useEffect(() => resetForm(email), [email])

  const handleCopy = e => {
    e.preventDefault()
    let value = [form.cc, admin.email].filter(t => t).join(', ')
    setForm({cc: value})
  }

  const handleUncopy = e => {
    e.preventDefault()
    let value = form.cc.replace(new RegExp(',? *' + admin.email), '')
    setForm({cc: value})
  }

  const handleDone = e => {
    e.preventDefault()
    onDone && onDone(form)
    resetForm(email)
  }

  const handleSend = e => {
    e.preventDefault()
    onSend(form)
  }

  return (
    <Modal onClose={handleDone} isOpen={isOpen}>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-8'>
              <h4 className='title is-4'>Edit email message</h4>
              {children}
            </div>
            <div className='column is-4 buttons is-right'>
              {onSend ? (
                <Fragment>
                  <button className='button is-text is-decorationless' onClick={handleDone}>
                    Cancel
                  </button>
                  <button className='button is-primary' onClick={handleSend}>
                    Send
                  </button>
                </Fragment>
              ) : (
                <button className='button' onClick={handleDone}>
                  Done
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <form>
          <div className='field'>
            <div className='control'>
              <InputField
                type='text'
                name='to'
                errors={errors.to}
                value={form.to || ''}
                onChange={setForm}
                help='Separate multiple email addresses with a comma (,).'
              />
            </div>
          </div>
          <div className='field'>
            <div className='control'>
              <a href='' onClick={adminIsCopied ? handleUncopy : handleCopy} className='is-pulled-right is-size-7'>
                {adminIsCopied ? "Don't copy" : 'Copy'} me in
              </a>
              <InputField type='text' name='cc' errors={errors.cc} value={form.cc || ''} onChange={setForm} />
            </div>
          </div>
          <div className='field'>
            <div className='control'>
              <InputField type='text' name='bcc' errors={errors.bcc} value={form.bcc || ''} onChange={setForm} />
            </div>
          </div>
          <div className='field is-spaced'>
            <div className='control'>
              <InputField
                type='text'
                name='subject'
                errors={errors.subject}
                value={form.subject || ''}
                onChange={setForm}
              />
            </div>
          </div>
          <div className='field'>
            <div className='control'>
              <InputField
                type='textarea'
                name='message'
                rows={10}
                errors={errors.message}
                value={form.message || ''}
                onChange={setForm}
              />
            </div>
          </div>
        </form>
      </section>
    </Modal>
  )
}
