import React from 'react'
import useUser from 'customer/hooks/useUser'
import useEffect from 'customer/hooks/useEffect'
import useStateObject from 'admin/hooks/useStateObject'
import useFlash from 'admin/hooks/useFlash'
import useDocument from 'customer/hooks/useDocument'
import InputField from 'customer/components/ui/InputField'

export default ({match, history}) => {
  const {token} = match.params
  const {changePassword, error, changePasswordStatus} = useUser()
  const [passwords, setPasswords] = useStateObject({password: '', password_confirmation: ''})
  const [_flash, setFlash] = useFlash()
  useDocument({title: 'Change your password'})

  useEffect(
    () => {
      if (changePasswordStatus.fetched) {
        if (!error.data) {
          setFlash('Password has been reset')
          history.replace('/login')
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [changePasswordStatus],
    false
  )

  const handleSubmit = e => {
    e.preventDefault()
    changePassword({reset_password_token: token, ...passwords})
  }

  const errors = error.data || {}

  return (
    <form action='' onSubmit={handleSubmit} className='box' style={{padding: '3em'}}>
      <div className='block'>
        <h1 className='title has-text-dark'>Change your password</h1>
      </div>
      <div className='block'>
        <div className='field'>
          <InputField
            type='password'
            name='password'
            label='Password'
            onChange={setPasswords}
            value={passwords.password}
            errors={errors.password}
          />
        </div>
        <div className='field'>
          <InputField
            type='password'
            name='password_confirmation'
            label='Password confirmation'
            onChange={setPasswords}
            value={passwords.password_confirmation}
            errors={errors.password_confirmation}
          />
        </div>
      </div>
      <div className='block field'>
        <button className='button is-success'>Change my password</button>
      </div>
    </form>
  )
}
