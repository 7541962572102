import {useQuery} from 'admin/lib/apollo'
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params'
import {GET_TEXT_MESSAGES} from 'admin/gql/TextMessages.gql'
import useEffect from 'admin/hooks/useEffect'

export default organisationId => {
  const [filters, setFilters] = useQueryParams({
    q: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50)
  })

  const variables = {organisationId, ...filters}
  const {data: {textMessages = {}} = {}, fetchMore} = useQuery(GET_TEXT_MESSAGES, {variables})

  useEffect(() => fetchMore({variables: filters}), [filters], false)

  return {
    filters,
    setFilters,
    textMessages: textMessages.collection || [],
    totalCount: textMessages.metadata ? textMessages.metadata.totalCount : 0
  }
}
