import React, {Fragment, useState} from 'react'
import diff from 'deep-diff'
import {useParams, useHistory} from 'react-router-dom'
import moment from 'admin/lib/moment'
import {toParams, formatNumber} from 'admin/lib/format'
import {hello} from 'admin/lib/greetings'
import {totalForInvoice} from 'admin/lib/tax'
import {dateIsInThePast, dateIsInTheFuture} from 'admin/lib/moment'
import useRepeatingInvoice from 'admin/hooks/useRepeatingInvoice'
import useOrganisation from 'admin/hooks/useOrganisation'
import useEffect from 'admin/hooks/useEffect'
import useDocument from 'admin/hooks/useDocument'
import useBoolState from 'admin/hooks/useBoolState'
import useStateObject from 'admin/hooks/useStateObject'
import Confirmation from 'admin/components/Confirmation'
import Flash from 'admin/components/Flash'
import History from 'admin/components/History'
import Breadcrumbs from 'admin/components/ui/Breadcrumbs'
import InputField from 'admin/components/ui/InputField'
import Form from './Form'
import Email from 'admin/components/shared/Email'

const EMAIL_DEFAULTS = {
  subject: `[Invoice Month] Invoice [Invoice Number] from [Organisation Name] for [Contact Name]`,
  message: `${hello()} [Contact First Name],

Here's your [Invoice Month] invoice [Invoice Number] for $NZD [Amount], due on [Due Date].

If you have any questions, please let us know.

Ngā mihi,
[Organisation Name]`
}

export default () => {
  const history = useHistory()
  const {organisationID, repeatingInvoiceID} = useParams()
  const {organisation} = useOrganisation(organisationID)
  const {repeatingInvoice = {}, errors, update, destroy} = useRepeatingInvoice(organisationID, repeatingInvoiceID)

  const [form, setForm, resetForm] = useStateObject(repeatingInvoice, {emailWithPlaceholders: EMAIL_DEFAULTS})
  const [deleteReason, setDeleteReason] = useState(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation, toggleShowDeleteConfirmation] = useBoolState()
  const [showApproveConfirmation, setShowApproveConfirmation, toggleShowApproveConfirmation] = useBoolState()
  const [showUpdateConfirmation, setShowUpdateConfirmation, toggleShowUpdateConfirmation] = useBoolState()
  const [showEmailModal, setShowEmailModal, toggleShowEmailModal] = useBoolState()

  useDocument({title: 'Edit repeating invoice'})
  useEffect(() => resetForm(repeatingInvoice), [repeatingInvoice], false)
  useEffect(() => errors.email && toggleShowEmailModal(), [errors.email], false)

  const handleApprove = e => {
    e.preventDefault()
    e.target.blur()
    update({state: 'approved'})
  }

  const handleUnapprove = e => {
    e.preventDefault()
    e.target.blur()
    update({state: 'draft'})
  }

  const handleDelete = () => {
    destroy({notes: deleteReason}, () => history.replace(`/orgs/${organisationID}/repeating_invoices`))
  }

  const handleUpdate = e => {
    e.preventDefault()
    e.target.blur()
    update(form)
  }

  const handleChange = changes => {
    if (changes.isSendable && !form.isSendable) toggleShowEmailModal()
    setForm(changes)
  }

  const handleChangeEmail = emailWithPlaceholders => {
    setForm({emailWithPlaceholders})
    toggleShowEmailModal()
  }

  const handleCancel = e => {
    e.preventDefault()
    resetForm(repeatingInvoice)
  }

  const breadcrumbs = []
  if (repeatingInvoice.account?.id) {
    breadcrumbs.push({
      label: 'Manage accounts',
      path: `/orgs/${organisationID}/accounts`
    })
    breadcrumbs.push({
      label: repeatingInvoice.account.displayName,
      path: `/orgs/${organisationID}/accounts/${repeatingInvoice.account.id}`
    })
  } else {
    breadcrumbs.push({
      label: 'Repeating Invoices',
      path: `/orgs/${organisationID}/repeating_invoices`
    })
  }

  const confirmDateMessage = dateIsInThePast(form.nextInvoiceDate)
    ? "A 'Next invoice date' in the past can create multiple past invoices"
    : "A 'Next invoice date' for today will immediately create an invoice. No email will be sent"

  const ignoredChanges = (path, key) =>
    path.length === 0 &&
    (key === 'forceDate' ||
      (key === 'emailWithPlaceholders' &&
        (!repeatingInvoice.emailWithPlaceholders?.subject || !repeatingInvoice.emailWithPlaceholders?.message)))
  const hasChanges = diff(form, repeatingInvoice, ignoredChanges)

  return (
    <Fragment>
      <Flash />

      <Confirmation
        confirm={confirmDateMessage}
        buttonText='Approve'
        onConfirm={handleApprove}
        onClose={toggleShowApproveConfirmation}
        isOpen={showApproveConfirmation}
      />

      <Confirmation
        confirm={confirmDateMessage}
        buttonText='Update'
        onConfirm={handleUpdate}
        onClose={toggleShowUpdateConfirmation}
        isOpen={showUpdateConfirmation}
      />

      <Confirmation
        confirm='Are you sure you want to delete?'
        buttonClass='is-danger'
        buttonText='Delete'
        onConfirm={handleDelete}
        onCancel={() => setDeleteReason(null)}
        onClose={toggleShowDeleteConfirmation}
        isOpen={showDeleteConfirmation}>
        <InputField
          value={deleteReason || ''}
          type='textarea'
          name='notes'
          label='Reason for deleting? (optional)'
          onChange={({notes}) => setDeleteReason(notes)}
          rows={2}
        />
      </Confirmation>

      <Email
        email={form.emailWithPlaceholders}
        errors={errors.emailWithPlaceholders ? errors.emailWithPlaceholders[0] : {}}
        onSave={handleChangeEmail}
        onExit={toggleShowEmailModal}
        isOpen={showEmailModal}
        placeholders={{
          ['Amount']: formatNumber(totalForInvoice(form), {thousands: true}),
          ['Contact First Name']: form.account?.contactFirstName,
          ['Contact Name']: form.account?.displayName,
          ['Due Date']: moment(form.nextInvoiceDueDate).format('D MMM YYYY'),
          ['Invoice Month']: moment(form.nextInvoiceDate).format('MMMM'),
          ['Invoice Total With Currency']: formatNumber(totalForInvoice(form), {thousands: true, currency: true}),
          ['Organisation Name']: organisation.name
        }}>
        <h5 className='subtitle is-6'>
          <i className='fa fa-paperclip' aria-hidden='true'></i>
          &nbsp;&nbsp;
          <a
            href={`/orgs/${organisationID}/downloads/repeating_invoice.pdf?${toParams({
              repeating_invoice_id: repeatingInvoiceID,
              inline: true
            })}`}
            target='_blank'>
            PDF attached
          </a>
        </h5>
      </Email>

      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <Breadcrumbs breadcrumbs={breadcrumbs}>
                <h3 className='title is-marginless'>Edit repeating invoice</h3>
              </Breadcrumbs>
            </div>
            <div className='column buttons is-right'>
              {hasChanges ? (
                <Fragment>
                  <button className='button is-text is-decorationless' onClick={handleCancel}>
                    Cancel
                  </button>
                  <button
                    className='button is-primary'
                    onClick={
                      !dateIsInTheFuture(form.nextInvoiceDate) && form.state === 'approved'
                        ? toggleShowUpdateConfirmation
                        : handleUpdate
                    }>
                    Save
                  </button>
                </Fragment>
              ) : form.id ? (
                <Fragment>
                  <a
                    href={`/orgs/${organisationID}/downloads/repeating_invoice.pdf?${toParams({
                      repeating_invoice_id: repeatingInvoiceID,
                      inline: true
                    })}`}
                    target='_blank'
                    className='button is-outlined is-dark'>
                    View PDF
                  </a>
                  <button className='button is-danger is-outlined' onClick={toggleShowDeleteConfirmation}>
                    Delete
                  </button>
                  {form.state === 'draft' ? (
                    <button
                      className='button is-primary'
                      onClick={dateIsInTheFuture(form.nextInvoiceDate) ? handleApprove : toggleShowApproveConfirmation}>
                      Approve
                    </button>
                  ) : (
                    <button className='button is-outlined is-info' onClick={handleUnapprove}>
                      Revert to Draft
                    </button>
                  )}
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <Form
            errors={errors}
            organisationID={organisationID}
            repeatingInvoice={form}
            onChange={handleChange}
            onEditEmailTemplate={toggleShowEmailModal}
          />
        </div>
      </section>
      <History
        itemType='RepeatingInvoice'
        itemId={repeatingInvoiceID}
        onAddNote={notes => update({notes})}
        className='has-background-light'
      />
    </Fragment>
  )
}
