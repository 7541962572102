import {combineReducers} from 'redux'
import types from 'customer/types/Account.types'
import createReducer from 'customer/reducers/createReducer'
import createRequestReducer from 'customer/reducers/createRequestReducer'

const INITIAL_DATA_STATE = {}
const data = createReducer(INITIAL_DATA_STATE, {
  [types.SAVE_ACCOUNT]: (_, action) => action.data,
  [types.GET_ACCOUNT]: () => INITIAL_DATA_STATE,
  [types.FULFILLED_GET_ACCOUNT]: (_, action) => action.data,
  [types.FULFILLED_UPDATE_ACCOUNT]: (_, action) => action.data
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.FULFILLED_GET_ACCOUNT]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_UPDATE_ACCOUNT]: () => INITIAL_ERROR_STATE,

  [types.REJECTED_GET_ACCOUNT]: (_, action) => action.error,
  [types.REJECTED_UPDATE_ACCOUNT]: (_, action) => action.error
})

const getStatus = createRequestReducer(types.GET_ACCOUNT)
const updateStatus = createRequestReducer(types.UPDATE_ACCOUNT)

export default combineReducers({data, error, getStatus, updateStatus})
