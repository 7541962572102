import {useSelector, useDispatch} from 'react-redux'
import types from 'customer/types/Transactions.types'
import {getFutureTransactions} from 'customer/actions/Transactions.actions'

const mapState = state => ({
  transactions: state.futureTransactions.data,
  error: state.futureTransactions.error,
  getTransactionsStatus: state.futureTransactions.getStatus
})

export default accountID => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)
  return {
    ...store,
    getTransactions: () => getFutureTransactions(accountID)(dispatch)
  }
}
