import React from 'react'
import InputField from 'customer/components/ui/InputField'
import DateField from 'customer/components/ui/DateField'

export default ({q, fromDate, toDate, onSubmit}) => {
  const handleSearch = changes => {
    onSubmit({...changes, page: 1})
  }

  return (
    <form className='box is-radiusless is-shadowless has-background-filters'>
      <div className='field is-grouped'>
        <div className='control is-expanded'>
          <DateField name='from_date' value={fromDate} onChange={handleSearch} />
        </div>
        <div className='control is-expanded'>
          <DateField name='to_date' value={toDate} onChange={handleSearch} />
        </div>
      </div>
      <div className='field is-grouped'>
        <div className='control is-expanded'>
          <InputField
            type='search'
            name='q'
            label='Invoice number'
            autoComplete='off'
            value={q || ''}
            onChange={handleSearch}
          />
        </div>
      </div>
    </form>
  )
}
