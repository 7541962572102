import React from 'react'

export default ({children, onClose, minWidth = '60%', minHeight, isOpen = true}) =>
  isOpen ? (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div className='modal-background' onClick={onClose}></div>
      <div className='modal-content box is-paddingless' style={{minWidth, minHeight}}>
        {children}
      </div>
    </div>
  ) : null
