import Confirm from 'admin/components/Confirm'
import Flash from 'admin/components/Flash'
import FourZeroFour from 'admin/components/FourZeroFour'
import AccountField from 'admin/components/ui/AccountField'
import Breadcrumbs from 'admin/components/ui/Breadcrumbs'
import InputField from 'admin/components/ui/InputField'
import Tags from 'admin/components/ui/Tags'
import useDocument from 'admin/hooks/useDocument'
import useEffect from 'admin/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'
import useStateObject from 'admin/hooks/useStateObject'
import useUser from 'admin/hooks/useUser'
import {formatTime} from 'admin/lib/format'
import React, {Fragment} from 'react'

export default ({match, history}) => {
  const {organisationID, userID} = match.params

  useDocument({title: 'Edit user'})
  const {
    error,
    user,
    updateUser,
    getUserStatus,
    updateUserStatus,
    sendUserInvitation,
    sendUserInvitationStatus,
    revokeUserAccess,
    revokeUserAccessStatus,
    restoreUserAccess,
    restoreUserAccessStatus,
    destroyUser,
    destroyUserStatus,
    sendUserVerification,
    sendUserVerificationStatus
  } = useUser(organisationID, userID)
  const [_flash, setFlash] = useFlash()
  const [confirm, setConfirm, clearConfirm] = useStateObject(null)
  const [form, setForm] = useStateObject({accounts: [], first_name: ''})

  useEffect(() => {
    if (user.accounts) setForm({accounts: user.accounts, first_name: user.name})
  }, [getUserStatus])

  useEffect(
    () => {
      if (sendUserInvitationStatus.fetched) {
        if (!error.data) {
          setFlash({error, success: 'Invitation sent'})
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [sendUserInvitationStatus],
    false
  )

  useEffect(
    () => {
      if (updateUserStatus.fetched) {
        if (!error.data) {
          setFlash({error, success: 'User updated'})
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [updateUserStatus],
    false
  )

  useEffect(
    () => {
      if (sendUserVerificationStatus.fetched) {
        if (!error.data) {
          setFlash({error, success: 'Confirmation instructions sent'})
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [sendUserVerificationStatus],
    false
  )

  useEffect(
    () => {
      if (restoreUserAccessStatus.fetched) {
        if (!error.data) {
          setFlash({error, success: 'Access restored'})
        }
      }
    },
    [restoreUserAccessStatus],
    false
  )

  useEffect(
    () => {
      if (revokeUserAccessStatus.fetched) {
        if (!error.data) {
          setFlash({error, success: 'Access revoked'})
        }
      }
    },
    [revokeUserAccessStatus],
    false
  )

  useEffect(
    () => {
      if (destroyUserStatus.fetched) {
        if (!error.data) {
          setFlash({error, success: 'User deleted'})
          history.push(`/orgs/${organisationID}/users`)
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [destroyUserStatus],
    false
  )

  if (error.status === 404) return <FourZeroFour />

  const handleSendInvitation = e => {
    setConfirm({
      message: 'Email this user with a link to setup a password?',
      confirmText: 'Send email',
      onConfirm: () => {
        sendUserInvitation()
        clearConfirm()
      },
      onCancel: clearConfirm
    })
    e.preventDefault()
  }

  const handleSendVerification = e => {
    setConfirm({
      message: 'Email this user with a link to confirm their email address?',
      confirmText: 'Send email',
      onConfirm: () => {
        sendUserVerification()
        clearConfirm()
      },
      onCancel: clearConfirm
    })
    e.preventDefault()
  }

  const handleRestoreAccess = e => {
    restoreUserAccess()
    e.preventDefault()
  }

  const handleRevokeAccess = e => {
    revokeUserAccess()
    e.preventDefault()
  }

  const handleDestroy = e => {
    setConfirm({
      message: 'Are you sure you want to delete?',
      confirmText: 'Delete',
      confirmClass: 'is-danger',
      onConfirm: () => {
        destroyUser()
        clearConfirm()
      },
      onCancel: clearConfirm
    })
    e.preventDefault()
  }

  const handleRemoveAccount = account => {
    setForm({accounts: form.accounts.filter(a => String(a.id) !== String(account.id))})
  }

  const handleAddAccount = account => {
    setForm({accounts: form.accounts.concat(account)})
  }

  const handleSubmit = () => {
    updateUser(form)
  }

  const breadcrumbs = [{label: 'Manage users', path: `/orgs/${organisationID}/users`}]

  return (
    <Fragment>
      <Flash />
      {confirm && <Confirm {...confirm} />}
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='is-5 column'>
              <Breadcrumbs breadcrumbs={breadcrumbs}>
                <h1 className='title is-marginless'>Edit {user.name || user.email}</h1>
              </Breadcrumbs>
            </div>
            <div className='is-7 column buttons is-right'>
              {!user.invitation_accepted_at && (
                <button
                  onClick={handleSendInvitation}
                  className='button is-outlined is-dark'
                  disabled={sendUserInvitationStatus.fetching}>
                  {user.invitation_sent_at ? 'Re-send' : 'Send'} invitation
                </button>
              )}
              {user.access_revoked_at ? (
                <button
                  onClick={handleRestoreAccess}
                  className='button is-info'
                  disabled={restoreUserAccessStatus.fetching}>
                  Restore login
                </button>
              ) : (
                <button
                  onClick={handleRevokeAccess}
                  className='button is-outlined is-info'
                  disabled={revokeUserAccessStatus.fetching}>
                  Revoke login
                </button>
              )}
              <button
                onClick={handleDestroy}
                className='button is-danger is-outlined'
                disabled={destroyUserStatus.fetching}>
                Delete
              </button>
              <button className='button is-primary' onClick={handleSubmit} disabled={updateUserStatus.fetching}>
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <div className='columns'>
            <div className='column'>
              <form>
                <div className='block'>
                  <InputField type='text' name='first_name' label='Name' value={form.first_name} onChange={setForm} />
                </div>
                <div className='block'>
                  <InputField type='text' name='email' value={user.email || ''} disabled />
                </div>
                <div className='block'>
                  <label htmlFor='account_name' className='label is-small'>
                    Accounts
                  </label>
                  <Tags selected={form.accounts} onDeselect={handleRemoveAccount} />
                  <AccountField
                    organisationID={organisationID}
                    onSelect={handleAddAccount}
                    showLabel={false}
                    exclude={form.accounts.reduce((exclusions, account) => {
                      exclusions.push(account.id)
                      return exclusions
                    }, [])}
                  />
                </div>
              </form>
            </div>
            <div className='column'>
              <dl className='inline-flex'>
                <dt>Created at</dt>
                <dd>{formatTime(user.created_at)}</dd>
                <dt>Last login</dt>
                <dd>{formatTime(user.last_sign_in_at)}</dd>
                <dt>Login revoked at</dt>
                <dd>{formatTime(user.access_revoked_at)}</dd>
                <dt>Invited by</dt>
                <dd>{user.invited_by}</dd>
                <dt>Invitation sent at</dt>
                <dd>{formatTime(user.invitation_sent_at)}</dd>
                <dt>Invitation accepted at</dt>
                <dd>{formatTime(user.invitation_accepted_at)}</dd>
                <dt>Reset password sent at</dt>
                <dd>{formatTime(user.reset_password_sent_at)}</dd>
              </dl>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
