import React from 'react'
import pages from './pages'

export default ({page: currentPage, limit = 50, total = 0, onChange, className}) => {
  const totalPages = Math.ceil(total / limit)

  if (totalPages <= 1) {
    return null
  }

  const onClick = page => onChange({limit, page})

  return (
    <div className={`pagination ${className || ''} is-small`} role='navigation' aria-label='pagination'>
      <ul className='pagination-list'>
        <li>
          <a onClick={() => onClick(1)} className='pagination-previous' disabled={currentPage === 1}>
            First
          </a>
        </li>
        <li>
          <a onClick={() => onClick(currentPage - 1)} className='pagination-previous' disabled={currentPage === 1}>
            Previous
          </a>
        </li>
        {pages(currentPage, totalPages).map((num, i) => (
          <li key={i}>
            <a
              onClick={() => onClick(num)}
              className={`pagination-link ${num === currentPage ? 'is-current' : ''}`}
              aria-label={`${num === currentPage ? 'Page' : 'Goto page'} ${num}`}>
              {num}
            </a>
          </li>
        ))}
        <li>
          <a onClick={() => onClick(currentPage + 1)} className='pagination-next' disabled={currentPage === totalPages}>
            Next
          </a>
        </li>
        <li>
          <a onClick={() => onClick(totalPages)} className='pagination-next' disabled={currentPage === totalPages}>
            Last
          </a>
        </li>
      </ul>
    </div>
  )
}
