import React from 'react'
import {capitalize} from 'admin/lib/format'

export default (Component, {onSort, sortBy, sortDirection, camelcase = false}) => ({name, column, className}) => {
  const handleSort = e => {
    e.preventDefault()
    let newDirection = sortDirection && sortDirection == 'desc' ? 'asc' : 'desc'
    if (camelcase) {
      onSort({['sortBy']: column || name, ['sortDirection']: newDirection})
    } else {
      onSort({['sort_by']: column || name, ['sort_direction']: newDirection})
    }
  }

  return (
    <Component className={className}>
      <a href='' className='sort-header' onClick={handleSort}>
        <span>{capitalize(name)}</span>
        {sortBy == (column || name) ? (
          <i className={`fa ${sortDirection == 'desc' ? 'fa-caret-down' : 'fa-caret-up'}`}></i>
        ) : null}
      </a>
    </Component>
  )
}
