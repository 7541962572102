import React, {Fragment, useState} from 'react'
import invert from 'invert-color'
import {colord} from 'colord'
import Dropdown from 'admin/components/ui/Dropdown'

export default ({selected, selectable = [], onSelect, onDeselect, buttonText = 'Add', buttonPosition = 'bottom'}) => {
  const [searchText, setSearchText] = useState('')

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const button = selectable.length ? (
    <Dropdown id='tags' className='field' isOpen={searchText.length} multi={true}>
      <a className='button is-small'>
        <span>{buttonText}</span>
        <span className='icon'>
          <i className='fa fa-caret-down'></i>
        </span>
      </a>
      {selectable.length > 10 ? (
        <div className='field'>
          <div className='control'>
            <input type='text' className='input is-small' onChange={handleSearch} placeholder='Search' />
          </div>
        </div>
      ) : null}
      {selectable.map((item, i) => {
        if (!item || item.name.search(new RegExp(searchText, 'i')) < 0) return
        return (
          <a key={i} onClick={() => onSelect && onSelect(item)}>
            {item.name}
          </a>
        )
      })}
    </Dropdown>
  ) : null

  const tags = (
    <div className='field is-grouped is-grouped-multiline'>
      {selected.map((item, i) => {
        if (!item) return

        let style = {}
        let color = item.colour || 'f5f5f5'
        style.backgroundColor = `#${color}`
        style.color = invert(`#${color}`, true)

        if (item.partial) {
          style.backgroundColor = colord(style.backgroundColor).alpha(0.5).toHex()
          style.color = colord(style.color).alpha(0.5).toHex()
        }

        return (
          <div className='control' key={i}>
            <div className={`tags ${onDeselect ? 'has-addons' : ''}`}>
              {item.partial ? (
                <a className='tag' style={style} onClick={() => onSelect(item)}>
                  {item.name}
                </a>
              ) : (
                <span className='tag' style={style}>
                  {item.name}
                </span>
              )}

              {onDeselect && <a className='tag is-delete' style={style} onClick={() => onDeselect(item)}></a>}
            </div>
          </div>
        )
      })}
      {buttonPosition === 'inline' ? button : null}
    </div>
  )

  if (buttonPosition === 'bottom') {
    return (
      <Fragment>
        {tags}
        {button}
      </Fragment>
    )
  } else if (buttonPosition === 'top') {
    return (
      <Fragment>
        {button}
        {tags}
      </Fragment>
    )
  } else {
    return tags
  }
}
