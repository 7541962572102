import React, {Fragment} from 'react'
import {Switch, Route, Link, useRouteMatch} from 'react-router-dom'
import {compact} from 'lodash'
import {pluralise, toParams, formatDate} from 'admin/lib/format'
import {hello} from 'admin/lib/greetings'
import useAccount from 'admin/hooks/useAccount'
import useOrganisation from 'admin/hooks/useOrganisation'
import useStatement from 'admin/hooks/useStatement'
import useBoolState from 'admin/hooks/useBoolState'
import Pagination from 'admin/components/ui/Pagination'
import Dropdown from 'admin/components/ui/Dropdown'
import Email from 'admin/components/Email'
import Transaction from 'admin/components/Transaction'
import NewTransaction from 'admin/components/NewTransaction'
import Table from './Table'
import Filters from './Filters'

export default () => {
  const match = useRouteMatch()
  const {organisationID, accountID} = match.params
  const {organisation} = useOrganisation(organisationID)
  const {account} = useAccount(organisationID, accountID)
  const {statement, errors, totalCount, filters, setFilters, send} = useStatement(organisationID, accountID)
  const [showSendModal, setShowSendModal, toggleShowSendModal] = useBoolState()

  const email = {
    to: account.billingEmail || account.contactEmail,
    subject: `Statement from ${organisation.name} for ${account.displayName}`,
    message: `${compact([hello(), account.contactFirstName]).join(' ')},

Here's your statement showing transaction history ${
      formatDate(filters.fromDate) ? 'from ' + formatDate(filters.fromDate) + ' to' : 'as at'
    } ${formatDate(filters.toDate) || 'today'}.

If you have any questions, please let us know.

Ngā mihi,
${organisation.name}`
  }

  const params = toParams({...filters, account_id: accountID})

  return (
    <Fragment>
      <Switch>
        <Route exact path={`${match.path}/:transactionID(\\d+)`} component={Transaction} />
        <Route path={`${match.path}/new`} component={NewTransaction} />
      </Switch>

      <Email
        email={email}
        errors={errors}
        onSend={email => send(email, toggleShowSendModal)}
        onDone={toggleShowSendModal}
        isOpen={showSendModal}>
        <h5 className='subtitle is-6'>
          <i className='fa fa-paperclip' aria-hidden='true'></i>
          &nbsp;&nbsp;
          <a
            href={`/orgs/${organisationID}/downloads/statement.pdf?${toParams({
              ...filters,
              account_id: accountID,
              inline: true
            })}`}
            target='_blank'>
            PDF attached
          </a>
        </h5>
      </Email>

      <section className='section'>
        <div className='container'>
          <div className='columns is-vcentered'>
            <div className='column'>
              <h4 className='subtitle'>
                Showing {statement.length} of {pluralise('transaction', totalCount, true)}
              </h4>
            </div>
            <div className='column buttons is-right'>
              <Dropdown id='account-transactions-options' className='is-right level-item'>
                <button className='button is-light'>
                  <span>New</span>
                  <span className='icon'>
                    <i className='fa fa-caret-down'></i>
                  </span>
                </button>
                <Link to={`/orgs/${organisationID}/accounts/${accountID}/transactions/new?type=payment`}>
                  New credit
                </Link>
                <Link to={`/orgs/${organisationID}/accounts/${accountID}/transactions/new?type=refund`}>New debit</Link>
                <Link to={`/orgs/${organisationID}/invoices/new`}>New invoice</Link>
                <Link to={`/orgs/${organisationID}/repeating_invoices/new`}>New repeating invoice</Link>
              </Dropdown>
              <Dropdown id='account-transactions-options' className='is-right level-item'>
                <button className='button is-light'>
                  <span>Download</span>
                  <span className='icon'>
                    <i className='fa fa-caret-down'></i>
                  </span>
                </button>
                <a href={`/orgs/${organisationID}/downloads/statement.csv?${params}`}>Statement as CSV</a>
                <a href={`/orgs/${organisationID}/downloads/statement.pdf?${params}`}>Statement as PDF</a>
              </Dropdown>
              <div className='level-item'>
                <button className='button is-info' onClick={toggleShowSendModal}>
                  <span>Send statement</span>
                </button>
              </div>
            </div>
          </div>
          <div className='transactions'>
            <div className='transactions__past'>
              <Filters {...filters} onSubmit={setFilters} />
              {statement.length != 0 ? (
                <Fragment>
                  <div className='level'>
                    <div className='level-left'></div>
                    <div className='level-right'>
                      <Pagination
                        page={filters.page}
                        limit={filters.limit}
                        total={totalCount}
                        onChange={setFilters}
                        className='level-item'
                      />
                    </div>
                  </div>
                  <Table
                    statement={statement}
                    onSort={setFilters}
                    sortBy={filters.sortBy}
                    sortDirection={filters.sortDirection}
                  />
                  {statement.length >= 10 && (
                    <div className='level'>
                      <div className='level-left'></div>
                      <div className='level-right'>
                        <Pagination
                          page={filters.page}
                          limit={filters.limit}
                          total={totalCount}
                          onChange={setFilters}
                          className='level-item'
                        />
                      </div>
                    </div>
                  )}
                </Fragment>
              ) : (
                <p>No transactions</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
