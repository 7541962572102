import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import useEffect from 'admin/hooks/useEffect'
import useGroup from 'admin/hooks/useGroup'
import useStateObject from 'admin/hooks/useStateObject'
import useDocument from 'admin/hooks/useDocument'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'

export default () => {
  const history = useHistory()
  const {organisationID, type, groupID} = useParams()
  const {errors, group, create} = useGroup(organisationID, type, groupID)
  const [form, setForm] = useStateObject(group)

  useDocument({title: 'New group'})

  useEffect(() => setForm(group), [group])

  useEffect(() => {
    jscolor.installByClassName('jscolor')
  })

  const handleColorChange = e => {
    e.preventDefault()
    setForm({colour: e.target.value})
  }

  const handleCreate = e => {
    e.preventDefault()
    create(form, history.goBack)
  }

  return (
    <Modal onClose={history.goBack} isOpen={true} minWidth='50%'>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title is-marginless'>New group</h3>
            </div>
            <div className='column buttons is-right'>
              <button className='button is-primary' onClick={handleCreate}>
                Create
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <form>
            <div className='field is-grouped'>
              <div className='control is-expanded'>
                <InputField
                  type='text'
                  name='name'
                  autoComplete='off'
                  value={form.name || ''}
                  errors={errors.name}
                  onChange={setForm}
                />
              </div>
              <div className='control'>
                <label htmlFor='colour' className='label is-small'>
                  Colour
                </label>
                <input
                  type='text'
                  name='colour'
                  autoComplete='off'
                  value={form.colour || ''}
                  className='input jscolor'
                  onChange={handleColorChange}
                  onBlur={handleColorChange}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  )
}
