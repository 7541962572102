import {combineReducers} from 'redux'
import account from './Account.reducer.js'
import accounts from './Accounts.reducer.js'
import pastTransactions from './PastTransactions.reducer.js'
import futureTransactions from './FutureTransactions.reducer.js'
import user from './User.reducer.js'
import organisation from './Organisation.reducer.js'
import types from 'customer/types/User.types.js'

const reducers = combineReducers({
  account,
  accounts,
  pastTransactions,
  futureTransactions,
  user,
  organisation
})

export default (state, action) => {
  if (action.type === types.FULFILLED_LOGOUT) state = undefined
  return reducers(state, action)
}
