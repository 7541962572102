import {combineReducers} from 'redux'
import types from 'customer/types/Transactions.types'
import createReducer from 'customer/reducers/createReducer'
import createRequestReducer from 'customer/reducers/createRequestReducer'

const INITIAL_DATA_STATE = []
const data = createReducer(INITIAL_DATA_STATE, {
  [types.GET_PAST_TRANSACTIONS]: (state, {data}) => (!data.page || data.page === 1 ? [] : state),
  [types.FULFILLED_GET_PAST_TRANSACTIONS]: (state, {data}) => state.concat(data.transactions)
})

const INITIAL_STATS_STATE = {
  paginated_count: 0,
  unpaginated_count: 0
}
const stats = createReducer(INITIAL_STATS_STATE, {
  [types.FULFILLED_GET_PAST_TRANSACTIONS]: (state, {data}) => ({
    paginated_count: data.paginated_count,
    unpaginated_count: data.unpaginated_count
  })
})

const INITIAL_FILTERS_STATE = {
  from_date: '',
  to_date: '',
  page: 1,
  limit: 30,
  sort_direction: 'desc'
}
const filters = createReducer(INITIAL_FILTERS_STATE, {
  [types.SAVE_PAST_TRANSACTIONS_FILTERS]: (state, action) => action.data,
  [types.GET_PAST_TRANSACTIONS]: (state, action) => ({...state, ...action.data})
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.FULFILLED_GET_PAST_TRANSACTIONS]: () => INITIAL_ERROR_STATE,
  [types.REJECTED_GET_PAST_TRANSACTIONS]: (_, {error}) => error
})

const getStatus = createRequestReducer(types.GET_PAST_TRANSACTIONS)

export default combineReducers({data, error, getStatus, filters, stats})
