// WIDTH is the maximum number of page links to show
const WIDTH = 5

// CENTER is the count between the first/last and center page links
const CENTER = Math.floor(WIDTH/2)

// return an array of numbers from low to high
const range = (low, high) => {
  return Array(high - low + 1).fill().map((_, idx) => low + idx)
}

// return an array of numbers to display as pagination
export default (currentPage, totalPages) => {
  let firstPage, lastPage, distFromLastPage = (totalPages - currentPage)

  if ((CENTER < currentPage) && (distFromLastPage >= CENTER)) {
    // when currentPage is nowhere near 1 & totalPages..
    // determine the first & last visible page links
    firstPage = Math.max(currentPage - CENTER, 1)
    lastPage = Math.min(currentPage + CENTER, totalPages)

  } else if (distFromLastPage < CENTER) {
    // when currentPage is near totalPages
    // determine the first visible page link
    firstPage = Math.max(totalPages - WIDTH + 1, 1)
    lastPage = totalPages

  } else {
    firstPage = 1
    lastPage = Math.min(WIDTH, totalPages)
  }

  return range(firstPage, lastPage)
}