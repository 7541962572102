import React, {Fragment} from 'react'
import {formatNumber} from 'customer/lib/format'

export default ({id, date, description, due_date, amount, balance, invoice_number, reference, expand, onClick}) => {
  const handleClick = e => {
    if (!e.target.classList.contains('download')) onClick()
  }
  return (
    <div onClick={handleClick} className={`row ${expand ? 'is-highlighted' : ''}`}>
      <div className='columns is-mobile is-multiline is-gapless'>
        <div className='column is-half'>
          <div>{invoice_number}</div>
          {expand ? null : <div className='has-text-grey mt-1 is-size-7'>{date}</div>}
        </div>
        <div className='column is-half has-text-right'>
          <div>-{formatNumber(amount)}</div>
          {expand ? null : <div className='has-text-grey mt-1 is-size-7'>{formatNumber(balance)}</div>}
        </div>

        <div className={`column is-full ${expand ? '' : 'is-hidden'}`}>
          <div className='columns is-mobile is-multiline is-gapless row-detail'>
            <div className='column is-half has-text-weight-medium'>Date</div>
            <div className='column is-half has-text-right'>{date}</div>

            <div className='column is-half has-text-weight-medium'>Due date</div>
            <div className='column is-half has-text-right'>{due_date}</div>

            <div className='column is-half has-text-weight-medium'>Description</div>
            <div className='column is-half has-text-right'>{description}</div>

            {reference ? (
              <Fragment>
                <div className='column is-half has-text-weight-medium'>Reference</div>
                <div className='column is-half has-text-right'>{reference}</div>
              </Fragment>
            ) : null}

            <div className='column is-half has-text-weight-medium'>Balance after</div>
            <div className='column is-half has-text-right'>{formatNumber(balance)}</div>

            <div className='column is-full has-text-right'>
              <a href={`invoices/${id}.pdf`} className='download'>
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
      <span className='icon has-text-grey-light'>
        <i className={`fa ${expand ? 'fa-angle-down' : 'fa-angle-right'}`} aria-hidden='true'></i>
      </span>
    </div>
  )
}
