import {combineReducers} from 'redux'
import types, {requestTypes} from 'customer/types/User.types'
import createReducer from 'customer/reducers/createReducer'
import createRequestReducer from 'customer/reducers/createRequestReducer'
import {asVariable} from 'customer/lib/format'

const INITIAL_USER_STATE = {}
const data = createReducer(INITIAL_USER_STATE, {
  [types.SAVE_USER]: (_, action) => action.data,
  [types.FULFILLED_GET_USER]: (_, action) => action.data,
  [types.FULFILLED_LOGIN]: (_, action) => action.data,
  [types.FULFILLED_UPDATE_USER]: (_, action) => action.data
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.SAVE_USER]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_GET_USER]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_LOGIN]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_UPDATE_USER]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_CONFIRM_EMAIL]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_FORGOT_PASSWORD]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_CHANGE_PASSWORD]: () => INITIAL_ERROR_STATE,
  [types.FULFILLED_SET_PASSWORD]: () => INITIAL_ERROR_STATE,

  [types.REJECTED_GET_USER]: (_, action) => action.error,
  [types.REJECTED_LOGIN]: (_, action) => action.error,
  [types.REJECTED_UPDATE_USER]: (_, action) => action.error,
  [types.REJECTED_CONFIRM_EMAIL]: (_, action) => action.error,
  [types.REJECTED_FORGOT_PASSWORD]: (_, action) => action.error,
  [types.REJECTED_CHANGE_PASSWORD]: (_, {error}) => {
    if (error.data && error.data.reset_password_token) {
      let baseErrors = error.data.base || []
      let tokenErrors = error.data.reset_password_token.map(m => `Token ${m}`)
      error.data.base = baseErrors.concat(tokenErrors)
    }
    return error
  },
  [types.REJECTED_SET_PASSWORD]: (_, {error}) => {
    if (error.data && error.data.invitation_token) {
      let baseErrors = error.data.base || []
      let tokenErrors = error.data.invitation_token.map(m => `Token ${m}`)
      error.data.base = baseErrors.concat(tokenErrors)
    }
    return error
  }
})

const requestReducers = requestTypes.reduce((reducers, requestType) => {
  reducers[`${asVariable(requestType)}Status`] = createRequestReducer(types[requestType])
  return reducers
}, {})

export default combineReducers({
  data,
  error,
  ...requestReducers
})
