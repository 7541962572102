import React from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import invert from 'invert-color'
import {isEmpty} from 'lodash'
import {formatTime, pluralise, toSentence} from 'admin/lib/format'
import useTextMessage from 'admin/hooks/useTextMessage'
import useDocument from 'admin/hooks/useDocument'

const statusColors = {
  enroute: '#EAAC3C',
  delivered: '#6DC47C',
  expired: '#D22043',
  rejected: '#D22043',
  failed: '#D22043'
}

export const Status = ({status}) => {
  const color = statusColors[status]
  let style = {overflowWrap: 'break-word'}
  if (color) {
    style.backgroundColor = color
    style.color = invert(color, true)
  }
  return (
    <span className='tag' style={style}>
      {status || 'submitted'}
    </span>
  )
}

export default ({textMessageId}) => {
  const {organisationID} = useParams()
  const {textMessage} = useTextMessage(organisationID, textMessageId)
  useDocument({
    title: `Text message${
      textMessage.recipients ? ` to ${pluralise('recipient', textMessage.recipients.length, true)}` : ''
    }`
  })

  if (isEmpty(textMessage)) return null

  const statusCounts = textMessage.recipients.reduce((counts, recipient) => {
    if (!counts[recipient.status]) counts[recipient.status] = 0
    counts[recipient.status] += 1
    return counts
  }, {})

  const statusCountsSentence = toSentence(
    Object.entries(statusCounts).map(([status, count]) => [count, status].join(' '))
  )

  return (
    <section className='section'>
      <div className='container'>
        <div className='block'>
          Submitted by {textMessage.sender} on {formatTime(textMessage.createdAt)}
        </div>

        <div className='block'>
          <h4 className='title is-5'>{pluralise('recipient', textMessage.recipients.length, true)}</h4>
          <p className='subtitle is-6'>{statusCountsSentence}</p>
          <table className='table is-striped is-fullwidth'>
            <colgroup>
              <col span='1' width='60%' />
              <col span='1' width='20%' />
              <col span='1' width='20%' />
            </colgroup>
            <thead>
              <tr>
                <th>Message</th>
                <th>Recipient</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {textMessage.recipients.map((recipient, i) => (
                <tr valign='top' key={i}>
                  <td className='has-linebreaks is-size-7' style={{marginBottom: '0', overflowWrap: 'break-word'}}>
                    {recipient.content}
                  </td>
                  <td>
                    <Link to={`/orgs/${organisationID}/accounts/${recipient.accountId}`}>{recipient.accountName}</Link>
                    <br />
                    {recipient.e164MobileNumber}
                  </td>
                  <td>
                    <Status status={recipient.status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}
