import {makeVar, useReactiveVar} from 'admin/lib/apollo'

var timeout = null
const INITIAL_VAR = {}
const reactiveVar = makeVar(INITIAL_VAR)

export default () => {
  const flash = useReactiveVar(reactiveVar)
  const setFlash = (message, {type = 'notice'} = {}) => {
    clearTimeout(timeout)
    reactiveVar({message, type})
    timeout = setTimeout(() => reactiveVar(INITIAL_VAR), 3500)
  }
  return [flash, setFlash]
}
