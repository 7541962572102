import React from 'react'
import {compact, isEmpty} from 'lodash'
import moment, {dateIsInTheFuture, dateIsTodayOrInThePast} from 'admin/lib/moment'
import {formatNumber} from 'admin/lib/format'
import InputField from 'admin/components/ui/InputField'
import DateField from 'admin/components/ui/DateField'
import AccountField from 'admin/components/shared/AccountField'
import ShowField from 'admin/components/ui/ShowField'
import LineItems from 'admin/components/shared/LineItems'
import {subtotalForInvoice, taxForInvoice, totalForInvoice, TAX_TYPES} from 'admin/lib/tax'

const sourceMessage = source => `This invoice was imported from ${source}.`

export default ({errors, organisationID, invoice, onChange, onEditEmailTemplate}) => {
  const fillBillTo = e => {
    e.preventDefault()
    const billingCustomer = invoice.account?.displayName?.toUpperCase()
    const billingAddress = invoice.account?.postalAddress || invoice.account?.physicalAddress
    handleChange({billTo: compact([billingCustomer, billingAddress]).join('\n')})
  }

  const editEmailTemplate = e => {
    e.preventDefault()
    onEditEmailTemplate()
  }

  const handleChange = changes => {
    let alteredChanges = {...changes}
    if ('date' in changes) {
      if (dateIsTodayOrInThePast(changes.date) || (dateIsInTheFuture(changes.date) && !invoice.email)) {
        alteredChanges.isSendable = false
      }
      let daysUntilDue = moment(invoice.dueDate).diff(moment(invoice.date), 'days')
      alteredChanges.dueDate = moment(changes.date).add(daysUntilDue, 'days').format('YYYY-MM-DD')
    } else if ('daysUntilDue' in changes) {
      delete alteredChanges.daysUntilDue
      alteredChanges.dueDate = moment(invoice.date).add(changes.daysUntilDue, 'days').format('YYYY-MM-DD')
    } else if ('accountId' in changes) {
      alteredChanges.billTo = null
    }
    onChange(alteredChanges)
  }

  return (
    <form>
      {invoice.source && (
        <div className='is-size-7 mb-4'>
          {sourceMessage(invoice.source)}{' '}
          {invoice.xeroLink && (
            <a href={invoice.xeroLink} target='_blank'>
              <span>Show in Xero</span>
              <span className='icon'>
                <i className='fa fa-external-link'></i>
              </span>
            </a>
          )}
        </div>
      )}

      <div className='columns pb-4'>
        <div className='column is-4'>
          <AccountField
            label='Account'
            value={invoice.account?.displayName}
            organisationID={organisationID}
            onSelect={account => handleChange({account, accountId: account?.id})}
            errors={errors.accountId}
          />

          <div className='field is-spaced'>
            <div className='control'>
              {!invoice.billTo && invoice.account?.id && (
                <a href='' onClick={fillBillTo} className='is-pulled-right is-size-7'>
                  Use account details
                </a>
              )}
              <InputField
                type='textarea'
                name='billTo'
                label='Bill to'
                value={invoice.billTo || ''}
                onChange={handleChange}
                errors={errors.billTo}
                rows={4}
              />
            </div>
          </div>

          {invoice.id && dateIsInTheFuture(invoice.date) && (
            <InputField
              type='checkbox'
              name='isSendable'
              checked={invoice.isSendable || false}
              label='Send email on invoice date'
              onChange={handleChange}
              help='Only approved invoices will be sent'>
              {invoice.isSendable && isEmpty(errors.email) ? (
                <a
                  href=''
                  className='is-size-7'
                  onClick={editEmailTemplate}
                  style={{position: 'absolute', top: 0, right: 0}}>
                  Edit email message
                </a>
              ) : invoice.isSendable ? (
                <a
                  href=''
                  className='is-size-7 has-text-danger'
                  onClick={editEmailTemplate}
                  style={{position: 'absolute', top: 0, right: -20}}>
                  <span className='icon-text'>
                    <span className='mr-1'>Edit email message</span>
                    <span className='icon has-text-danger'>
                      <i className='fa fa-exclamation-triangle'></i>
                    </span>
                  </span>
                </a>
              ) : null}
            </InputField>
          )}
        </div>
        <div className='column is-8-tablet is-6-desktop is-offset-2-desktop'>
          <DateField
            name='date'
            label='Invoice date'
            value={invoice.date || ''}
            onChange={handleChange}
            required
            errors={errors.date}
            isHorizontal={true}
          />

          <ShowField label='Invoice number' value={invoice.invoiceNumber} />

          <InputField
            type='text'
            name='reference'
            label='Invoice reference'
            value={invoice.reference || ''}
            onChange={handleChange}
            errors={errors.reference}
            isHorizontal={true}
          />

          <ShowField label='Account number' value={invoice.account && invoice.account.customerNumber} />
          <ShowField label='GST number' value={invoice.organisation && invoice.organisation.gstNumber} />

          <InputField
            type='number'
            name='daysUntilDue'
            label='Payment is due'
            value={invoice.dueDate ? moment(invoice.dueDate).diff(moment(invoice.date), 'days') : ''}
            min={0}
            onChange={handleChange}
            errors={errors.dueDate}
            disabled={!invoice.date}
            isHorizontal={true}>
            <a className='button is-static'>&nbsp; day(s) after invoice date</a>
          </InputField>
        </div>
      </div>

      <LineItems items={invoice.lineItems || []} name='lineItems' onChange={handleChange} errors={errors.lineItems} />

      <div className='field is-grouped is-grouped-right is-spaced'>
        <div className='control h'>
          <label className='label is-size-7'>Amounts are</label>
        </div>
        {Object.values(TAX_TYPES).map((type, i) => (
          <div key={i} className='control'>
            <label name={`taxType_${type}`} className='radio is-size-7'>
              <input
                type='radio'
                name='taxType'
                value={type}
                onChange={e => handleChange({taxType: e.target.value})}
                checked={invoice.taxType == type || (!invoice.taxType && type == TAX_TYPES.inclusive)}
              />
              <b>Tax {type.toLowerCase()}</b>
            </label>
          </div>
        ))}
      </div>

      <div className='field is-grouped is-grouped-right'>
        <div className='control'>
          <label className='label is-size-7'>Subtotal</label>
        </div>
        <div className='control has-text-right' style={{width: '100px'}}>
          {formatNumber(subtotalForInvoice(invoice), {thousands: true})}
        </div>
      </div>

      <div className='field is-grouped is-grouped-right'>
        <div className='control'>
          <label className='label is-size-7'>
            {invoice.taxType === TAX_TYPES.inclusive ? 'Includes GST 15%' : 'Total GST 15%'}
          </label>
        </div>
        <div className='control has-text-right' style={{width: '100px'}}>
          {formatNumber(taxForInvoice(invoice), {thousands: true})}
        </div>
      </div>

      <div className='field is-grouped is-grouped-right'>
        <div className='control'>
          <label className='label is-size-4'>TOTAL</label>
        </div>
        <div className='control has-text-right is-size-4' style={{width: '100px'}}>
          {formatNumber(totalForInvoice(invoice), {thousands: true})}
        </div>
      </div>
    </form>
  )
}
