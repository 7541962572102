import {useSelector, useDispatch} from 'react-redux'
import {getRepeatingInvoices} from 'admin/actions/RepeatingInvoices.actions'

const mapState = state => ({
  error: state.repeatingInvoices.error,
  repeatingInvoices: state.repeatingInvoices.data,
  counts: state.repeatingInvoices.counts,
  getStatus: state.repeatingInvoices.getStatus,
  updateStatus: state.repeatingInvoices.updateStatus
})

export default organisationId => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)

  return {
    ...store,
    getRepeatingInvoices: filters => getRepeatingInvoices(organisationId, filters)(dispatch)
  }
}
