import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {MapContainer, TileLayer, Polygon, Marker, useMapEvent, Tooltip, LayersControl} from 'react-leaflet'
import useServiceTowers from 'admin/hooks/useServiceTowers'
import useAccounts from 'admin/hooks/useAccounts'

const MoveControl = ({filters, setFilters}) => {
  const map = useMapEvent('moveend', () => {
    setFilters({bounds: map.getBounds().toBBoxString(), zoom: map.getZoom()})
  }, [])

  useEffect(() => {
    if (filters.bounds.length) {
      let [lng1, lat1, lng2, lat2] = filters.bounds.split(",")
      map.fitBounds([[lat1, lng1],[lat2, lng2]])
    }
  }, [])

  return null
}

const randomColor = () => {
  return ('#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'))
}

export default () => {
  const {organisationID} = useParams()
  const {serviceTowers, filters: serviceTowersFilters, setFilters: serviceTowersSetFilters} = useServiceTowers(organisationID)
  const {accounts, setFilters: accountsSetFilters} = useAccounts(organisationID)

  const setFilters = (filters) => {
    serviceTowersSetFilters(filters)
    accountsSetFilters(filters)
  }

  return (
    <div style={{height: '800px'}}>
      <MapContainer center={[-40.988, 173.771]} zoom={7} scrollWheelZoom={false} style={{height: '100%'}}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />

        {serviceTowers.map(
          ({name, coords}, i) =>
            coords && (
              <Marker position={coords} key={i}>
                <Tooltip>{name}</Tooltip>
              </Marker>
            )
        )}

        {serviceTowers.length ? (
          <LayersControl position='topright' collapsed={false}>
            {serviceTowers.map(
              ({name, coverage}, i) =>
                coverage && (
                  <LayersControl.Overlay name={name} key={i}>
                    <Polygon pathOptions={{color: randomColor(), fillOpacity: 0.5}} positions={coverage} />
                  </LayersControl.Overlay>
                )
            )}
          </LayersControl>
        ) : null}

        {accounts.map(({displayName, physicalAddress, physicalCoords}, i) => {
          if (!physicalCoords) return null
          return (
            <Marker position={physicalCoords} key={i}>
              <Tooltip>
                {displayName}
                <br />
                {physicalAddress}
              </Tooltip>
            </Marker>
          )
        })}

        <MoveControl filters={serviceTowersFilters} setFilters={setFilters} />
      </MapContainer>
    </div>
  )
}
