import axios from 'axios'

// compose Rails specific request header as { X-CSRF-Token: csrfToken, X-Requested-With: XMLHttpRequest }
const config = () => {
  return {headers: ReactOnRails.authenticityHeaders()}
}

export default ({method, path, baseType, data, successParser, errorParser}) => dispatch => {
  dispatch({type: baseType, data})
  let args = method === 'get' ? [path, config()] : [path, data, config()]
  return axios[method](...args)
    .then(response => {
      const data = successParser ? successParser(response.data) : response.data
      dispatch({type: `${baseType}_FULFILLED`, data})
      return {data}
    })
    .catch(error => {
      let {response} = error
      if (!response) throw error
      const data = errorParser ? errorParser(response.data) : response.data
      const headers = !!response.headers['retry-after'] ? {['retry-after']: response.headers['retry-after']} : {}
      dispatch({type: `${baseType}_REJECTED`, error: {data, status: response.status, headers}})
      return {error: {data, status: response.status, headers}}
    })
}
