import React from 'react'
import {capitalize, formatTime} from 'admin/lib/format'
import Tags from 'admin/components/ui/Tags'

export default ({onClick, data}) => {
  if (!data.length) return null

  return (
    <table className='table is-striped is-hoverable is-fullwidth is-linked'>
      <colgroup>
        <col span='1' style={{width: '20%'}} />
        <col span='1' style={{width: '30%'}} />
        <col span='1' style={{width: '10%'}} />
        <col span='1' style={{width: '15%'}} />
        <col span='1' style={{width: '25%'}} />
      </colgroup>
      <thead>
        <tr>
          <th>User</th>
          <th>Accounts</th>
          <th>Invited</th>
          <th>Login enabled</th>
          <th>Last login</th>
        </tr>
      </thead>
      <tbody>
        {data.map((user, i) => (
          <tr key={i} onClick={() => onClick(user.id)}>
            <td>
              {user.name || user.email}
              {user.name ? <sub className='is-size-7 is-italic'>{user.email}</sub> : null}
            </td>
            <td>
              <div className='tags'>
                {user.accounts.map((account, i) => (
                  <span key={i} className='tag'>
                    {account.name}
                  </span>
                ))}
              </div>
            </td>
            <td className='is-size-5'>{String.fromCharCode(!!user.invitation_sent_at ? 10003 : 215)}</td>
            <td className='is-size-5'>{String.fromCharCode(!user.access_revoked_at ? 10003 : 215)}</td>
            <td>{formatTime(user.last_sign_in_at)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
