import React from 'react'
import Modal from 'admin/components/ui/Modal'

export default ({
  children,
  confirm,
  onConfirm,
  onCancel,
  onClose,
  isOpen,
  confirmText,
  cancelText,
  buttonText,
  buttonClass,
  minWidth = '45%'
}) => {
  const handleConfirm = e => {
    if (onConfirm) onConfirm(e)
    if (onClose) onClose()
  }

  const handleCancel = e => {
    if (onCancel) onCancel(e)
    if (onClose) onClose()
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} minWidth={minWidth}>
      <section className='section'>
        <form onSubmit={e => e.preventDefault()}>
          <div className='field'>
            <div className='control'>{confirm}</div>
          </div>
          {children}
          <div className='buttons'>
            <button onClick={handleCancel} className='button'>
              {cancelText || 'Cancel'}
            </button>
            <button onClick={handleConfirm} className={`button is-primary ${buttonClass}`}>
              {confirmText || buttonText || 'Confirm'}
            </button>
          </div>
        </form>
      </section>
    </Modal>
  )
}
