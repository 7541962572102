import pluralize from 'pluralize'
import qs from 'qs'
import moment from 'customer/lib/moment'

// pluralize('test', 5) //=> "tests"
// pluralize('test', 1, true) //=> "1 test"
export const pluralise = pluralize

export const formatNumber = (number, opts = {}) => {
  if (!number) {
    return 0
  }
  if (opts.accounting) {
    if (number > 0) {
      // credits show in brackets (the opposite of accounting formats - usually negative numbers are in brackets)
      const num = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number)
      return `(${num})`
    } else {
      return new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(
        Math.abs(number)
      )
    }
  } else if (opts.currency) {
    return new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'NZD', currencyDisplay: 'symbol'}).format(
      number
    )
  } else {
    return new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number)
  }
}

export const capitalize = text =>
  text.charAt(0).toUpperCase() +
  text
    .substr(1)
    .toLowerCase()
    .replace('_', ' ')

export const asVariable = text => text.toLowerCase().replace(/_(.)/g, v => v.charAt(1).toUpperCase())

export const frequencyInWords = (count, frequency) => {
  let word = {monthly: 'month', weekly: 'week'}[frequency]
  return pluralise(word, count, true)
}

export const formatDate = dateString => {
  if (!dateString || !dateString.length) return null
  return moment(dateString).format('D MMM YYYY')
}

export const formatTime = (timeString, multiline = false) => {
  if (!timeString || !timeString.length) return null
  return moment(timeString).format(`D MMM YYYY${multiline ? '[\n]' : ' '}h:mm a`)
}

export const toParams = data => qs.stringify(data, {arrayFormat: 'brackets'})
export const fromParams = string => qs.parse(string)
