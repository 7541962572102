import {gql} from '@apollo/client'
import {FULL_INVOICE_FIELDS} from './Fragments.gql'

export const GET_INVOICE = gql`
  ${FULL_INVOICE_FIELDS}
  query GetInvoice($invoiceId: ID!, $organisationId: ID!) {
    invoice(id: $invoiceId, organisationId: $organisationId) {
      ...FullInvoiceFields
    }
  }
`

export const CREATE_INVOICE = gql`
  ${FULL_INVOICE_FIELDS}
  mutation CreateInvoice(
    $organisationId: ID!
    $taxType: String
    $date: String
    $dueDate: String
    $notes: String
    $isSendable: Boolean
    $accountId: ID
    $reference: String
    $billTo: String
    $lineItems: JSON
  ) {
    invoice(
      organisationId: $organisationId
      taxType: $taxType
      date: $date
      dueDate: $dueDate
      notes: $notes
      isSendable: $isSendable
      accountId: $accountId
      reference: $reference
      billTo: $billTo
      lineItems: $lineItems
    ) {
      ...FullInvoiceFields
    }
  }
`

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice(
    $organisationId: ID!
    $invoiceId: ID!
    $taxType: String
    $date: String
    $dueDate: String
    $notes: String
    $isSendable: Boolean
    $accountId: ID
    $reference: String
    $billTo: String
    $lineItems: JSON
    $email: JSON
    $state: String
  ) {
    invoice(
      organisationId: $organisationId
      id: $invoiceId
      taxType: $taxType
      date: $date
      dueDate: $dueDate
      notes: $notes
      isSendable: $isSendable
      accountId: $accountId
      reference: $reference
      billTo: $billTo
      lineItems: $lineItems
      email: $email
      state: $state
    ) {
      id
      taxType
      date
      dueDate
      isSendable
      account {
        id
        name
        displayName @client
        contactFirstName
        contactLastName
        billingEmail
        contactEmail
        physicalAddress
        postalAddress
        customerNumber
      }
      reference
      billTo
      state
      lineItems
      email
    }
  }
`

export const SEND_INVOICE_EMAIL = gql`
  mutation SendInvoiceEmail(
    $invoiceId: ID!
    $organisationId: ID!
    $to: String
    $cc: String
    $bcc: String
    $subject: String
    $message: String
  ) {
    invoice: sendInvoiceEmail(
      invoiceId: $invoiceId
      organisationId: $organisationId
      to: $to
      cc: $cc
      bcc: $bcc
      subject: $subject
      message: $message
    ) {
      id
      email
    }
  }
`
