import React, {Fragment} from 'react'
import Flash from 'admin/components/Flash'

export default ({id, children, title, subtitle, className, buttons}) => (
  <Fragment>
    <Flash />
    <section className={`section ${className ? className : ''}`} id={id}>
      <div className='container'>
        {title || subtitle || buttons ? (
          <div className='columns is-vcentered is-mobile'>
            {title || subtitle ? (
              <div className='column'>
                <h3 className='title'>{title}</h3>
                <h4 className='subtitle'>{subtitle}</h4>
              </div>
            ) : null}
            {buttons ? <div className={`column buttons ${title || subtitle ? 'is-right' : ''}`}>{buttons}</div> : null}
          </div>
        ) : null}
        {children}
      </div>
    </section>
  </Fragment>
)
