import {gql} from 'admin/lib/apollo'

export const GET_ORGANISATION = gql`
  query GetOrganisation($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      name
      gstNumber
      customerNumbersSequence
      invoiceNumbersSequence
      color
      emailDisclaimer
      paymentDetails
    }
  }
`

export const UPDATE_ORGANISATION = gql`
  mutation UpdateOrganisation(
    $organisationId: ID!
    $name: String
    $gstNumber: String
    $customerNumbersSequence: Int
    $invoiceNumbersSequence: Int
    $emailDisclaimer: String
    $paymentDetails: String
  ) {
    organisation(
      id: $organisationId
      name: $name
      gstNumber: $gstNumber
      customerNumbersSequence: $customerNumbersSequence
      invoiceNumbersSequence: $invoiceNumbersSequence
      emailDisclaimer: $emailDisclaimer
      paymentDetails: $paymentDetails
    ) {
      id
      name
      gstNumber
      customerNumbersSequence
      invoiceNumbersSequence
      color
      emailDisclaimer
      paymentDetails
    }
  }
`
