import React from 'react'
import {Link} from 'react-router-dom'

export default ({breadcrumbs, children}) =>
  breadcrumbs.length ? (
    <nav className='breadcrumb has-succeeds-separator' aria-label='breadcrumbs'>
      <ul>
        {breadcrumbs.map(({path, label}, i) => (
          <li key={i}>
            <Link to={path}>{label}</Link>
          </li>
        ))}
        {children && <li></li>}
      </ul>
      {children}
    </nav>
  ) : (
    children
  )
