import {useState} from 'react'

export default (initialState = {}, defaults = {}) => {
  const withDefaults = (state, update = {}) => {
    let result = {...state, ...update}

    for (let key in defaults) {
      result[key] = {
        ...defaults[key],
        ...(state[key] || {}),
        ...(update[key] || {})
      }
    }

    return result
  }

  const [state = {}, setState] = useState(withDefaults(initialState))

  const mergeState = (newState = {}) => setState(withDefaults(state, newState))
  const resetState = (newState = {}) => setState(withDefaults(initialState, newState))

  return [state, mergeState, resetState]
}
