import {combineReducers} from 'redux'
import types from 'customer/types/Organisation.types'
import createReducer from 'customer/reducers/createReducer'
import createRequestReducer from 'customer/reducers/createRequestReducer'

const INITIAL_ORGANISATION_STATE = {}
const data = createReducer(INITIAL_ORGANISATION_STATE, {
  [types.FULFILLED_GET_ORGANISATION]: (state, action) => action.data
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.FULFILLED_GET_ORGANISATION]: () => INITIAL_ERROR_STATE
})

const getStatus = createRequestReducer(types.GET_ORGANISATION)

export default combineReducers({data, error, getStatus})
