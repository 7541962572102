import {gql} from 'admin/lib/apollo'

export const BASE_GROUP_FIELDS = gql`
  fragment BaseGroupFields on Group {
    id
    name
    colour
    numOfAccountsByState
  }
`

export const CREATE_GROUP = gql`
  ${BASE_GROUP_FIELDS}
  mutation CreateGroup($organisationId: ID!, $name: String, $colour: String) {
    group(organisationId: $organisationId, name: $name, colour: $colour) {
      ...BaseGroupFields
    }
  }
`

export const UPDATE_GROUP = gql`
  ${BASE_GROUP_FIELDS}
  mutation UpdateGroup($organisationId: ID!, $id: ID, $name: String, $colour: String) {
    group(organisationId: $organisationId, id: $id, name: $name, colour: $colour) {
      ...BaseGroupFields
    }
  }
`
