import {useDispatch, useSelector} from 'react-redux'
import useEffect from 'admin/hooks/useEffect'
import {
  getUser,
  createUser,
  updateUser,
  inviteUser,
  setupUser,
  sendUserInvitation,
  revokeUserAccess,
  restoreUserAccess,
  destroyUser,
  sendUserVerification
} from 'admin/actions/User.actions'

const mapState = state => ({
  user: state.user.data,
  error: state.user.error,
  getUserStatus: state.user.getUserStatus,
  updateUserStatus: state.user.updateUserStatus,
  createUserStatus: state.user.createUserStatus,
  inviteUserStatus: state.user.inviteUserStatus,
  setupUserStatus: state.user.setupUserStatus,
  sendUserInvitationStatus: state.user.sendUserInvitationStatus,
  revokeUserAccessStatus: state.user.revokeUserAccessStatus,
  restoreUserAccessStatus: state.user.restoreUserAccessStatus,
  destroyUserStatus: state.user.destroyUserStatus,
  sendUserVerificationStatus: state.user.sendUserVerificationStatus,
  addUserAccountStatus: state.user.addUserAccountStatus,
  removeUserAccountStatus: state.user.removeUserAccountStatus
})

const parse = data => {
  let {accounts, ...rest} = data
  return {
    account_ids: accounts.map(a => a.id),
    ...rest
  }
}

export default (organisationID, userID) => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)

  useEffect(() => {
    if (!store.getUserStatus.fetching && !store.getUserStatus.fetched && userID) {
      getUser(organisationID, userID)(dispatch)
    }
  }, [[store.getUserStatus, userID]])

  return {
    ...store,
    createUser: data => createUser(organisationID, parse(data))(dispatch),
    inviteUser: data => inviteUser(organisationID, {customer: parse(data)})(dispatch),
    updateUser: data => updateUser(organisationID, userID, parse(data))(dispatch),
    setupUser: data => setupUser(organisationID, data)(dispatch),
    sendUserInvitation: () => sendUserInvitation(organisationID, userID)(dispatch),
    revokeUserAccess: () => revokeUserAccess(organisationID, userID)(dispatch),
    restoreUserAccess: () => restoreUserAccess(organisationID, userID)(dispatch),
    destroyUser: () => destroyUser(organisationID, userID)(dispatch),
    sendUserVerification: () => sendUserVerification(organisationID, userID)(dispatch)
  }
}
