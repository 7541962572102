import React, {Fragment} from 'react'
import {fromParams} from 'admin/lib/format'
import useEffect from 'admin/hooks/useEffect'
import useTransaction from 'admin/hooks/useTransaktion'
import useAccount from 'admin/hooks/useAccount'
import useDocument from 'admin/hooks/useDocument'
import useBoolState from 'admin/hooks/useBoolState'
import useStateObject from 'admin/hooks/useStateObject'
import InputField from 'admin/components/ui/InputField'
import DateField from 'admin/components/ui/DateField'
import Modal from 'admin/components/ui/Modal'
import Flash from 'admin/components/Flash'
import History from 'admin/components/History'

const DESTROY_CONFIRMATION = 'Are you sure you want to delete?'

export default ({match, location, history}) => {
  const {organisationID, accountID, transactionID} = match.params
  const {type} = fromParams(location.search.slice(1))
  const {account} = useAccount(organisationID, accountID)
  const {transaction, errors, update, destroy} = useTransaction(organisationID, accountID, transactionID)
  const [showActivityModal, setShowActivityModal, toggleShowActivityModal] = useBoolState()
  const [form, setForm, resetForm] = useStateObject(transaction)

  const transactionType = (type || form.type) == 'payment' ? 'Credit' : 'Debit'
  const transactionTitle = form.id && `${transactionType} ${form.id}`

  useDocument({title: [transactionTitle || '', account.name || ''].filter(v => v.length).join(' - ')})

  useEffect(() => resetForm(transaction), [transaction], false)
  useEffect(() => {
    if (type && !transaction.id) setForm({type})
  }, [])

  const handleUpdate = e => {
    e.preventDefault()
    update(form, history.goBack)
  }

  const handleDestroy = e => {
    e.preventDefault()
    if (confirm(DESTROY_CONFIRMATION)) destroy({}, history.goBack)
  }

  const handleChange = changes => {
    setForm(changes)
  }

  const handleCancel = e => {
    e.preventDefault()
    resetForm(transaction)
    history.goBack()
  }

  const readOnly = transaction.source === 'bank'

  return (
    <Fragment>
      <Modal onClose={handleCancel} isOpen={true}>
        {form.source && (
          <section className='py-3 section has-background-dark'>
            <div className='container'>
              <span className='is-size-7 has-text-light'>
                This transaction was imported from {form.source.replace('bank', 'a bank statement')}.{' '}
                {form.xero_link && (
                  <a href={form.xero_link} target='_blank'>
                    <span>Show in Xero</span>
                    <span className='icon'>
                      <i className='fa fa-external-link'></i>
                    </span>
                  </a>
                )}
              </span>
            </div>
          </section>
        )}
        <section className='section has-background-light'>
          <div className='container'>
            <div className='columns is-vcentered is-mobile'>
              <div className='column'>
                <h3 className='title is-marginless'>{transactionTitle}</h3>
              </div>
              <div className='column buttons is-right'>
                <a onClick={toggleShowActivityModal} className='button is-white'>
                  <span className='icon is-small'>
                    <i className='fa fa-history' aria-hidden='true'></i>
                  </span>
                  <span>View History & Notes</span>
                </a>
                <Fragment>
                  <button
                    className='button is-danger is-outlined'
                    onClick={handleDestroy}>
                    Delete
                  </button>
                  <button className='button is-primary' onClick={handleUpdate}>
                    Save
                  </button>
                </Fragment>
              </div>
            </div>
          </div>
        </section>
        <section className='section'>
          <div className='container'>
            <form>
              <div className='field is-grouped'>
                <div className='control is-expanded'>
                  <DateField
                    name='date'
                    value={form.date || ''}
                    onChange={handleChange}
                    errors={errors.date}
                    readOnly={readOnly}
                    className={readOnly ? 'is-static' : ''}
                    required
                  />
                </div>

                <div className='control is-expanded'>
                  <InputField
                    type='number'
                    name='amount'
                    value={form.amount || ''}
                    onChange={handleChange}
                    errors={errors.amount}
                    autoFocus={true}
                    readOnly={readOnly}
                    className={readOnly ? 'is-static' : ''}
                    required
                  />
                </div>
              </div>

              <div className='field'>
                <div className='control'>
                  <InputField
                    type='text'
                    name='description'
                    value={form.description || ''}
                    onChange={handleChange}
                    errors={errors.description}
                    required
                  />
                </div>
              </div>
            </form>
          </div>
        </section>
      </Modal>

      <Modal onClose={toggleShowActivityModal} isOpen={showActivityModal}>
        <Flash />
        <History
          itemType='Payment'
          itemId={transactionID}
          onAddNote={notes => update({notes})}
          itemTitle={transactionTitle}
        />
      </Modal>
    </Fragment>
  )
}
