import React from 'react'
export default () => (
  <section className='section'>
    <div className='container'>
      <h1 className='title is-1'>Oops!</h1>
      <h2 className='subtitle is-3'>404 - Page not found</h2>
      <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
    </div>
  </section>
)
