import React from 'react'
import {Link} from 'react-router-dom'
import {formatTime, pluralise} from 'admin/lib/format'
import {Status} from 'admin/components/TextMessage'

export default ({data, organisationID, onClick}) => (
  <table className='table is-striped is-fullwidth'>
    <colgroup>
      <col span='1' width='40%' />
      <col span='1' width='20%' />
      <col span='1' width='10%' />
      <col span='1' width='15%' />
      <col span='1' width='15%' />
    </colgroup>
    <thead>
      <tr>
        <th>Message</th>
        <th>Recipients</th>
        <th>Status</th>
        <th>Sender</th>
        <th>Submitted on</th>
      </tr>
    </thead>
    <tbody>
      {data.map((message, i) => {
        const deliveredCount = message.recipients.reduce((count, recipient) => {
          if (recipient.status === 'delivered') count += 1
          return count
        }, 0)

        const handleClick = e => {
          e.preventDefault()
          onClick(message.id)
        }

        if (message.recipients.length === 0) {
          return null
        } else if (message.recipients.length === 1) {
          return (
            <tr key={i}>
              <td className='has-linebreaks is-size-7' style={{marginBottom: '0', overflowWrap: 'break-word'}}>
                {message.recipients[0].content}
              </td>
              <td>
                <Link to={`/orgs/${organisationID}/accounts/${message.recipients[0].accountId}`}>
                  {message.recipients[0].accountName}
                </Link>
                <br />
                {message.recipients[0].e164MobileNumber}
              </td>
              <td>
                <Status status={message.recipients[0].status} />
              </td>
              <td>{message.sender}</td>
              <td>{formatTime(message.createdAt)}</td>
            </tr>
          )
        } else {
          return (
            <tr key={i}>
              <td className='has-linebreaks is-size-7' style={{marginBottom: '0', overflowWrap: 'break-word'}}>
                {message.contentWithPlaceholders}
              </td>
              <td>
                <a href='' onClick={handleClick}>
                  {pluralise('recipient', message.recipients.length, true)}
                </a>
              </td>
              <td>
                <p className='is-size-7'>
                  {`${Math.round((deliveredCount / message.recipients.length) * 100)}%`}
                  <br />
                  delivered
                </p>
              </td>
              <td>{message.sender}</td>
              <td>{formatTime(message.createdAt)}</td>
            </tr>
          )
        }
      })}
    </tbody>
  </table>
)
