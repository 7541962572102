import {gql} from '@apollo/client'
import {FULL_REPEATING_INVOICE_FIELDS} from './Fragments.gql'

export const GET_REPEATING_INVOICE = gql`
  ${FULL_REPEATING_INVOICE_FIELDS}
  query GetRepeatingInvoice($repeatingInvoiceId: ID!, $organisationId: ID!) {
    repeatingInvoice(id: $repeatingInvoiceId, organisationId: $organisationId) {
      ...FullRepeatingInvoiceFields
    }
  }
`

export const CREATE_REPEATING_INVOICE = gql`
  ${FULL_REPEATING_INVOICE_FIELDS}
  mutation (
    $organisationId: ID!
    $taxType: String
    $nextInvoiceDate: String
    $nextInvoiceDueDate: String
    $notes: String
    $isSendable: Boolean
    $accountId: ID
    $reference: String
    $billTo: String
    $lineItems: JSON
    $forceDate: Boolean
    $frequencyCount: Int
    $frequency: String
    $endDate: String
  ) {
    repeatingInvoice(
      organisationId: $organisationId
      taxType: $taxType
      nextInvoiceDate: $nextInvoiceDate
      nextInvoiceDueDate: $nextInvoiceDueDate
      notes: $notes
      isSendable: $isSendable
      accountId: $accountId
      reference: $reference
      billTo: $billTo
      lineItems: $lineItems
      forceDate: $forceDate
      frequencyCount: $frequencyCount
      frequency: $frequency
      endDate: $endDate
    ) {
      ...FullRepeatingInvoiceFields
    }
  }
`

export const UPDATE_REPEATING_INVOICE = gql`
  ${FULL_REPEATING_INVOICE_FIELDS}
  mutation UpdateRepeatingInvoice(
    $organisationId: ID!
    $repeatingInvoiceId: ID!
    $taxType: String
    $nextInvoiceDate: String
    $nextInvoiceDueDate: String
    $notes: String
    $isSendable: Boolean
    $accountId: ID
    $reference: String
    $billTo: String
    $lineItems: JSON
    $emailWithPlaceholders: JSON
    $state: String
    $forceDate: Boolean
    $frequencyCount: Int
    $frequency: String
    $endDate: String
  ) {
    repeatingInvoice(
      organisationId: $organisationId
      id: $repeatingInvoiceId
      taxType: $taxType
      nextInvoiceDate: $nextInvoiceDate
      nextInvoiceDueDate: $nextInvoiceDueDate
      notes: $notes
      isSendable: $isSendable
      accountId: $accountId
      reference: $reference
      billTo: $billTo
      lineItems: $lineItems
      emailWithPlaceholders: $emailWithPlaceholders
      state: $state
      forceDate: $forceDate
      frequencyCount: $frequencyCount
      frequency: $frequency
      endDate: $endDate
    ) {
      ...FullRepeatingInvoiceFields
    }
  }
`
