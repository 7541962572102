import {parseError, useMutation, useQuery} from 'admin/lib/apollo'
import {GET_SERVICE, CREATE_SERVICE, UPDATE_SERVICE} from 'admin/gql/Service.gql'
import useDestroyable from 'admin/hooks/useDestroyable'
import mutateWith from './mutateWith'
import clearCache from './clearCache'

export default (organisationId, accountId, serviceId) => {
  const {data: {service} = {}, error: getError} = useQuery(GET_SERVICE, {
    variables: {organisationId, serviceId},
    skip: !serviceId,
    errorPolicy: 'all'
  })

  const [create, {error: createError}] = useMutation(CREATE_SERVICE, {
    variables: {accountId},
    errorPolicy: 'all',
    update: cache => clearCache({services: {organisationId, accountId}})(cache)
  })

  const [update, {error: updateError}] = useMutation(UPDATE_SERVICE, {
    variables: {accountId, serviceId},
    errorPolicy: 'all',
    update: (cache, {data: {service}}) => {
      if (String(accountId) !== String(service.accountId)) {
        return clearCache({services: [{organisationId, accountId}, {organisationId, accountId: service.accountId}]})(cache)
      } else {
        return {}
      }
    }
  })

  const {destroy, errors: destroyError} = useDestroyable(organisationId, 'AccountInternetService', serviceId)

  return {
    service,
    errors: parseError(getError || createError || updateError) || destroyError,
    create: mutateWith(create, 'Service created'),
    update: mutateWith(update, 'Service updated'),
    destroy
  }
}
