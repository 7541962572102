import React, {Fragment} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import {isEmpty} from 'lodash'
import Login from 'customer/components/Login'
import ForgotPassword from 'customer/components/ForgotPassword'
import ChangePassword from 'customer/components/ChangePassword'
import SetPassword from 'customer/components/SetPassword'
import ConfirmEmail from 'customer/components/ConfirmEmail'
import Flash from 'admin/components/Flash'
import useOrganisation from 'customer/hooks/useOrganisation'
import {invertColor, lightenColor, complementColor} from 'customer/lib/color'

export default props => {
  const currentPath = props.match.path
  const {organisation} = useOrganisation()

  if (isEmpty(organisation)) return null

  let style = {}
  if (organisation.color) {
    let lightenedColor = lightenColor(organisation.color, 0.1)
    style = {
      color: invertColor(organisation.color),
      backgroundColor: lightenedColor,
      backgroundImage: `linear-gradient(175deg, ${lightenedColor} 0%, ${lightenedColor} 35%, ${complementColor(
        organisation.color
      )} 100%)`
    }
  }

  return (
    <Fragment>
      <Flash floating={true} />
      <section id='login' className='hero is-primary is-fullheight' style={style}>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-centered'>
              <div className='column is-7-tablet is-6-desktop is-5-widescreen'>
                {organisation.logo ? (
                  <img src={organisation.logo} alt='logo' style={{height: '50px', marginBottom: '1em'}} />
                ) : (
                  organisation.name
                )}

                <Switch>
                  <Route path={`${currentPath}/forgotpassword`} component={ForgotPassword} />
                  <Route path={`${currentPath}/changepassword/:token`} component={ChangePassword} />
                  <Route path={`${currentPath}/invitation/:token`} component={SetPassword} />
                  <Route path={`${currentPath}/confirmation/:token`} component={ConfirmEmail} />
                  <Route exact path={`${currentPath}`} component={Login} />
                  <Redirect to={`${currentPath}`} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
