import {useSelector, useDispatch} from 'react-redux'
import useEffect from 'admin/hooks/useEffect'

const mapState = state => ({
  admin: state.admin
})

export default (organisationID, id) => {
  const {admin} = useSelector(mapState)
  return {admin}
}
