import React from 'react'
import {useParams, useHistory} from 'react-router-dom'
import useEffect from 'admin/hooks/useEffect'
import useService from 'admin/hooks/useService'
import useDocument from 'admin/hooks/useDocument'
import useStateObject from 'admin/hooks/useStateObject'
import InputField from 'admin/components/ui/InputField'
import PlanSelect from 'admin/components/ui/PlanSelect'
import Modal from 'admin/components/ui/Modal'
import AccountField from 'admin/components/ui/AccountField'

export default () => {
  const history = useHistory()
  const {organisationID, accountID, serviceID} = useParams()
  const {service, errors, update, destroy} = useService(organisationID, accountID, serviceID)
  const [form, setForm] = useStateObject(service)

  useDocument({title: 'Edit service'})

  useEffect(() => setForm(service), [service], false)

  const handleUpdate = e => {
    e.preventDefault()
    update(form, history.goBack)
  }

  const handlePlanSelection = plan => {
    setForm({plan, planId: plan.id})
  }

  const handleDelete = () => {
    destroy({}, history.goBack)
  }

  return (
    <Modal onClose={history.goBack} isOpen={true}>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns  is-vcentered is-mobile'>
            <div className='is-5 column'>
              <h3 className='title is-marginless'>Edit service</h3>
            </div>
            <div className='is-7 column buttons is-right'>
              <button className='button is-danger is-outlined' onClick={handleDelete}>
                Delete
              </button>
              <button className='button is-success' onClick={handleUpdate}>
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <div className='field'>
            <div className='control'>
              <label className='label is-small'>Service plan</label>
              <PlanSelect
                buttonText={form.plan && form.plan.name}
                onChange={handlePlanSelection}
                errors={errors.internetServicePlanId}
              />
            </div>
          </div>
          <InputField
            type='text'
            name='description'
            label='Description'
            placeholder='34 Pine Drive - the blue house at the back'
            value={form.description || ''}
            onChange={setForm}
            errors={errors.description}
          />
          <InputField
            type='text'
            name='macAddress'
            label='MAC Address'
            placeholder='78:7B:8A:B2:D8:CB'
            value={form.macAddress || ''}
            onChange={setForm}
            errors={errors.macAddress}
          />
          <AccountField
            label='Transfer to'
            value={form.transferTo}
            organisationID={organisationID}
            onChange={account => account && setForm({transferTo: account.display_name, accountId: account.id})}
            errors={errors.accountId}
          />
        </div>
      </section>
    </Modal>
  )
}
