import useEffect from 'customer/hooks/useEffect'
import useOrganisation from 'customer/hooks/useOrganisation'

export default ({title}) => {
  const {organisation} = useOrganisation()

  useEffect(() => {
    document.title = title ? `${title} | ${organisation.name}` : organisation.name
  })
}
