import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {fromParams} from 'admin/lib/format'
import useEffect from 'admin/hooks/useEffect'
import useTransaction from 'admin/hooks/useTransaktion'
import useStateObject from 'admin/hooks/useStateObject'
import useDocument from 'admin/hooks/useDocument'
import InputField from 'admin/components/ui/InputField'
import DateField from 'admin/components/ui/DateField'
import Modal from 'admin/components/ui/Modal'

export default () => {
  const history = useHistory()
  const {search} = useLocation()
  const {type} = fromParams(search.slice(1))
  const {organisationID, accountID} = useParams()
  const {transaction, errors, create} = useTransaction(organisationID, accountID)
  const [form, setForm] = useStateObject(transaction)

  const transactionType = ((type || transaction.type) == 'payment' ? 'Credit' : 'Debit').toLowerCase()

  useDocument({title: `New ${transactionType}`})

  useEffect(() => setForm({type}), [type])

  const handleCreate = e => {
    e.preventDefault()
    create({...form, reconciled: true}, history.goBack)
  }

  return (
    <Modal onClose={history.goBack} isOpen={true}>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title is-marginless'>New {transactionType}</h3>
            </div>
            <div className='column buttons is-right'>
              <button className='button is-primary' onClick={handleCreate}>
                Create
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <form>
            <div className='field is-grouped'>
              <div className='control is-expanded'>
                <DateField name='date' value={form.date || ''} onChange={setForm} errors={errors.date} required />
              </div>

              <div className='control is-expanded'>
                <InputField
                  type='number'
                  name='amount'
                  value={form.amount ? Math.abs(form.amount) : ''}
                  onChange={({amount}) => setForm({amount: Number(transactionType == 'credit' ? amount : -amount)})}
                  errors={errors.amount}
                  autoFocus={true}
                  required
                />
              </div>
            </div>

            <div className='field'>
              <div className='control'>
                <InputField
                  type='text'
                  name='description'
                  value={form.description || ''}
                  onChange={setForm}
                  errors={errors.description}
                  required
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  )
}
