import Flash from 'admin/components/Flash'
import sortable from 'admin/components/hoc/sortable'
import Dropdown from 'admin/components/ui/Dropdown'
import Pagination from 'admin/components/ui/Pagination'
import Tabs from 'admin/components/ui/Tabs'
import useDocument from 'admin/hooks/useDocument'
import useEffect from 'admin/hooks/useEffect'
import useInvoices from 'admin/hooks/useInvoices'
import {formatNumber, pluralise} from 'admin/lib/format'
import React, {Fragment} from 'react'
import {Link, matchPath} from 'react-router-dom'
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params'
import Filters from './Filters'

const RESTORE_CONFIRMATION = 'Are you sure you want to restore?'

export default ({match, location}) => {
  const {organisationID} = match.params
  const {invoices, counts, getInvoices, getInvoicesStatus, errors, restore} = useInvoices(organisationID)
  const [filters, setFilters] = useQueryParams({
    q: withDefault(StringParam, ''),
    from_date: withDefault(StringParam, ''),
    to_date: withDefault(StringParam, ''),
    sort_by: withDefault(StringParam, 'date'),
    sort_direction: withDefault(StringParam, 'desc'),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50)
  })

  const getDeletedInvoices = () => getInvoices({...filters, type: 'deleted'})

  useEffect(() => {
    if (!getInvoicesStatus.fetched || matchPath(location.pathname, {path: match.path, exact: true}))
      getDeletedInvoices()
  }, [filters])

  useDocument({title: 'Deleted invoices'})

  const handleRestore = e => {
    e.preventDefault()
    if (confirm(RESTORE_CONFIRMATION)) {
      restore({recordIds: [e.currentTarget.dataset.id]}, getDeletedInvoices)
    }
  }

  const SortableTH = sortable('th', {
    onSort: setFilters,
    sortBy: filters.sort_by,
    sortDirection: filters.sort_direction
  })

  return (
    <Fragment>
      <Flash />
      <Tabs
        active='Deleted'
        collection={[
          {label: 'Draft', to: `/orgs/${organisationID}/invoices/draft`},
          {label: 'Approved', to: `/orgs/${organisationID}/invoices/approved`},
          {label: 'Repeating', to: `/orgs/${organisationID}/repeating_invoices`},
          {label: 'Deleted', to: `/orgs/${organisationID}/invoices/deleted`}
        ]}
      />
      <section className='section' id='admin_invoices'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title'>Manage deleted invoices</h3>
              <h4 className='subtitle'>{`Showing ${counts.count || 0} of ${pluralise(
                `deleted invoice`,
                counts.results_count || 0,
                true
              )}`}</h4>
            </div>
            <div className='column buttons is-right'>
              <Dropdown id='invoices-options' className='is-right'>
                <button className='button is-light'>
                  <span>New</span>
                  <span className='icon'>
                    <i className='fa fa-caret-down'></i>
                  </span>
                </button>
                <Link to={`/orgs/${organisationID}/invoices/new`}>New invoice</Link>
                <Link to={`/orgs/${organisationID}/repeating_invoices/new`}>New repeating invoice</Link>
              </Dropdown>
            </div>
          </div>
          <Filters q={filters.q} fromDate={filters.from_date} toDate={filters.to_date} onSubmit={setFilters} />
          {counts.count && counts.count != 0 ? (
            <Fragment>
              <div className='level'>
                <div className='level-left'></div>
                <div className='level-right'>
                  <Pagination
                    page={filters.page}
                    limit={filters.limit}
                    total={counts.results_count}
                    onChange={setFilters}
                    className='level-item'
                  />
                </div>
              </div>

              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th>Account</th>
                    <SortableTH column='date' name='Date' />
                    <SortableTH column='invoice_number' name='Number' />
                    <SortableTH name='reference' />
                    <SortableTH className='has-text-right' name='amount' />
                    <SortableTH className='has-text-right' column='deleted_at' name='Deleted at' />
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice, i) => (
                    <tr key={i}>
                      <td>{invoice.account_name}</td>
                      <td>{invoice.date}</td>
                      <td>{invoice.invoice_number}</td>
                      <td>{invoice.reference}</td>
                      <td className='has-text-right'>{formatNumber(invoice.amount)}</td>
                      <td className='has-text-right'>{invoice.deleted_at}</td>
                      <td className='has-text-right'>
                        <Dropdown className='is-right'>
                          <button className='button is-outlined'>
                            <span>Actions</span>
                            <span className='icon'>
                              <i className='fa fa-caret-down' aria-hidden='true'></i>
                            </span>
                          </button>
                          <a className='dropdown-item' onClick={handleRestore} data-id={invoice.id}>
                            Restore
                          </a>
                          <Link
                            to={`/orgs/${organisationID}/invoices/deleted/${invoice.id}/activity`}
                            className='dropdown-item'>
                            History & Notes
                          </Link>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {counts.count > 10 && (
                <div className='level'>
                  <div className='level-left'></div>
                  <div className='level-right'>
                    <Pagination
                      page={filters.page}
                      limit={filters.limit}
                      total={counts.results_count}
                      onChange={setFilters}
                      className='level-item'
                    />
                  </div>
                </div>
              )}
            </Fragment>
          ) : (
            <p>No deleted invoices</p>
          )}
        </div>
      </section>
    </Fragment>
  )
}
