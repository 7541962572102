import pluralize from 'pluralize'
import qs from 'qs'
import moment from 'admin/lib/moment'
import {camelCase as camel, snakeCase as snake, capitalize as cap, startCase} from 'lodash'

export const camelCase = camel
export const snakeCase = snake
export const capitalize = cap

export const titlecase = text => capitalize(snakeCase(text).replace(/_/g, ' '))

// pluralize('test', 5) //=> "tests"
// pluralize('test', 1, true) //=> "1 test"
export const pluralise = (word, count, prefix) => {
  if (!!word) {
    return pluralize(word, count, prefix)
  } else if (prefix) {
    return count
  } else {
    return word
  }
}

export const toSentence = arr => {
  if (arr.length > 1) {
    return arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1)
  } else {
    return arr[0]
  }
}

export const formatNumber = (number, opts = {}) => {
  if (number !== 0 && !number) {
    return 0
  }
  if (opts.accounting) {
    if (number > 0) {
      // credits show in brackets (the opposite of accounting formats - usually negative numbers are in brackets)
      const num = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number)
      return `(${num})`
    } else {
      return new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(
        Math.abs(number)
      )
    }
  } else if (opts.currency) {
    return new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'NZD', currencyDisplay: 'symbol'}).format(
      number
    )
  } else {
    return new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number)
  }
}

export const asVariable = text => text.toLowerCase().replace(/_(.)/g, v => v.charAt(1).toUpperCase())

export const frequencyInWords = (count, frequency) => {
  let word = {monthly: 'month', weekly: 'week'}[frequency]
  return pluralise(word, count, true)
}

export const formatDate = date => {
  if (!date || date === '') return null
  if (!moment.isMoment(date)) date = moment(date)
  return date.format('D MMM YYYY')
}

export const formatTime = (timeString, multiline = false) => {
  if (!timeString || !timeString.length) return null
  return moment(timeString).format(`D MMM YYYY${multiline ? '[\n]' : ' '}h:mm a`)
}

export const toParams = (data, snakecase = true) =>
  qs.stringify(data, {
    arrayFormat: 'brackets',
    encoder: (str, defaultEncoder, _charset, type) => {
      if (type === 'key' && snakecase) str = str.replace(/(\w+)/g, snakeCase)
      return defaultEncoder(str)
    }
  })

export const fromParams = string => qs.parse(string)

export const toSeconds = (amount, measure) => {
  return measure === 'days'
    ? amount * 86400
    : measure === 'weeks'
    ? amount * 604800
    : measure === 'months'
    ? amount * 2628000
    : measure === 'years'
    ? amount * 31540000
    : 0
}

export const fromSeconds = seconds => {
  seconds = Number(seconds)
  return seconds >= 31540000
    ? [seconds / 31540000, 'years']
    : seconds >= 2628000
    ? [seconds / 2628000, 'months']
    : seconds >= 604800
    ? [seconds / 604800, 'weeks']
    : seconds >= 86400
    ? [seconds / 86400, 'days']
    : [0, 'days']
}
