import {combineReducers} from 'redux'
import types from 'admin/types/Users.types'
import createReducer from 'admin/reducers/createReducer'
import createRequestReducer from 'admin/reducers/createRequestReducer'

const INITIAL_DATA_STATE = []
const data = createReducer(INITIAL_DATA_STATE, {
  [types.GET_USERS_FULFILLED]: (_, action) => action.data.users
})

const INITIAL_COUNTS_STATE = {paginated_count: 0, unpaginated_count: 0}
const counts = createReducer(INITIAL_COUNTS_STATE, {
  [types.GET_USERS_FULFILLED]: (state, action) => ({
    paginated_count: action.data.paginated_count,
    unpaginated_count: action.data.unpaginated_count
  })
})

const INITIAL_ERROR_STATE = {}
const error = createReducer(INITIAL_ERROR_STATE, {
  [types.GET_USERS_FULFILLED]: () => INITIAL_ERROR_STATE,
  [types.GET_USERS_REJECTED]: (_, {error}) => error
})

const getStatus = createRequestReducer(types.GET_USERS)

export default combineReducers({data, error, getStatus, counts})
