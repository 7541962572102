import React, {Fragment} from 'react'
import InputField from 'admin/components/ui/InputField'
import {formatNumber} from 'admin/lib/format'

export default ({items, name, onChange, readOnly, errors = []}) => {
  if (!items || !items.length) items = [{}]

  const handleChange = (index, changes) => {
    let lineItems = [...items]
    let parsedChanges = Object.entries(changes).reduce((memo, change) => {
      memo[change[0].replace(/_\d+/, '')] = change[1]
      return memo
    }, {})
    lineItems[index] = {...lineItems[index], ...parsedChanges}
    onChange({[name]: lineItems})
  }

  const handleAddItem = e => {
    e.preventDefault()
    onChange({[name]: [...items, {}]})
  }

  const handleRemoveItem = (index, e) => {
    e.preventDefault()
    let lineItems = [...items]
    lineItems.splice(index, 1)
    onChange({[name]: lineItems})
  }

  return (
    <div className='line-items'>
      {items.map(({description, unitPrice, quantity}, i) => {
        const onChange = changes => handleChange(i, changes)
        const error = errors.find(err => err.index == i) || {}
        const onRemove = event => handleRemoveItem(i, event)

        return (
          <div className='field is-grouped' key={i}>
            <div className='control is-expanded'>
              <InputField
                showLabel={i === 0}
                type='text'
                id={`description_${i}`}
                name='description'
                readOnly={readOnly}
                className={readOnly ? 'is-static' : ''}
                value={description || ''}
                onChange={onChange}
                style={{flexGrow: 2}}
                errors={error.description}
              />
            </div>
            <div className='control'>
              <InputField
                showLabel={i === 0}
                type='number'
                id={`quantity_${i}`}
                name='quantity'
                readOnly={readOnly}
                className={readOnly ? 'is-static' : ''}
                min={0}
                value={quantity === 0 ? 0 : quantity || ''}
                onChange={onChange}
                errors={error.quantity}
              />
            </div>
            <div className='control'>
              <InputField
                showLabel={i === 0}
                type='number'
                id={`unitPrice_${i}`}
                name='unitPrice'
                readOnly={readOnly}
                className={readOnly ? 'is-static' : ''}
                min={0}
                label='Unit price'
                step={0.5}
                value={unitPrice === 0 ? 0 : unitPrice || ''}
                onChange={onChange}
                errors={error.unitPrice}
              />
            </div>
            <div className='control amount'>
              <div className='field'>
                {i === 0 && <label className='label is-small has-text-right'>Amount</label>}
                <div className='control'>
                  <span className='value'>{formatNumber((unitPrice || 0) * (quantity || 0), {thousands: true})}</span>
                </div>
              </div>
            </div>
            {!readOnly && (
              <div className='control remove'>
                <div className='field'>
                  {i === 0 ? (
                    <Fragment>
                      <label className='label is-small'>{'\u00A0'}</label>
                      <div className='control'>
                        <div className='button is-text is-invisible'>
                          <span className='icon is-small'></span>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <div className='control'>
                      <a className='button is-text' onClick={onRemove}>
                        <span className='icon is-small'>
                          <i className='fa fa-times' aria-hidden='true'></i>
                        </span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      })}

      {!readOnly && (
        <button href='' className='button is-outlined is-dark' onClick={handleAddItem}>
          Add a new line
        </button>
      )}
    </div>
  )
}
