import React from 'react'
import useUser from 'customer/hooks/useUser'
import useEffect from 'customer/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'
import useDocument from 'customer/hooks/useDocument'

export default ({match, history}) => {
  const {token} = match.params
  const {confirmEmail, error, confirmEmailStatus} = useUser()
  const [_flash, setFlash] = useFlash()

  useDocument({title: 'Confirm your email'})

  useEffect(() => confirmEmail(token), [])

  useEffect(
    () => {
      if (confirmEmailStatus.fetched) {
        if (!error.data) {
          setFlash('Your email address has been confirmed')
          history.replace('/login')
        } else {
          setFlash('There are error(s) on the form', {type: 'alert'})
        }
      }
    },
    [confirmEmailStatus],
    false
  )

  return null
}
