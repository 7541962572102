import {gql} from 'admin/lib/apollo'

export const GET_TEXT_MESSAGES = gql`
  query GetTextMessages($organisationId: ID!, $q: String, $page: Int, $limit: Int) {
    textMessages(organisationId: $organisationId, q: $q, page: $page, limit: $limit) {
      collection {
        id
        contentWithPlaceholders
        sender
        recipients
        createdAt
      }
      metadata {
        totalCount
      }
    }
  }
`
