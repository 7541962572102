import React, {Fragment} from 'react'
import useAccount from 'customer/hooks/useAccount'
import useFutureTransactions from 'customer/hooks/useFutureTransactions'
import Tabs from 'customer/components/ui/Tabs'
import useEffect from 'customer/hooks/useEffect'

export default ({match}) => {
  const {accountID} = match.params
  const {account} = useAccount(accountID)
  const {transactions, getTransactionsStatus} = useFutureTransactions(accountID)

  useEffect(() => {
    if (!getTransactionsStatus.fetching) getTransactions()
  }, [accountID])

  return (
    <div id='customer_account'>
      <Tabs
        className='is-fullwidth-mobile'
        active='Upcoming'
        collection={[
          {label: 'Transactions', to: `/accounts/${accountID}/transactions`},
          {label: 'Upcoming', to: `/accounts/${accountID}/upcoming`},
          {label: 'Details', to: `/accounts/${accountID}`}
        ]}
      />
      <section className='section'>
        <div className='container'>
          <table className='table is-fullwidth is-hoverable is-linked is-striped'>
            <tbody>
              {transactions.map((transaction, i) => (
                <tr key={i} onClick={() => handleClick(transaction)}>
                  <td>{transaction.date}</td>
                  <td className='has-text-right'>{formatNumber(transaction.amount)}</td>
                  <td>{transaction.description}</td>
                  <td>
                    {transaction.frequency ? (
                      <span>
                        {`every ${frequencyInWords(transaction.frequency_count, transaction.frequency)}`}
                        {transaction.end_date && `, ends ${transaction.end_date}`}
                      </span>
                    ) : (
                      'one off'
                    )}
                  </td>
                  <td>{transaction.invoice_number}</td>
                  <td>{transaction.reference && `Ref: ${transaction.reference}`}</td>
                  <td>
                    {transaction.status === 'send' && (
                      <span className='tag greenish is-small'>{capitalize(transaction.status)}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  )
}
