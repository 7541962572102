import types from 'customer/types/User.types'
import send from 'customer/lib/action'
import {toParams} from 'customer/lib/format'

export const loginUser = data =>
  send({
    method: 'post',
    path: `/users/login`,
    baseType: types.LOGIN,
    data
  })

export const logoutUser = () =>
  send({
    method: 'get',
    path: `/users/logout`,
    baseType: types.LOGOUT
  })

export const getUser = () =>
  send({
    method: 'get',
    path: `/user.json`,
    baseType: types.GET_USER
  })

export const updateUser = data =>
  send({
    method: 'put',
    path: `/users`,
    baseType: types.UPDATE_USER,
    data
  })

export const confirmEmail = data =>
  send({
    method: 'get',
    path: `/users/confirmation?${toParams(data)}`,
    baseType: types.CONFIRM_EMAIL
  })

export const forgotPassword = data =>
  send({
    method: 'post',
    path: `/users/password`,
    baseType: types.FORGOT_PASSWORD,
    data
  })

export const changePassword = data =>
  send({
    method: 'put',
    path: `/users/password`,
    baseType: types.CHANGE_PASSWORD,
    data
  })

export const setPassword = data =>
  send({
    method: 'put',
    path: `/users/invitation`,
    baseType: types.SET_PASSWORD,
    data
  })
