import React, {Children, cloneElement, useRef} from 'react'
import useEffect from 'admin/hooks/useEffect'
import useBoolState from 'admin/hooks/useBoolState'

export default ({children, id, className, isOpen, multi}) => {
  const ref = useRef()
  const [showMenu, setShowMenu, toggleShowMenu] = useBoolState(isOpen || false)

  const childrens = Children.toArray(children)

  useEffect(() => {
    document.addEventListener('click', handleExit)
    return () => document.removeEventListener('click', handleExit)
  }, [])

  const handleExit = ({target: clickedElement}) => {
    do {
      if (clickedElement == ref.current) return
      clickedElement = clickedElement.parentNode
    } while (clickedElement)
    setShowMenu(false)
  }

  const onItemClick = (e, onClick) => {
    if (!multi) setShowMenu(false)
    if (onClick) onClick(e)
  }

  return (
    <div ref={ref} className={`dropdown ${showMenu ? 'is-active' : ''} ${className || ''}`}>
      <div className='dropdown-trigger'>
        {cloneElement(childrens[0], {['aria-controls']: id, ['aria-haspopup']: 'true', onClick: toggleShowMenu})}
      </div>
      <div className='dropdown-menu' id={id} role='menu'>
        <div className='dropdown-content'>
          {childrens.slice(1).map(child =>
            cloneElement(child, {
              className: 'dropdown-item',
              onClick: e => onItemClick(e, child.props.onClick)
            })
          )}
        </div>
      </div>
    </div>
  )
}
