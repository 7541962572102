import {gql} from '@apollo/client'

export const FULL_INVOICE_FIELDS = gql`
  fragment FullInvoiceFields on Invoice {
    id
    account {
      id
      displayName @client
      name
      contactFirstName
      contactLastName
      billingEmail
      contactEmail
      physicalAddress
      postalAddress
      customerNumber
    }
    invoiceNumber
    reference
    date
    dueDate
    source
    xeroLink
    billTo
    isSendable
    state
    isSent
    lineItems
    taxType
    email
  }
`

export const FULL_REPEATING_INVOICE_FIELDS = gql`
  fragment FullRepeatingInvoiceFields on RepeatingInvoice {
    id
    account {
      id
      displayName @client
      name
      contactFirstName
      contactLastName
      billingEmail
      contactEmail
      physicalAddress
      postalAddress
      customerNumber
    }
    reference
    nextInvoiceDate
    nextInvoiceDueDate
    source
    xeroLink
    billTo
    isSendable
    state
    lineItems
    taxType
    emailWithPlaceholders
    frequencyCount
    frequency
    endDate
  }
`
