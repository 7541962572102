import React, {useState} from 'react'
import {useQuery} from 'admin/lib/apollo'
import {useParams} from 'react-router-dom'
import {GET_GROUPS} from 'admin/gql/Groups.gql'
import useEffect from 'admin/hooks/useEffect'
import Tags from 'admin/components/ui/Tags'

export default ({
  selectedIds = [],
  partialSelectedIds = [],
  onSelect,
  onUnselect,
  buttonText,
  buttonPosition,
  tagPrefix,
  type
}) => {
  const {organisationID} = useParams()
  const {data} = useQuery(GET_GROUPS, {variables: {organisationId: organisationID, type}})
  const groups = data ? data.groups : []

  const selected = groups
    .filter(group => [...partialSelectedIds, ...selectedIds].includes(String(group.id)))
    .map(group => ({
      ...group,
      name: [tagPrefix, group.name].join(' '),
      partial: partialSelectedIds.includes(String(group.id))
    }))

  const selectable = groups
    .filter(group => ![...selectedIds, ...partialSelectedIds].includes(String(group.id)))
    .sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })

  return (
    <Tags
      selected={selected}
      selectable={selectable}
      onDeselect={onUnselect}
      onSelect={onSelect}
      buttonText={buttonText}
      buttonPosition={buttonPosition}
    />
  )
}
