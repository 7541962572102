import React, {Fragment} from 'react'

export default React.forwardRef(({accounts, onClick, organisationId}, ref) => {
  let handleSelection = (e, account) => {
    e.preventDefault()
    onClick(account)
  }
  return (
    <div className='dropdown-content' ref={ref}>
      {accounts.map((account, key) => (
        <a key={key} className='dropdown-item' onClick={e => handleSelection(e, account)}>
          {account.displayName}
        </a>
      ))}
      {accounts.length < 5 && (
        <Fragment>
          <hr className='dropdown-divider' />
          <a
            href={`/orgs/${organisationId}/accounts?n=1`}
            target='_blank'
            rel='noopener noreferrer'
            className='has-text-weight-semibold dropdown-item'>
            Create a new account
          </a>
        </Fragment>
      )}
    </div>
  )
})
