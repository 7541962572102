import React, {useRef, useState} from 'react'
import {gql, useLazyQuery} from 'admin/lib/apollo'
import useEffect from 'admin/hooks/useEffect'
import InputField from 'admin/components/ui/InputField'
import List from './List'

const QUERY = gql`
  query GetAccountsForAccountField($organisationId: ID!, $search: String) {
    accounts(organisationId: $organisationId, q: $search, limit: 5, state: "active") {
      collection {
        id
        displayName @client
        name
        contactFirstName
        contactLastName
        contactEmail
        physicalAddress
        postalAddress
        customerNumber
      }
    }
  }
`

export default ({
  organisationID: organisationId,
  value,
  label = 'Account',
  showLabel = true,
  className,
  onChange,
  onSelect,
  exclude = [],
  ...props
}) => {
  const ref = useRef()
  const [showList, setShowList] = useState(false)
  const [search, setSearch] = useState(value)

  useEffect(() => setSearch(value), [value], false)

  useEffect(() => {
    document.addEventListener('click', handleExit)
    return () => document.removeEventListener('click', handleExit)
  }, [])

  const [getAccounts, {data: {accounts = {}} = {}}] = useLazyQuery(QUERY)

  const handleExit = ({target: clickedElement}) => {
    do {
      if (clickedElement == ref.current) return
      clickedElement = clickedElement.parentNode
    } while (clickedElement)
    setShowList(false)
  }

  const handleSearch = ({search}) => {
    setSearch(search)
    if (search && search.length) {
      getAccounts({variables: {organisationId, search}})
      setShowList(true)
    } else {
      setShowList(false)
      if (!search.length) onSelect({id: null})
    }
  }

  const handleSelection = account => {
    onSelect && onSelect({...account, name: account.displayName})
    setSearch(null)
    setShowList(false)
  }

  const data = accounts.collection ? accounts.collection.filter(({id}) => !exclude.includes(id)) : []

  return (
    <div style={{width: '100%'}} className={`dropdown ${data.length && showList ? 'is-active' : ''}`}>
      <div style={{width: '100%'}} className='dropdown-trigger'>
        <InputField
          {...props}
          type='text'
          name='search'
          label={label}
          showLabel={showLabel}
          autoComplete='off'
          value={search || ''}
          aria-controls='accounts-list'
          aria-haspopup='true'
          onChange={handleSearch}
          className={props.readOnly ? `${className} is-static` : className}
        />
      </div>
      <div className='dropdown-menu' id='accounts-list' role='menu'>
        <List ref={ref} accounts={data} onClick={handleSelection} organisationId={organisationId} />
      </div>
    </div>
  )
}
