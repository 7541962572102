import React from 'react'
import InputField from 'admin/components/ui/InputField'

export default ({value, values, errors, ...props}) => {
  if (values) {
    return (
      <div className='field is-grouped'>
        {Object.keys(values).map((name, i) => (
          <div className='control is-expanded' key={i}>
            <InputField key={i} {...props} name={name} value={values[name] || ''} errors={errors[name]} />
          </div>
        ))}
      </div>
    )
  } else {
    if (props.type === 'address') props.type = 'textarea'
    return <InputField {...props} value={value || ''} errors={errors} isHorizontal={true} />
  }
}
