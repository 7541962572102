import React, {Fragment, useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import {gql, useQuery} from 'admin/lib/apollo'
import Dropdown from 'admin/components/ui/Dropdown'

const QUERY = gql`
  query GetPlansForPlanField($organisationId: ID!) {
    plans(organisationId: $organisationId) {
      collection {
        id
        name
      }
    }
  }
`

export default ({buttonText, onChange, errors}) => {
  const match = useRouteMatch()
  const {organisationID} = match.params

  const {data} = useQuery(QUERY, {variables: {organisationId: organisationID}})
  const options = data && data.plans ? data.plans.collection : []

  const [searchText, setSearchText] = useState('')

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const handleSelect = (e, option) => {
    e.preventDefault()
    onChange(option)
  }

  return (
    <Fragment>
      <Dropdown id='plan-field' className={`field ${errors ? 'mb-0' : ''}`} isOpen={searchText.length}>
        <a className={`button ${errors ? 'is-danger is-outlined' : ''}`}>
          <span>{buttonText || 'Select plan'}</span>
          <span className='icon'>
            <i className='fa fa-caret-down'></i>
          </span>
        </a>
        {options.length > 10 ? (
          <div className='field'>
            <div className='control'>
              <input type='text' className='input is-small' onChange={handleSearch} placeholder='Search' />
            </div>
          </div>
        ) : null}
        {options.map(option => {
          if (!option || option.name.search(new RegExp(searchText, 'i')) < 0) return
          return (
            <a key={option.id} onClick={e => handleSelect(e, option)}>
              {option.name}
            </a>
          )
        })}
      </Dropdown>
      {errors && <span className={`help ${errors ? 'is-danger' : ''}`}>{errors.join(', ')}</span>}
    </Fragment>
  )
}
