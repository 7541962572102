import send from 'admin/lib/action'
import {parseInvoicesRequest, parseInvoicesResponse} from 'admin/lib/parse'
import {toParams} from 'admin/lib/format'
import types from 'admin/types/Invoices.types'

export const updateInvoices = (organisationID, data) =>
  send({
    method: 'put',
    path: `/orgs/${organisationID}/bulk/invoices`,
    baseType: types.UPDATE_INVOICES,
    data: {invoices: data, task: 'update'}
  })

export const getInvoices = (organisationID, filters = {}) =>
  send({
    method: 'get',
    path: `/orgs/${organisationID}/invoices.json?${toParams(parseInvoicesRequest(filters))}`,
    baseType: types.GET_INVOICES,
    data: filters,
    successParser: parseInvoicesResponse
  })
