import React, {Fragment, useState} from 'react'
import {formatTime} from 'admin/lib/format'
import useHistory from 'admin/hooks/useHistory'
import InputField from 'admin/components/ui/InputField'

export default ({itemType, itemId, itemTitle, onAddNote, onChangeNote, className, children}) => {
  const [notes, setNotes] = useState('')
  const {history, loading} = useHistory(itemType, itemId)

  const handleChange = ({notes}) => {
    setNotes(notes)
    if (onChangeNote) onChangeNote({notes})
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (notes.length) {
      setNotes('')
      onAddNote(notes)
    }
  }

  if (!itemId) return null

  return (
    <section className={`section ${className || ''}`}>
      <div className='container'>
        <div className='columns is-vcentered'>
          <div className='is-5 column'>
            <h4 className='title'>History & Notes{itemTitle && ` for ${itemTitle}`}</h4>
          </div>
          <div className='is-7 column buttons is-right'>{children}</div>
        </div>

        {onAddNote && (
          <form>
            <InputField value={notes} type='textarea' name='notes' label='Add a note' onChange={handleChange} />
            <button onClick={handleSubmit} className='button is-primary'>
              Add
            </button>
          </form>
        )}
        <br />
        {loading ? (
          <span>Loading...</span>
        ) : !history.length ? (
          <span>There is no history.</span>
        ) : (
          <table className={`table is-striped is-fullwidth ${className || ''}`}>
            <colgroup>
              <col span='1' style={{width: '15%'}} />
              <col span='1' style={{width: '15%'}} />
              <col span='1' style={{width: '10%'}} />
              <col span='1' style={{width: '60%'}} />
            </colgroup>
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Event</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody className='is-size-7'>
              {history.map((version, i) => {
                return (
                  <tr valign='top' key={i}>
                    <td>{formatTime(version.timestamp)}</td>
                    <td>{version.user}</td>
                    <td>{version.event}</td>
                    <td>
                      {version.details
                        .filter(detail => detail && detail.length)
                        .map((detail, i) => (
                          <Fragment key={i}>
                            <span>{detail}</span>
                            <br />
                          </Fragment>
                        ))}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </section>
  )
}
