import React, {useState, useRef} from 'react'
import moment from 'admin/lib/moment'
import useEffect from 'admin/hooks/useEffect'
import Pikaday from 'pikaday'
import InputField from 'admin/components/ui/InputField'

const DISPLAY_FORMAT = 'D MMM YYYY'
const DATA_FORMAT = 'YYYY-MM-DD' // iso8601

export default ({onChange, name, value: val, readOnly, maxDate, minDate, ...props}) => {
  const [value, setValue] = useState('')
  const [pikaday, setPikaday] = useState()
  const ref = useRef()

  useEffect(() => {
    if (val && val !== '') {
      let momentVal = moment(val)
      if (momentVal.isValid()) setValue(momentVal.format(DISPLAY_FORMAT))
    }
  }, [val])

  useEffect(() => {
    if (ref.current.type !== 'date' && !readOnly) {
      setPikaday(
        new Pikaday({
          field: ref.current,
          onSelect: handlePikadaySelection,
          minDate,
          maxDate,
          keyboardInput: false
        })
      )
    }

    return () => pikaday && pikaday.destroy()
  }, [])

  const handlePikadaySelection = date => {
    let momentValue = date instanceof moment ? date : moment(date)
    if (momentValue.isValid()) setValue(momentValue.format(DISPLAY_FORMAT))
  }

  const handleInputBlur = () => {
    if (value === '') {
      onChange({[name]: null})
    } else {
      let momentValue = moment(value, DISPLAY_FORMAT)
      if (momentValue.isValid()) {
        setValue(momentValue.format(DISPLAY_FORMAT))
        onChange({[name]: momentValue.format(DATA_FORMAT)})
      }
    }
  }

  const handleInputChange = changes => {
    setValue(changes[name])
    let momentValue = moment(changes[name])
    if (momentValue.isValid()) pikaday && pikaday.setMoment(momentValue, true)
  }

  return (
    <InputField
      ref={ref}
      {...props}
      name={name}
      value={value}
      readOnly={readOnly}
      type='text'
      autoComplete='off'
      onChange={handleInputChange}
      onBlur={handleInputBlur}
    />
  )
}
