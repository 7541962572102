import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import useUser from 'customer/hooks/useUser'
import useEffect from 'customer/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'
import InputField from 'customer/components/ui/InputField'
import useDocument from 'customer/hooks/useDocument'

export default props => {
  const {forgotPassword, error, forgotPasswordStatus, clearForgotPassword} = useUser()
  const [email, setEmail] = useState('')
  const [_flash, setFlash] = useFlash()
  useDocument({title: 'Forgot your password?'})

  useEffect(
    () => {
      if (forgotPasswordStatus.fetched && !error.data) {
        clearForgotPassword()
        setFlash('Password reset email sent')
        props.history.replace('/login')
      }
    },
    [forgotPasswordStatus],
    false
  )

  const handleSubmit = e => {
    e.preventDefault()
    forgotPassword({email})
  }

  const errors = error.data || {}

  return (
    <form action='' onSubmit={handleSubmit} className='box' style={{padding: '3em'}}>
      <div className='block'>
        <h1 className='title has-text-dark'>Forgot your password?</h1>
      </div>
      <div className='block'>
        <div className='field'>
          <InputField
            type='email'
            name='email'
            label='Email'
            onChange={({email}) => setEmail(email)}
            value={email}
            errors={errors.email}
          />
        </div>
      </div>
      <div className='block field'>
        <button className='button is-success'>Send me reset password instructions</button>
      </div>
      <div className='block field'>
        <Link to='/login' className='button is-light'>
          Login
        </Link>
      </div>
    </form>
  )
}
