import React from 'react'
import useEffect from 'admin/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'

export default ({floating = false}) => {
  const [flash] = useFlash()

  useEffect(() => {
    if (!floating && flash.message) window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [flash.message])

  if (!flash.message) return null

  return (
    <section className={`section flash ${flash.type} ${floating ? 'floating' : ''}`}>
      <div className='container'>{flash.message}</div>
    </section>
  )
}
