import {useSelector, useDispatch} from 'react-redux'
import useEffect from 'admin/hooks/useEffect'
import {getUsers} from 'admin/actions/Users.actions'

const mapState = state => ({
  error: state.users.error,
  users: state.users.data,
  counts: state.users.counts,
  getUsersStatus: state.users.getStatus
})

export default organisationID => {
  const dispatch = useDispatch()
  const store = useSelector(mapState)
  return {
    ...store,
    getUsers: filters => getUsers(organisationID, filters)(dispatch)
  }
}
