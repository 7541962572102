import Form from 'admin/components/RepeatingInvoice/Form'
import Breadcrumbs from 'admin/components/ui/Breadcrumbs'
import Flash from 'admin/components/Flash'
import useDocument from 'admin/hooks/useDocument'
import useEffect from 'admin/hooks/useEffect'
import useRepeatingInvoice from 'admin/hooks/useRepeatingInvoice'
import useStateObject from 'admin/hooks/useStateObject'
import React, {Fragment} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {NumberParam, StringParam, useQueryParams} from 'use-query-params'

export default () => {
  const history = useHistory()
  const {organisationID} = useParams()
  const [{accountId, accountName}] = useQueryParams({accountId: NumberParam, accountName: StringParam})
  const {
    repeatingInvoice = {
      accountId,
      account: {
        id: accountId,
        displayName: accountName
      }
    },
    errors,
    create
  } = useRepeatingInvoice(organisationID)
  const [form, setForm] = useStateObject(repeatingInvoice)

  useDocument({title: 'New repeating invoice'})

  useEffect(() => setForm(repeatingInvoice), [repeatingInvoice], false)

  const handleCreate = e => {
    e.preventDefault()
    create(form, ({repeatingInvoice}) => {
      history.replace(`/orgs/${organisationID}/repeating_invoices/${repeatingInvoice.id}`)
    })
  }

  const breadcrumbs = []
  if (repeatingInvoice.account?.id) {
    breadcrumbs.push({
      label: 'Manage accounts',
      path: `/orgs/${organisationID}/accounts`
    })
    breadcrumbs.push({
      label: repeatingInvoice.account.displayName,
      path: `/orgs/${organisationID}/accounts/${repeatingInvoice.account.id}`
    })
  } else {
    breadcrumbs.push({
      label: 'Repeating Invoices',
      path: `/orgs/${organisationID}/repeating_invoices`
    })
  }

  return (
    <Fragment>
      <Flash />
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered'>
            <div className='is-5 column'>
              <Breadcrumbs breadcrumbs={breadcrumbs}>
                <h3 className='title is-marginless'>New repeating invoice</h3>
              </Breadcrumbs>
            </div>
            <div className='is-7 column buttons is-right'>
              <button className='button is-success' onClick={handleCreate}>
                Create
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <Form errors={errors} organisationID={organisationID} repeatingInvoice={form} onChange={setForm} />
        </div>
      </section>
    </Fragment>
  )
}
