import React, {Fragment} from 'react'
import {useRouteMatch} from 'react-router-dom'
import useOrganisation from 'admin/hooks/useOrganisation'
import InputField from 'admin/components/ui/InputField'
import useStateObject from 'admin/hooks/useStateObject'
import useEffect from 'admin/hooks/useEffect'
import useFlash from 'admin/hooks/useFlash'
import Flash from 'admin/components/Flash'

export default () => {
  const match = useRouteMatch()
  const {organisationID} = match.params
  const {organisation, errors, update} = useOrganisation(organisationID)
  const [_flash, setFlash] = useFlash()
  const [form, setForm, resetForm] = useStateObject({
    name: '',
    gstNumber: '',
    paymentDetails: '',
    customerNumbersSequence: '',
    invoiceNumbersSequence: '',
    emailDisclaimer: ''
  })

  useEffect(() => resetForm(organisation), [organisation], false)
  const handleUpdate = () => update(form)

  return (
    <Fragment>
      <Flash />
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='column'>
              <h3 className='title'>Edit {organisation.name}</h3>
            </div>
            <div className='column buttons is-right'>
              <button className='button is-primary' onClick={handleUpdate}>
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <div className='field is-spaced'>
            <div className='control'>
              <InputField type='text' name='name' value={form.name || ''} onChange={setForm} errors={errors.name} />
            </div>
          </div>
          <div className='field is-spaced'>
            <div className='control'>
              <InputField
                type='text'
                label='GST number'
                name='gstNumber'
                value={form.gstNumber}
                onChange={setForm}
                errors={errors.gstNumber}
              />
            </div>
          </div>
          <div className='field is-spaced'>
            <div className='control'>
              <InputField
                type='textarea'
                name='paymentDetails'
                value={form.paymentDetails}
                onChange={setForm}
                errors={errors.paymentDetails}
                rows={6}
              />
            </div>
            <span className='help'>These details are appended to invoices and statements</span>
          </div>
          <div className='field is-spaced'>
            <div className='control'>
              <div className='field is-grouped'>
                <div className='control is-expanded'>
                  <InputField
                    type='number'
                    label='Next account number'
                    name='customerNumbersSequence'
                    value={form.customerNumbersSequence}
                    onChange={setForm}
                    errors={errors.customerNumbersSequence}
                    min={organisation.customerNumbersSequence}
                  />
                </div>
                <div className='control is-expanded'>
                  <InputField
                    type='number'
                    label='Next invoice number'
                    name='invoiceNumbersSequence'
                    value={form.invoiceNumbersSequence}
                    onChange={setForm}
                    errors={errors.invoiceNumbersSequence}
                    min={organisation.invoiceNumbersSequence}
                  />
                </div>
              </div>
            </div>
            <span className='help'>Be careful changing these values. The number sequence cannot be decremented.</span>
          </div>
          <div className='field is-spaced'>
            <div className='control'>
              <InputField
                type='textarea'
                name='emailDisclaimer'
                value={form.emailDisclaimer}
                onChange={setForm}
                errors={errors.emailDisclaimer}
                rows={6}
              />
            </div>
            <span className='help'>This text is appended to invoice and statement emails</span>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
