import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import createStore from 'customer/store'
import withNavbar from 'customer/components/hoc/withNavbar'
import AccountLayout from 'customer/components/layouts/AccountLayout'
import LoginLayout from 'customer/components/layouts/LoginLayout'
import UserDetails from 'customer/components/UserDetails'
import FourZeroFour from 'customer/components/FourZeroFour'
import auth from 'customer/lib/auth'

const ProtectedRoute = ({component: Component, ...rest}) => {
  const render = props => {
    return auth.isValid() ? <Component {...props} /> : <Redirect to='/login' />
  }
  return <Route {...rest} render={render} />
}

export default props => (
  <Provider store={createStore(props)}>
    <Router>
      <Switch>
        <ProtectedRoute path='/accounts/:accountID(\d+)' component={withNavbar(AccountLayout)} />
        <ProtectedRoute exact path='/accounts' component={withNavbar(FourZeroFour)} />
        <ProtectedRoute exact path='/profile' component={withNavbar(UserDetails)} />
        <Route path='/login' component={LoginLayout} />
        <Redirect exact from='/' to='/login' />
        <Route component={FourZeroFour} />
      </Switch>
    </Router>
  </Provider>
)
