import {gql} from '@apollo/client'

export const SEND_STATEMENT_EMAIL = gql`
  mutation SendStatementEmail(
    $accountId: ID!
    $to: String
    $cc: String
    $bcc: String
    $subject: String
    $message: String
    $fromDate: String
    $toDate: String
  ) {
    sendStatementEmail(
      accountId: $accountId
      to: $to
      cc: $cc
      bcc: $bcc
      subject: $subject
      message: $message
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`

export const GET_STATEMENT = gql`
  query GetStatement(
    $organisationId: ID!
    $accountId: ID!
    $fromDate: String
    $toDate: String
    $page: Int
    $limit: Int
    $sortBy: String
    $sortDirection: String
  ) {
    statement(
      organisationId: $organisationId
      accountId: $accountId
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      collection {
        id
        date
        amount
        balance
        source
        ... on Invoice {
          description @client
          lineItems
          dueDate
          invoiceNumber
          reference
          isSent
        }
        ... on Transaction {
          description
        }
      }
      metadata {
        totalCount
      }
    }
  }
`
