import React, {useState} from 'react'
import Modal from 'admin/components/ui/Modal'
import InputField from 'admin/components/ui/InputField'

export default ({history, message, notesLabel, onConfirm, onCancel, confirmClass, confirmText}) => {
  const [notes, setNotes] = useState('')

  const handleChange = changes => {
    setNotes(changes.notes)
  }

  const handleConfirm = e => {
    setNotes('')
    onConfirm(notes)
    e.preventDefault()
  }

  const handleCancel = e => {
    onCancel()
    e.preventDefault()
  }

  return (
    <Modal minWidth='45%'>
      <section className='section'>
        <form>
          <div className='field'>
            <div className='control'>{message}</div>
          </div>
          {notesLabel && (
            <InputField
              value={notes}
              type='textarea'
              name='notes'
              label={notesLabel}
              onChange={handleChange}
              rows={2}
            />
          )}
          <div className='buttons'>
            <button onClick={handleCancel} className='button'>
              Cancel
            </button>
            <button onClick={handleConfirm} className={`button is-primary ${confirmClass}`}>
              {confirmText || 'Confirm'}
            </button>
          </div>
        </form>
      </section>
    </Modal>
  )
}
