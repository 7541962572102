import Confirmation from 'admin/components/Confirmation'
import Flash from 'admin/components/Flash'
import History from 'admin/components/History'
import Email from 'admin/components/shared/Email'
import Breadcrumbs from 'admin/components/ui/Breadcrumbs'
import InputField from 'admin/components/ui/InputField'
import useBoolState from 'admin/hooks/useBoolState'
import useDocument from 'admin/hooks/useDocument'
import useEffect from 'admin/hooks/useEffect'
import useInvoice from 'admin/hooks/useInvoice'
import useOrganisation from 'admin/hooks/useOrganisation'
import useStateObject from 'admin/hooks/useStateObject'
import {formatNumber, toParams} from 'admin/lib/format'
import {hello} from 'admin/lib/greetings'
import moment, {dateIsTodayOrInThePast} from 'admin/lib/moment'
import {totalForInvoice} from 'admin/lib/tax'
import diff from 'deep-diff'
import React, {Fragment, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Form from './Form'

const EMAIL_DEFAULTS = {
  subject: `[Invoice Month] Invoice [Invoice Number] from [Organisation Name] for [Contact Name]`,
  message: `${hello()} [Contact First Name],

Here's your [Invoice Month] invoice [Invoice Number] for $NZD [Amount], due on [Due Date].

If you have any questions, please let us know.

Ngā mihi,
[Organisation Name]`
}

export default () => {
  const history = useHistory()
  const {organisationID, invoiceID} = useParams()
  const {organisation} = useOrganisation(organisationID)
  const {invoice = {}, errors, update, destroy, send} = useInvoice(organisationID, invoiceID)

  const [form, setForm, resetForm] = useStateObject(invoice, {email: EMAIL_DEFAULTS})
  const [deleteReason, setDeleteReason] = useState(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation, toggleShowDeleteConfirmation] = useBoolState()
  const [showEmailModal, setShowEmailModal, toggleShowEmailModal] = useBoolState()

  useDocument({title: `Edit invoice ${form.invoiceNumber || ''}`})
  useEffect(() => resetForm(invoice), [invoice], false)
  useEffect(() => errors.email && toggleShowEmailModal(), [errors.email], false)

  const handleApprove = e => {
    e.preventDefault()
    e.target.blur()
    update({state: 'approved'})
  }

  const handleUnapprove = e => {
    e.preventDefault()
    e.target.blur()
    update({state: 'draft'})
  }

  const handleDelete = () => {
    destroy({notes: deleteReason}, () => history.replace(`/orgs/${organisationID}/invoices`))
  }

  const handleUpdate = e => {
    e.preventDefault()
    e.target.blur()
    update(form)
  }

  const handleSendEmail = email => {
    send(email, toggleShowEmailModal)
  }

  const handleChange = changes => {
    if (changes.isSendable && !form.isSendable) toggleShowEmailModal()
    setForm(changes)
  }

  const handleChangeEmail = email => {
    setForm({email})
    toggleShowEmailModal()
  }

  const handleCancel = e => {
    e.preventDefault()
    resetForm(invoice)
  }

  const breadcrumbs = []
  if (invoice.account?.id) {
    breadcrumbs.push({label: 'Manage accounts', path: `/orgs/${organisationID}/accounts`})
    breadcrumbs.push({
      label: invoice.account?.displayName,
      path: `/orgs/${organisationID}/accounts/${invoice.account.id}`
    })
  } else if (!invoice.isDraft) {
    breadcrumbs.push({label: 'Approved Invoices', path: `/orgs/${organisationID}/invoices/approved`})
  } else {
    breadcrumbs.push({label: 'Draft Invoices', path: `/orgs/${organisationID}/invoices/draft`})
  }

  const ignoredChanges = (path, key) =>
    path.length === 0 && key === 'email' && (!invoice.email?.subject || !invoice.email?.message)
  const isSendableNow = form.state === 'approved' && dateIsTodayOrInThePast(form.date)
  const hasChanges = diff(form, invoice, ignoredChanges)

  return (
    <Fragment>
      <Flash />
      <Confirmation
        confirm='Are you sure you want to delete?'
        buttonClass='is-danger'
        buttonText='Delete'
        onConfirm={handleDelete}
        onCancel={() => setDeleteReason(null)}
        onClose={toggleShowDeleteConfirmation}
        isOpen={showDeleteConfirmation}>
        <InputField
          value={deleteReason || ''}
          type='textarea'
          name='notes'
          label='Reason for deleting? (optional)'
          onChange={({notes}) => setDeleteReason(notes)}
          rows={2}
        />
      </Confirmation>
      <Email
        email={form.email}
        errors={errors.email ? errors.email[0] : {}}
        onSend={isSendableNow && handleSendEmail}
        onSave={!isSendableNow && handleChangeEmail}
        onExit={toggleShowEmailModal}
        isOpen={showEmailModal}
        placeholders={{
          ['Invoice Month']: moment(form.date).format('MMMM'),
          ['Invoice Number']: form.invoiceNumber,
          ['Due Date']: moment(form.dueDate).format('D MMM YYYY'),
          ['Contact First Name']: form.account?.contactFirstName,
          ['Invoice Total With Currency']: formatNumber(totalForInvoice(form), {thousands: true, currency: true}),
          ['Amount']: formatNumber(totalForInvoice(form), {thousands: true}),
          ['Organisation Name']: organisation.name,
          ['Contact Name']: form.account?.displayName
        }}>
        <h5 className='subtitle is-6'>
          <i className='fa fa-paperclip' aria-hidden='true'></i>
          &nbsp;&nbsp;
          <a
            href={`/orgs/${organisationID}/downloads/invoice.pdf?${toParams({
              invoice_id: invoiceID,
              inline: true
            })}`}
            target='_blank'>
            PDF attached
          </a>
        </h5>
      </Email>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns is-vcentered is-mobile'>
            <div className='is-5 column'>
              <Breadcrumbs breadcrumbs={breadcrumbs}>
                <h3 className='title is-marginless'>Edit invoice {form.invoiceNumber}</h3>
              </Breadcrumbs>
            </div>
            <div className='is-7 column buttons is-right'>
              {hasChanges ? (
                <Fragment>
                  <button className='button is-text is-decorationless' onClick={handleCancel}>
                    Cancel
                  </button>
                  <button className='button is-primary' onClick={handleUpdate}>
                    Save
                  </button>
                </Fragment>
              ) : form.id ? (
                <Fragment>
                  <a
                    href={`/orgs/${organisationID}/downloads/invoice.pdf?${toParams({
                      invoice_id: invoiceID,
                      inline: true
                    })}`}
                    target='_blank'
                    className='button is-outlined is-dark'>
                    View PDF
                  </a>
                  {isSendableNow ? (
                    <button className='button is-outlined is-dark' onClick={toggleShowEmailModal}>
                      {form.isSent ? 'Resend' : 'Send'} email
                    </button>
                  ) : null}
                  <button className='button is-danger is-outlined' onClick={toggleShowDeleteConfirmation}>
                    Delete
                  </button>
                  {form.state === 'draft' ? (
                    <button className='button is-primary' onClick={handleApprove}>
                      Approve
                    </button>
                  ) : (
                    <button className='button is-outlined is-info' onClick={handleUnapprove}>
                      Revert to Draft
                    </button>
                  )}
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <Form
            errors={errors}
            organisationID={organisationID}
            invoice={form}
            onChange={handleChange}
            onEditEmailTemplate={toggleShowEmailModal}
          />
        </div>
      </section>
      <History
        itemType='Invoice'
        itemId={invoiceID}
        onAddNote={notes => update({notes})}
        className='has-background-light'
      />
    </Fragment>
  )
}
