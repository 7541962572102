import {CREATE_REPEATING_INVOICE, GET_REPEATING_INVOICE, UPDATE_REPEATING_INVOICE} from 'admin/gql/RepeatingInvoice.gql'
import clearCache from 'admin/hooks/clearCache'
import mutateWith from 'admin/hooks/mutateWith'
import useDestroyable from 'admin/hooks/useDestroyable'
import {parseError, useMutation, useQuery} from 'admin/lib/apollo'

export default (organisationId, repeatingInvoiceId) => {
  const {data: {repeatingInvoice} = {}} = useQuery(GET_REPEATING_INVOICE, {
    variables: {organisationId, repeatingInvoiceId},
    skip: !repeatingInvoiceId,
    errorPolicy: 'all'
  })

  const [create, {error: createError}] = useMutation(CREATE_REPEATING_INVOICE, {
    variables: {organisationId, repeatingInvoiceId},
    errorPolicy: 'all',
    update: (cache, {data: {repeatingInvoice}, errors}) =>
      !errors && clearCache({repeatingInvoices: {accountId: repeatingInvoice.account.id}})(cache)
  })

  const [update, {error: updateError}] = useMutation(UPDATE_REPEATING_INVOICE, {
    variables: {organisationId, repeatingInvoiceId},
    errorPolicy: 'all',
    update: (cache, {data: {repeatingInvoice}, errors}) =>
      !errors &&
      clearCache({
        history: {itemType: 'RepeatingInvoice', itemId: repeatingInvoiceId},
        schedule: [{accountId: repeatingInvoice.account.id}]
      })(cache)
  })

  const {destroy, errors: destroyError} = useDestroyable(organisationId, 'RepeatingInvoice', repeatingInvoiceId)

  return {
    repeatingInvoice,
    errors: parseError(createError || updateError) || destroyError,
    create: mutateWith(create, 'Repeating invoice created'),
    update: mutateWith(update, 'Repeating invoice updated'),
    destroy
  }
}
