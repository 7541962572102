import {useQuery} from 'admin/lib/apollo'
import {GET_GROUPS} from 'admin/gql/Groups.gql'

export default (organisationId, type = null) => {
  const {data: {groups = []} = {}} = useQuery(GET_GROUPS, {
    variables: {organisationId, type}
  })

  return {groups}
}
