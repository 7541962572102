import {useQuery, useMutation} from '@apollo/client'
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params'
import {GET_STATEMENT, SEND_STATEMENT_EMAIL} from 'admin/gql/Statement.gql'
import {parseError} from 'admin/lib/apollo'
import useEffect from 'admin/hooks/useEffect'
import mutateWith from 'admin/hooks/mutateWith'

export default (organisationId, accountId) => {
  const [filters, setFilters] = useQueryParams({
    fromDate: StringParam,
    toDate: StringParam,
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sortBy: withDefault(StringParam, 'date'),
    sortDirection: withDefault(StringParam, 'asc')
  })

  const variables = {organisationId, accountId, ...filters}

  const {data: {statement = {}} = {}, error: getError, fetchMore} = useQuery(GET_STATEMENT, {variables})

  const [send, {error: sendError}] = useMutation(SEND_STATEMENT_EMAIL, {
    variables,
    errorPolicy: 'all'
  })

  useEffect(() => fetchMore({variables: filters}), [filters], false)

  return {
    filters,
    setFilters,
    statement: statement.collection || [],
    errors: parseError(getError || sendError),
    totalCount: statement.metadata ? statement.metadata.totalCount : 0,
    send: mutateWith(send, 'Statement email submitted')
  }
}
