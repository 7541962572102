import React from 'react'
import {compact, isEmpty} from 'lodash'
import moment, {dateIsTodayOrInThePast, dateIsMoreThanAYearAgo, dateIsInTheFuture} from 'admin/lib/moment'
import {formatNumber} from 'admin/lib/format'
import InputField from 'admin/components/ui/InputField'
import DateField from 'admin/components/ui/DateField'
import AccountField from 'admin/components/shared/AccountField'
import ShowField from 'admin/components/ui/ShowField'
import LineItems from 'admin/components/shared/LineItems'
import {subtotalForInvoice, taxForInvoice, totalForInvoice, TAX_TYPES} from 'admin/lib/tax'

const sourceMessage = source => `This repeating invoice was imported from ${source}.`

export default ({errors, organisationID, repeatingInvoice, onChange, onEditEmailTemplate}) => {
  const fillBillTo = e => {
    e.preventDefault()
    const billingCustomer = repeatingInvoice.account?.displayName?.toUpperCase()
    const billingAddress = repeatingInvoice.account?.postalAddress || repeatingInvoice.account?.physicalAddress
    handleChange({billTo: compact([billingCustomer, billingAddress]).join('\n')})
  }

  const editEmailTemplate = e => {
    e.preventDefault()
    onEditEmailTemplate()
  }

  const handleChange = changes => {
    let alteredChanges = {...changes}
    if ('nextInvoiceDate' in changes) {
      if (
        dateIsTodayOrInThePast(changes.nextInvoiceDate) ||
        (dateIsInTheFuture(changes.nextInvoiceDate) && !repeatingInvoice.emailWithPlaceholders)
      ) {
        alteredChanges.isSendable = false
      }

      if (!dateIsMoreThanAYearAgo(repeatingInvoice.nextInvoiceDate)) {
        alteredChanges.forceDate = false
      }

      let daysUntilDue = moment(repeatingInvoice.nextInvoiceDueDate).diff(
        moment(repeatingInvoice.nextInvoiceDate),
        'days'
      )
      alteredChanges.nextInvoiceDueDate = moment(changes.nextInvoiceDate).add(daysUntilDue, 'days').format('YYYY-MM-DD')
    } else if ('daysUntilDue' in changes) {
      alteredChanges.nextInvoiceDueDate = moment(repeatingInvoice.nextInvoiceDate)
        .add(changes.daysUntilDue, 'days')
        .format('YYYY-MM-DD')
    } else if ('accountId' in changes) {
      alteredChanges.billTo = null
    }
    onChange(alteredChanges)
  }

  return (
    <form>
      {repeatingInvoice.source && (
        <div className='is-size-7 mb-4'>
          {sourceMessage(repeatingInvoice.source)}{' '}
          {repeatingInvoice.xeroLink && (
            <a href={repeatingInvoice.xeroLink} target='_blank'>
              <span>Show in Xero</span>
              <span className='icon'>
                <i className='fa fa-external-link'></i>
              </span>
            </a>
          )}
        </div>
      )}

      <div className='columns pb-4'>
        <div className='column is-4'>
          <AccountField
            label='Account'
            value={repeatingInvoice.account?.displayName}
            organisationID={organisationID}
            onSelect={account => handleChange({account, accountId: account?.id})}
            errors={errors.accountId}
          />

          <div className='field is-spaced'>
            <div className='control'>
              {!repeatingInvoice.billTo && repeatingInvoice.account?.id && (
                <a href='' onClick={fillBillTo} className='is-pulled-right is-size-7'>
                  Use account details
                </a>
              )}
              <InputField
                type='textarea'
                name='billTo'
                label='Bill to'
                value={repeatingInvoice.billTo || ''}
                onChange={handleChange}
                errors={errors.billTo}
                rows={4}
              />
            </div>
          </div>

          {repeatingInvoice.id && dateIsInTheFuture(repeatingInvoice.nextInvoiceDate) && (
            <InputField
              type='checkbox'
              name='isSendable'
              checked={repeatingInvoice.isSendable || false}
              label='Send email on next invoice date'
              onChange={handleChange}
              help='Only approved invoices will be sent'>
              {repeatingInvoice.isSendable && isEmpty(errors.emailWithPlaceholders) ? (
                <a
                  href=''
                  className='is-size-7'
                  onClick={editEmailTemplate}
                  style={{position: 'absolute', top: 0, right: 0}}>
                  Edit email message
                </a>
              ) : repeatingInvoice.isSendable ? (
                <a
                  href=''
                  className='is-size-7 has-text-danger'
                  onClick={editEmailTemplate}
                  style={{position: 'absolute', top: 0, right: -20}}>
                  <span className='icon-text'>
                    <span className='mr-1'>Edit email message</span>
                    <span className='icon has-text-danger'>
                      <i className='fa fa-exclamation-triangle'></i>
                    </span>
                  </span>
                </a>
              ) : null}
            </InputField>
          )}
        </div>
        <div className='column is-8-tablet is-6-desktop is-offset-2-desktop'>
          <DateField
            name='nextInvoiceDate'
            label='Next invoice date'
            value={repeatingInvoice.nextInvoiceDate || ''}
            onChange={handleChange}
            required
            errors={errors.nextInvoiceDate}
            isHorizontal={true}
          />
          {dateIsMoreThanAYearAgo(repeatingInvoice.nextInvoiceDate) && (
            <div className='field is-grouped is-spaced'>
              <div className='control'>
                <InputField
                  type='checkbox'
                  name='forceDate'
                  checked={repeatingInvoice.forceDate || false}
                  label='Force next invoice date'
                  onChange={handleChange}
                  help="Forces the next invoice date if it's more than 1 year in the past"
                />
              </div>
            </div>
          )}
          <InputField
            type='text'
            name='reference'
            label='Invoice reference'
            value={repeatingInvoice.reference || ''}
            onChange={handleChange}
            errors={errors.reference}
            isHorizontal={true}
          />
          <ShowField label='Account number' value={repeatingInvoice.account?.customerNumber} />
          <ShowField label='GST number' value={repeatingInvoice.organisation?.gstNumber} />
          <InputField
            type='number'
            name='daysUntilDue'
            label='Payment is due'
            value={
              repeatingInvoice.nextInvoiceDueDate
                ? moment(repeatingInvoice.nextInvoiceDueDate).diff(moment(repeatingInvoice.nextInvoiceDate), 'days')
                : ''
            }
            min={0}
            onChange={handleChange}
            errors={errors.nextInvoiceDueDate}
            disabled={!repeatingInvoice.nextInvoiceDate}
            isHorizontal={true}>
            <a className='button is-static'>&nbsp; day(s) after invoice date</a>
          </InputField>

          <div className='field is-grouped pt-5'>
            <div className='control'>
              <InputField
                type='number'
                label='Repeat every'
                id='frequencyCount'
                name='frequencyCount'
                min={1}
                value={repeatingInvoice.frequencyCount || ''}
                onChange={handleChange}
                errors={errors.frequencyCount}>
                <span className='select'>
                  <select
                    id='frequency'
                    name='frequency'
                    value={repeatingInvoice.frequency}
                    onChange={e => handleChange({frequency: e.target.value})}>
                    <option value='weekly'>Weeks</option>
                    <option value='monthly'>Months</option>
                  </select>
                </span>
              </InputField>
            </div>
            <div className='control'>
              <DateField
                label='End date'
                name='endDate'
                value={repeatingInvoice.endDate || ''}
                onChange={handleChange}
                errors={errors.endDate}
              />
            </div>
          </div>
        </div>
      </div>

      <LineItems
        items={repeatingInvoice.lineItems || []}
        name='lineItems'
        onChange={handleChange}
        errors={errors.lineItems}
      />

      <div className='field is-grouped is-grouped-right is-spaced'>
        <div className='control h'>
          <label className='label is-size-7'>Amounts are</label>
        </div>
        {Object.values(TAX_TYPES).map((type, i) => (
          <div key={i} className='control'>
            <label name={`taxType_${type}`} className='radio is-size-7'>
              <input
                type='radio'
                name='taxType'
                value={type}
                onChange={e => handleChange({taxType: e.target.value})}
                checked={repeatingInvoice.taxType == type}
              />
              <b>Tax {type.toLowerCase()}</b>
            </label>
          </div>
        ))}
      </div>

      <div className='field is-grouped is-grouped-right'>
        <div className='control'>
          <label className='label is-size-7'>Subtotal</label>
        </div>
        <div className='control has-text-right' style={{width: '100px'}}>
          {formatNumber(subtotalForInvoice(repeatingInvoice), {thousands: true})}
        </div>
      </div>

      <div className='field is-grouped is-grouped-right'>
        <div className='control'>
          <label className='label is-size-7'>
            {repeatingInvoice.taxType === TAX_TYPES.inclusive ? 'Includes GST 15%' : 'Total GST 15%'}
          </label>
        </div>
        <div className='control has-text-right' style={{width: '100px'}}>
          {formatNumber(taxForInvoice(repeatingInvoice), {thousands: true})}
        </div>
      </div>

      <div className='field is-grouped is-grouped-right'>
        <div className='control'>
          <label className='label is-size-4'>TOTAL</label>
        </div>
        <div className='control has-text-right is-size-4' style={{width: '100px'}}>
          {formatNumber(totalForInvoice(repeatingInvoice), {thousands: true})}
        </div>
      </div>
    </form>
  )
}
